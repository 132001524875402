import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      halls: [],
};
/////////////// Getters
const getters = {
  allHalls: (state) => state.halls
};
/////////////// Actions
const actions = {
  async fetchHalls({ commit }) {
    const response = await apiServices.fetch_halls();
    commit("setHalls", response.data.halls);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setHalls: (state, halls) => {
      state.halls = halls;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
