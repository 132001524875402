<template>
  <div class="showMessage">
    <v-card class="mx-auto" max-width="500" elevation="0">
      <v-toolbar elevation="0" class="customerTableToolBar">
        <svg
          style="width: 30px; height: 30px; margin-left: 10px"
          viewBox="0 0 24 24"
        >
          <path
            fill="#8ac926"
            d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
          />
        </svg>
        تم المسح بنجاح
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DeleteMessage",
  data: () => ({}),
  methods: {},
};
</script>

<style scoped>
.showMessage {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.customerTableToolBar{
  font-size: 20px;
}
</style>
