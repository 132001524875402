import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      bouquets: [],
};
/////////////// Getters
const getters = {
  allBoscoBouquets: (state) => state.bouquets.reverse()
};
/////////////// Actions
const actions = {
  async fetchBoscoBouquets({ commit }) {
    const response = await apiServices.fetch_bosco_bouquets();
    commit("setBoscoBouquets", response.data.bouquets);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setBoscoBouquets: (state, bouquets) => {
      state.bouquets = bouquets;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
