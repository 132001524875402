import apiServices from "../../api/apiServices";
/////////////// State
const state = {
  studentsHouse: [],
  totalStudentsHouseByCustomer: [],
};
/////////////// Getters
const getters = {
  allStudentsHouse: (state) => state.studentsHouse.reverse(),
  allStudentsHouseByCustomer: (state) =>
    state.totalStudentsHouseByCustomer.reverse(),
};
/////////////// Actions
const actions = {
  async fetchStudentsHouse({ commit, rootGetters }) {
    const response = await apiServices.fetch_students_house();
    //filter students house posts by customer
    let total_students_house_posts = response.data.students.filter((items) => {
      const matchPost = items.bosco_customer_email.includes(
        rootGetters.get_bosco_customer.customer_email
      );
      return matchPost;
    });
    commit("setStudentsHouseByCustomer", total_students_house_posts);
    commit("setStudentsHouse", response.data.students);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
  setStudentsHouse: (state, studentsHouse) => {
    state.studentsHouse = studentsHouse;
  },
  setStudentsHouseByCustomer: (state, total_students_house_posts) => {
    state.totalStudentsHouseByCustomer = total_students_house_posts;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
