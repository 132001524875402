import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      walls: [],
};
/////////////// Getters
const getters = {
  allWalls: (state) => state.walls
};
/////////////// Actions
const actions = {
  async fetchWalls({ commit }) {
    const response = await apiServices.fetch_walls();
    commit("setWalls", response.data.walls);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setWalls: (state, walls) => {
      state.walls = walls;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
