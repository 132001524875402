import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      sketches: [],
};
/////////////// Getters
const getters = {
  allSketches: (state) => state.sketches
};
/////////////// Actions
const actions = {
  async fetchSketches({ commit }) {
    const response = await apiServices.fetch_sketches();
    commit("setSketches", response.data.sketches);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setSketches: (state, sketches) => {
      state.sketches = sketches;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
