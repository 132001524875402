import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      furnitures: [],
};
/////////////// Getters
const getters = {
  allFurnitures: (state) => state.furnitures
};
/////////////// Actions
const actions = {
  async fetchFurnitures({ commit }) {
    const response = await apiServices.fetch_furnitures();
    commit("setFurnitures", response.data.furnitures);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setFurnitures: (state, furnitures) => {
      state.furnitures = furnitures;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
