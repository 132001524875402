import { render, staticRenderFns } from "./BoApartmentsForRent.vue?vue&type=template&id=db415ddc&scoped=true&"
import script from "./BoApartmentsForRent.vue?vue&type=script&lang=js&"
export * from "./BoApartmentsForRent.vue?vue&type=script&lang=js&"
import style0 from "./BoApartmentsForRent.vue?vue&type=style&index=0&id=db415ddc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db415ddc",
  null
  
)

export default component.exports