<template>
  <v-container id="ourTeam">
    <section class="firstSection">
      <v-layout row wrap>
        <v-flex xs12 md12>
          <h2 class="howWeAre">بوسكو لي شنو؟</h2>
          <p>في منصة بوسكو للتسويق العقاري، عايزين نسهل لي الناس يلقوا عقار بكل سهولة.</p>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <p class="">
            بوسكو للتسويق العقاري، منصة لتسويق العقارات في السودان، عايزين نتساعد مع بعض في تطوير مجال التسويق العقاري،  بتقديم خدمات إحترافية وعصرية.
          </p>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="secondLayout">
        <!-- <v-spacer></v-spacer> -->
        <v-flex xs12 md5 class="togod">
          <v-card class="mx-auto howWeAreCard" max-width="434">
            <v-img height="350" src="../assets/images/togod2.jpg"> </v-img>
            <v-list-item color="rgba(0, 0, 0, .4)" dark>
              <v-list-item-content>
                <v-list-item-title class="teamNames">
                  {{ tname }}
                </v-list-item-title>
                <v-divider></v-divider>

                <v-list-item-subtitle class="teamNames"
                  >{{ tTitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 md5 class="slma">
          <v-card class="mx-auto howWeAreCard" max-width="434">
            <v-img height="350" src="../assets/images/alfa2.jpg"> </v-img>
            <v-list-item color="rgba(0, 0, 0, .4)" dark>
              <v-list-item-content>
                <v-list-item-title class="teamNames">
                  {{ sname }}
                </v-list-item-title>
                <v-list-item-subtitle class="teamNames"
                  >{{ sTitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-spacer></v-spacer>
           <v-flex xs12 md5 class="slma">
          <v-card class="mx-auto howWeAreCard" max-width="434">
            <v-img height="350" src="../assets/images/raniaTalab.jpg"> </v-img>
            <v-list-item color="rgba(0, 0, 0, .4)" dark>
              <v-list-item-content>
                <v-list-item-title class="teamNames">
                  {{ rname }}
                </v-list-item-title>
                <v-list-item-subtitle class="teamNames"
                  >{{ rTitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </section>
  </v-container>
</template>

<script>
export default {
  name: "Ourteam",
  data: () => ({
    sname: "محمد الفاهاشم",
    tname: "تقد عمر",
    sTitle: "مدير المبيعات",
    tTitle: "مدير R&D",
    rTitle: "مديرة  التسويق",
    rname: "رانيا طلب",
  }),
  metaInfo: {
    title: "  بوسكو عقارات - من نحن",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "بوسكو للتسويق العقاري الحديث",
      },
    ],
  },
};
</script>

<style scoped>
#ourTeam {
  text-align: center;
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.howWeAre {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 20px;
  color: #000;
  opacity: 0.5;
  word-spacing: 10px;
}
p {
  font-size: 20px;
  word-spacing: 10px;
  line-height: 1.8;
  color: #28292a;
}

.teamNames {
  margin-left: 20px;
  font-size: 20px;
  color: #28292a !important;
}

.secondLayout {
  margin-top: 40px;
  margin-bottom: 40px;
}
.togod,
.slma {
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .togod {
    margin-bottom: 20px;
  }
  #ourteam {
    margin-top: 15px;
  }
}
</style>
