import Vue from "vue";
import VueRouter from "vue-router";

import store from '../store'




//Main Pages Routes Section for clinte
import BoHome from "../views/BoHome.vue";
import BoAgriculturalFields from "../views/BoAgriculturalFields.vue";
import BoApartmentsForRent from "../views/BoApartmentsForRent.vue";
import BoApartmentsForSale from "../views/BoApartmentsForSale.vue";
import BoBuildings from "../views/BoBuildings.vue";
import BoHouses from "../views/BoHouses.vue";
import BoLands from "../views/BoLands.vue";
import BoOffices from "../views/BoOffices.vue";
import BoShops from "../views/BoShops.vue";
import BoStudentsHousing from "../views/BoStudentsHousing.vue";
import BoVillas from "../views/BoVillas.vue";
import BoOurTeam from "../views/BoOurTeam.vue";
import BoSupports from "../views/BoSupports.vue";
import BoPrivacyPolicy from "../views/BoPrivacyPolicy.vue";
import BoAdmin from "../views/BoAdmin.vue";
import BoSettings from "../views/BoSettings";
import BoBouquets from "../views/BoBouquets";
//Main Pages Routes Section For Admin
import Villas from "../views/mainPages/Villas";
import StudentsHouse from "../views/mainPages/StudentsHouse";
import Shops from "../views/mainPages/Shops";
import Offices from "../views/mainPages/Offices";
import Lands from "../views/mainPages/Lands";
import Houses from "../views/mainPages/Houses";
import Buildings from "../views/mainPages/Buildings";
import ApartmentsForSale from "../views/mainPages/ApartmentsForSale";
import ApartmentsForRent from "../views/mainPages/ApartmentsForRent";
import AgriculturalFields from "../views/mainPages/AgriculturalFields";
import BoscoUsers from "../views/mainPages/BoscoUsers";
//Create Pages Routes Section
import CreateVilla from "../views/creates/CreateVilla";
import CreateLand from "../views/creates/CreateLand";
import CreateShop from "../views/creates/CreateShop";
import CreateHouse from "../views/creates/CreateHouse";
import CreateAgriculturalFields from "../views/creates/CreateAgriculturalFields";
import CreateOffice from "../views/creates/CreateOffice";
import CreateStudentHousing from "../views/creates/CreateStudentHousing";
import CreateBuilding from "../views/creates/CreateBuilding";
import CreateApartmentForSale from "../views/creates/CreateApartmentForSale";
import CreateApartmentForRent from "../views/creates/CreateApartmentForRent";
import CreateUser from "../views/creates/CreateUser";
//Edit Pages Routes Section
import EditVilla from "../views/edits/EditVilla";
import EditShop from "../views/edits/EditShop";
import EditOffice from "../views/edits/EditOffice";
import EditLand from "../views/edits/EditLand";
import EditHouse from "../views/edits/EditHouse";
import EditBuilding from "../views/edits/EditBuilding";
import EditStudentHouse from "../views/edits/EditStudentHouse";
import EditAgriculturalField from "../views/edits/EditAgriculturalField";
import EditApartmentForRent from "../views/edits/EditApartmentForRent";
import EditApartmentForSale from "../views/edits/EditApartmentForSale";
////////////Edit User 
import EditUser from "../views/edits/EditUser";
////Info Pages Routes Section
import ApartmentForRentInfo from "../views/showSinglePageData/ApartmentForRentInfo";
import ApartmentForSaleInfo from "../views/showSinglePageData/ApartmentForSaleInfo";
import HouseInfo from "../views/showSinglePageData/HouseInfo";
import BuildingInfo from "../views/showSinglePageData/BuildingInfo";
import VillaInfo from "../views/showSinglePageData/VillaInfo";
import OfficeInfo from "../views/showSinglePageData/OfficeInfo";
import ShopInfo from "../views/showSinglePageData/ShopInfo";
import StudentHouseInfo from "../views/showSinglePageData/StudentHouseInfo";
import AgriculturalFieldInfo from "../views/showSinglePageData/AgriculturalFieldInfo";
import LandInfo from "../views/showSinglePageData/LandInfo";
//Categories pages route
import CreateFinishingType from "../views/categories/creates/CreateFinishingType";
import CreateSourceOfWater from "../views/categories/creates/CreateSourceOfWater";
import CreateSketch from "../views/categories/creates/CreateSketch";
import CreateKitchen from "../views/categories/creates/CreateKitchen";
import CreateBathroom from "../views/categories/creates/CreateBathroom";
import CreateFloor from "../views/categories/creates/CreateFloor";
import CreateFurniture from "../views/categories/creates/CreateFurniture";
import CreateBalcony from "../views/categories/creates/CreateBalcony";
import CreateRoom from "../views/categories/creates/CreateRoom";
import CreatePower from "../views/categories/creates/CreatePower";
import CreateAdvancedPayment from "../views/categories/creates/CreateAdvancedPayment";
import CreateGuard from "../views/categories/creates/CreateGuard";
import CreateHall from "../views/categories/creates/CreateHall";
import CreateCity from "../views/categories/creates/CreateCity";
import CreateGeneral from "../views/categories/creates/CreateGeneral";
import CreateElevator from "../views/categories/creates/CreateElevator";
import CreateParking from "../views/categories/creates/CreateParking";
import CreateAirCondition from "../views/categories/creates/CreateAirCondition";
import CreateWall from "../views/categories/creates/CreateWall";
import CreateAdvertismentFor from "../views/categories/creates/CreateAdvertismentFor";
import CreateOwnership from "../views/categories/creates/CreateOwnership";
import CreateMeasurement from "../views/categories/creates/CreateMeasurement";
import CreateCurrencyType from "../views/categories/creates/CreateCurrencyType";
import CreateBoscoBouquet from "../views/categories/creates/CreateBoscoBouquet";
import CreateRole from "../views/categories/creates/CreateRole";
///show delete message
import DeleteMessage from "../views/deletes/DeleteMessage";
//Login
import BoLogIn from "../views/BoLogIn";
///Main Admin Data Pages
import AdminApartmentsForRent from "../views/superAdminPages/AdminApartmentsForRent";
import AdminApartmentsForSale from "../views/superAdminPages/AdminApartmentsForSale";
import AdminAgriculturalFields from "../views/superAdminPages/AdminAgriculturalFields";
import AdminBuildings from "../views/superAdminPages/AdminBuildings";
import AdminHouses from "../views/superAdminPages/AdminHouses";
import AdminLands from "../views/superAdminPages/AdminLands";
import AdminOffices from "../views/superAdminPages/AdminOffices";
import AdminShops from "../views/superAdminPages/AdminShops";
import AdminStudentsHouse from "../views/superAdminPages/AdminStudentsHouse";
import AdminVillas from "../views/superAdminPages/AdminVillas";
/////////Ads/////////////////////////////////////////
import Wesco from "../views/ads/Wesco"
import Ghalia from "../views/ads/Ghalia"

Vue.use(VueRouter);
  ////Bosco Navigation Guard
  function boscoNavigationGuard(to, from, next) {
    let authenticated = store.getters['isLoggedIn'];
    if(authenticated){
     // allow to enter route
      next()
    } else {
     // go to home page
     next('/boLogIn')
    }
   }

const routes = [
  {
    path: "/",
    name: "home",
    component: BoHome,
    meta: {
      title: 'BoHome'
    }
  },
  {
    path: "/boAgriculturalFields",
    name: "boAgriculturalFields",
    component: BoAgriculturalFields,
    meta: {
      title: 'BoHBoAgriculturalFieldsome'
    }
  },
  {
    path: "/boApartmentsForRent",
    name: "boApartmentsForRent",
    component: BoApartmentsForRent,
    meta: {
      title: 'BoApartmentsForRent'
    }
  },
  {
    path: "/boApartmentsForSale",
    name: "boApartmentsForSale",
    component: BoApartmentsForSale,
    meta: {
      title: 'BoApartmentsForSale'
    }
  },
  {
    path: "/boBuildings",
    name: "boBuildings",
    component: BoBuildings,
    meta: {
      title: 'BoBuildings'
    }
  },
  {
    path: "/boHouses",
    name: "boHouses",
    component: BoHouses,
    meta: {
      title: 'BoHouses'
    }
  },
  {
    path: "/boLands",
    name: "boLands",
    component: BoLands,
    meta: {
      title: 'BoLands'
    }
  },
  {
    path: "/boOffices",
    name: "boOffices",
    component: BoOffices,
    meta: {
      title: 'BoOffices'
    }
  },
  {
    path: "/boShops",
    name: "boShops",
    component: BoShops,
    meta: {
      title: 'BoShops'
    }
  },
  {
    path: "/boStudentsHousing",
    name: "boStudentsHousing",
    component: BoStudentsHousing,
    meta: {
      title: 'BoStudentsHousing'
    }
  },
  {
    path: "/boVillas",
    name: "boVillas",
    component: BoVillas,
    meta: {
      title: 'BoVillas'
    }
  },
  {
    path: "/boAdmin",
    name: "boAdmin",
    component: BoAdmin,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'BoAdmin'
    }
  },
  {
    path: "/boSettings",
    name: "boSettings",
    component: BoSettings,
    meta: {
      title: 'BoSettings'
    }
  },

  {
    path: "/boBouquets",
    name: "boBouquets",
    component: BoBouquets,
    meta: {
      title: 'BoBouquets'
    }
  },

  {
    path: "/agriculturalFields",
    name: "agriculturalFields",
    component: AgriculturalFields,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'AgriculturalFields'
    }
  },
  {
    path: "/apartmentsForRent",
    name: "apartmentsForRent",
    component: ApartmentsForRent,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'ApartmentsForRent'
    }
  },
  {
    path: "/apartmentsForSale",
    name: "apartmentsForSale",
    component: ApartmentsForSale,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'ApartmentsForSale'
    }
  },
  {
    path: "/boscoUsers",
    name: "boscoUsers",
    component: BoscoUsers,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'BoscoUsers'
    }
  },

  {
    path: "/buildings",
    name: "buildings",
    component: Buildings,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'Buildings'
    }
  },
  {
    path: "/houses",
    name: "houses",
    component: Houses,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'Houses'
    }
  },
  {
    path: "/lands",
    name: "lands",
    component: Lands,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'Lands'
    }
  },
  {
    path: "/offices",
    name: "offices",
    component: Offices,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'Offices'
    }
  },
  {
    path: "/shops",
    name: "shops",
    component: Shops,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'Shops'
    }
  },
  {
    path: "/studentsHouse",
    name: "studentsHouse",
    component: StudentsHouse,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'StudentsHouse'
    }
  },
  {
    path: "/villas",
    name: "villas",
    component: Villas,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'Villas'
    }
  },
  {
    path: "/createVilla",
    name: "createVilla",
    component: CreateVilla,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'CreateVilla'
    }
  },
  {
    path: "/createLand",
    name: "createLand",
    component: CreateLand,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateLand'
    }
  },
  {
    path: "/createShop",
    name: "createShop",
    component: CreateShop,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateShop'
    }
  },
  {
    path: "/createHouse",
    name: "createHouse",
    component: CreateHouse,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateHouse'
    }
  },
  {
    path: "/createAgriculturalFields",
    name: "createAgriculturalFields",
    component: CreateAgriculturalFields,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateAgriculturalFields'
    }
  },
  {
    path: "/createOffice",
    name: "createOffice",
    component: CreateOffice,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateOffice'
    }
  },
  {
    path: "/createStudentHousing",
    name: "createStudentHousing",
    component: CreateStudentHousing,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateStudentHousing'
    }
  },
  {
    path: "/createBuilding",
    name: "createBuilding",
    component: CreateBuilding,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateBuilding'
    }
  },
  {
    path: "/createApartmentForSale",
    name: "createApartmentForSale",
    component: CreateApartmentForSale,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateApartmentForSale'
    }
  },
  {
    path: "/createApartmentForRent",
    name: "createApartmentForRent",
    component: CreateApartmentForRent,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateApartmentForRent'
    }
  },
  {
    path: "/createUser",
    name: "createUser",
    component: CreateUser,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateUser'
    }
  },

  {
    path: "/editVilla",
    name: "editVilla",
    component: EditVilla,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditVilla'
    }
  },
  {
    path: "/editShop",
    name: "editShop",
    component: EditShop,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditShop'
    }
  },
  {
    path: "/editOffice",
    name: "editOffice",
    component: EditOffice,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditOffice'
    }
  },
  {
    path: "/editLand",
    name: "editLand",
    component: EditLand,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditLand'
    }
  },
  {
    path: "/editHouse",
    name: "editHouse",
    component: EditHouse,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditHouse'
    }
  },
  {
    path: "/editBuilding",
    name: "editBuilding",
    component: EditBuilding,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditBuilding'
    }
  },
  {
    path: "/editStudentHouse",
    name: "editStudentHouse",
    component: EditStudentHouse,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditStudentHouse'
    }
  },
  {
    path: "/editAgriculturalField",
    name: "editAgriculturalField",
    component: EditAgriculturalField,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditAgriculturalField'
    }
  },
  {
    path: "/editApartmentForRent",
    name: "editApartmentForRent",
    component: EditApartmentForRent,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'EditApartmentForRent'
    }
  },
  {
    path: "/editApartmentForSale",
    name: "editApartmentForSale",
    component: EditApartmentForSale,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditApartmentForSale'
    }
  },
  {
    path: "/editUser",
    name: "editUser",
    component: EditUser,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'EditUser'
    }
  },
  
  {
    path: "/apartmentForRentInfo/:id",
    name: "apartmentForRentInfo",
    component: ApartmentForRentInfo,
    meta: {
      title: 'ApartmentForRentInfo'
    }
  },
  {
    path: "/apartmentForSaleInfo/:id",
    name: "apartmentForSaleInfo",
    component: ApartmentForSaleInfo,
    meta: {
      title: 'ApartmentForSaleInfo'
    }
  },
  {
    path: "/houseInfo/:id",
    name: "houseInfo",
    component: HouseInfo,
    meta: {
      title: 'HouseInfo'
    }
  },
  {
    path: "/buildingInfo/:id",
    name: "buildingInfo",
    component: BuildingInfo,
    meta: {
      title: 'BuildingInfo'
    }
  },
  {
    path: "/villaInfo/:id",
    name: "villaInfo",
    component: VillaInfo,
    meta: {
      title: 'VillaInfo'
    }
  },
  {
    path: "/officeInfo/:id",
    name: "officeInfo",
    component: OfficeInfo,
    meta: {
      title: 'OfficeInfo'
    }
  },
  {
    path: "/shopInfo/:id",
    name: "shopInfo",
    component: ShopInfo,
    meta: {
      title: 'ShopInfo'
    }
  },
  {
    path: "/studentHouseInfo/:id",
    name: "studentHouseInfo",
    component: StudentHouseInfo,
    meta: {
      title: 'StudentHouseInfo'
    }
  },
  {
    path: "/agriculturalFieldInfo/:id",
    name: "agriculturalFieldInfo",
    component: AgriculturalFieldInfo,
    meta: {
      title: 'AgriculturalFieldInfo'
    }
  },
  {
    path: "/landInfo/:id",
    name: "landInfo",
    component: LandInfo,
    meta: {
      title: 'LandInfo'
    }
  },
  {
    path: "/boOurTeam",
    name: "boOurTeam",
    component: BoOurTeam,
    meta: {
      title: 'BoOurTeam'
    }
  },
  {
    path: "/boSupports",
    name: "boSupports",
    component: BoSupports,
    meta: {
      title: 'BoSupports'
    }
  },
  {
    path: "/boPrivacyPolicy",
    name: "boPrivacyPolicy",
    component: BoPrivacyPolicy,
    meta: {
      title: 'BoPrivacyPolicy'
    }
  },
  {
    path: "/boLogIn",
    name: "boLogIn",
    component: BoLogIn,
    meta: {
      title: 'BoLogIn'
    }
  },
  {
    path: "/adminApartmentsForRent",
    name: "adminApartmentsForRent",
    component: AdminApartmentsForRent,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'AdminApartmentsForRent'
    }
  },
  {
    path: "/adminApartmentsForSale",
    name: "adminApartmentsForSale",
    component: AdminApartmentsForSale,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'AdminApartmentsForSale'
    }
  },
  {
    path: "/adminAgriculturalFields",
    name: "adminAgriculturalFields",
    component: AdminAgriculturalFields,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'AdminAgriculturalFields'
    }
  },
  {
    path: "/adminBuildings",
    name: "adminBuildings",
    component: AdminBuildings,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'AdminBuildings'
    }
  },
  {
    path: "/adminHouses",
    name: "adminHouses",
    component: AdminHouses,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'AdminHouses'
    }
  },
  {
    path: "/adminLands",
    name: "adminLands",
    component: AdminLands,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'AdminLands'
    }
  },
  {
    path: "/adminOffices",
    name: "adminOffices",
    component: AdminOffices,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'AdminOffices'
    }
  },
  {
    path: "/adminShops",
    name: "adminShops",
    component: AdminShops,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'AdminShops'
    }
  },
  {
    path: "/adminStudentsHouse",
    name: "adminStudentsHouse",
    component: AdminStudentsHouse,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'AdminStudentsHouse'
    }
  },
  {
    path: "/adminVillas",
    name: "adminVillas",
    component: AdminVillas,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'AdminVillas'
    }
  },

  {
    path: "/createFinishingType",
    name: "createFinishingType",
    component: CreateFinishingType,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'CreateFinishingType'
    }
  },
  {
    path: "/createSourceOfWater",
    name: "createSourceOfWater",
    component: CreateSourceOfWater,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateSourceOfWater'
    }
  },
  {
    path: "/createSketch",
    name: "createSketch",
    component: CreateSketch,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateSketch'
    }
  },
  {
    path: "/createKitchen",
    name: "createKitchen",
    component: CreateKitchen,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateKitchen'
    }
  },
  {
    path: "/createBathroom",
    name: "createBathroom",
    component: CreateBathroom,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateBathroom'
    }
  },
  {
    path: "/createFloor",
    name: "createFloor",
    component: CreateFloor,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateFloor'
    }
  },
  {
    path: "/createFurniture",
    name: "createFurniture",
    component: CreateFurniture,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateFurniture'
    }
  },
  {
    path: "/createBalcony",
    name: "createBalcony",
    component: CreateBalcony,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateBalcony'
    }
  },
  {
    path: "/createRoom",
    name: "createRoom",
    component: CreateRoom,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateRoom'
    }
  },
  {
    path: "/createPower",
    name: "createPower",
    component: CreatePower,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreatePower'
    }
  },
  {
    path: "/createAdvancedPayment",
    name: "createAdvancedPayment",
    component: CreateAdvancedPayment,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateAdvancedPayment'
    }
  },
  {
    path: "/createGuard",
    name: "createGuard",
    component: CreateGuard,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'CreateGuard'
    }
  },
  {
    path: "/createHall",
    name: "createHall",
    component: CreateHall,
    beforeEnter : boscoNavigationGuard,
    meta: {
      title: 'CreateHall'
    }
  },
  {
    path: "/createCity",
    name: "createCity",
    component: CreateCity,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateCity'
    }
  },
  {
    path: "/createGeneral",
    name: "createGeneral",
    component: CreateGeneral,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateGeneral'
    }
  },
  {
    path: "/createElevator",
    name: "createElevator",
    component: CreateElevator,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateElevator'
    }
  },
  {
    path: "/createParking",
    name: "createParking",
    component: CreateParking,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateParking'
    }
  },
  {
    path: "/createAirCondition",
    name: "createAirCondition",
    component: CreateAirCondition,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateAirCondition'
    }
  },
  {
    path: "/createWall",
    name: "createWall",
    component: CreateWall,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateWall'
    }
  },
  {
    path: "/createAdvertismentFor",
    name: "createAdvertismentFor",
    component: CreateAdvertismentFor,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateAdvertismentFor'
    }
  },
  {
    path: "/createOwnership",
    name: "createOwnership",
    component: CreateOwnership,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateOwnership'
    }
  },
  {
    path: "/createMeasurement",
    name: "createMeasurement",
    component: CreateMeasurement,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateMeasurement'
    }
  },
  {
    path: "/createCurrencyType",
    name: "createCurrencyType",
    component: CreateCurrencyType,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateCurrencyType'
    }
  },
  {
    path: "/createBoscoBouquet",
    name: "createBoscoBouquet",
    component: CreateBoscoBouquet,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateBoscoBouquet'
    }
  },
  {
    path: "/createRole",
    name: "createRole",
    component: CreateRole,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'CreateRole'
    }
  },
  {
    path: "/deleteMessage",
    name: "deleteMessage",
    component: DeleteMessage,
    beforeEnter : boscoNavigationGuard,

    meta: {
      title: 'DeleteMessage'
    }
  },
  {
    path: "/wesco",
    name: "wesco",
    component: Wesco,
    meta: {
      title: 'wesco'
    }
  },
  {
    path: "/ghalia",
    name: "ghalia",
    component: Ghalia,
    meta: {
      title: 'ghalia'
    }
  },
  
  {
    path: "*",
    redirect: "/",
  },
  //{
  // path: "/about",
  //name: "about",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () =>
  //import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition){
    if(to.hash){
      return {
        selector: to.hash
      };
    }
    if(savedPosition){
      return savedPosition
    }
    return {x: 0, y: 0};
   }
});
export default router;
