<template>
  <div id="singlePost">
    <v-layout row wrap>
      <v-flex xs12 md12>
        <h2 class="adsForWhat">
          <span class=""> بيانات الشقة للإيجار </span>
        </h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <p class="singleMainTitle">
          {{ apartmentForRentInfo.city_title }}
        </p>
        <p class="singleMainTitle villa_neighborhoodPara">
          {{ apartmentForRentInfo.apartment_for_rent_neighborhood }}
          -
          {{ apartmentForRentInfo.apartment_for_rent_address }}
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <h2 class="pb-1 price">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M2,5H22V20H2V5M20,18V7H4V18H20M17,8A2,2 0 0,0 19,10V15A2,2 0 0,0 17,17H7A2,2 0 0,0 5,15V10A2,2 0 0,0 7,8H17M17,13V12C17,10.9 16.33,10 15.5,10C14.67,10 14,10.9 14,12V13C14,14.1 14.67,15 15.5,15C16.33,15 17,14.1 17,13M15.5,11A0.5,0.5 0 0,1 16,11.5V13.5A0.5,0.5 0 0,1 15.5,14A0.5,0.5 0 0,1 15,13.5V11.5A0.5,0.5 0 0,1 15.5,11M13,13V12C13,10.9 12.33,10 11.5,10C10.67,10 10,10.9 10,12V13C10,14.1 10.67,15 11.5,15C12.33,15 13,14.1 13,13M11.5,11A0.5,0.5 0 0,1 12,11.5V13.5A0.5,0.5 0 0,1 11.5,14A0.5,0.5 0 0,1 11,13.5V11.5A0.5,0.5 0 0,1 11.5,11M8,15H9V10H8L7,10.5V11.5L8,11V15Z"
              />
            </svg>
          </span>
          {{ apartmentForRentInfo.apartment_for_rent_price }}
          {{ apartmentForRentInfo.currency_type_title }}
        </h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <p class="singleMainPara pb-1">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M20 2H4C2.9 2 2 2.9 2 4V20C2 21.11 2.9 22 4 22H20C21.11 22 22 21.11 22 20V4C22 2.9 21.11 2 20 2M4 6L6 4H10.9L4 10.9V6M4 13.7L13.7 4H18.6L4 18.6V13.7M20 18L18 20H13.1L20 13.1V18M20 10.3L10.3 20H5.4L20 5.4V10.3Z"
              />
            </svg>
          </span>
          المساحة:
          {{ apartmentForRentInfo.apartment_for_rent_area }}
          {{ apartmentForRentInfo.measurement_title }}
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M15 6H22V9H18V13H14V17H10V21H3V18H7V14H11V10H15V6M10.17 6.66L4.66 12.17L2.83 10.34L8.34 4.83L6.5 3H12V8.5L10.17 6.66Z"
              />
            </svg>
          </span>
          الطابق:
          {{ apartmentForRentInfo.floor_title }}
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md6 class="mb-4 mt-2">
        <v-img
          :src="`data:image/png;base64, ${apartmentForRentInfo.image}`"
          aspect-ratio="1.7"
          class="mb-2 singleImage"
        ></v-img>
      </v-flex>
      <v-flex xs12 md5 class="secondFlex">
        <h3 class="pb-2 customerNmae">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M7 12C9.2 12 11 10.2 11 8S9.2 4 7 4 3 5.8 3 8 4.8 12 7 12M11 20V14.7C9.9 14.3 8.5 14 7 14C3.1 14 0 15.8 0 18V20H11M15 4C13.9 4 13 4.9 13 6V18C13 19.1 13.9 20 15 20H22C23.1 20 24 19.1 24 18V6C24 4.9 23.1 4 22 4H15Z"
              />
            </svg>
          </span>
          مكتب:  
          {{ apartmentForRentInfo.bosco_logged_in_customer }}
        </h3>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M21 13C21.6 13 22.1 13.2 22.4 13.6C22.8 14 23 14.5 23 15L15 18L8 16V7H9.9L17.2 9.7C17.7 9.9 18 10.3 18 10.8C18 11.1 17.9 11.4 17.7 11.6C17.5 11.8 17.2 12 16.8 12H14L12.3 11.3L12 12.2L14 13H21M2 7H6V18H2V7Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            مقدم:
            {{ apartmentForRentInfo.advanced_payment_title }}
          </span>
        </div>
        <div class="singleMainPara pb-2">
        <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M2 7H22V10H20L21 19H18.5L17.94 14H6.06L5.5 19H3L4 10H2V7M17.5 10H6.5L6.29 12H17.71L17.5 10Z"
              />
            </svg>
          </span>
          أثاثات:
          {{ apartmentForRentInfo.furniture_title }}
         </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            الغرف:
            {{ apartmentForRentInfo.room_title }}
          </span>
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M12.5 7C12.5 5.89 13.39 5 14.5 5H18C19.1 5 20 5.9 20 7V9.16C18.84 9.57 18 10.67 18 11.97V14H12.5V7M6 11.96V14H11.5V7C11.5 5.89 10.61 5 9.5 5H6C4.9 5 4 5.9 4 7V9.15C5.16 9.56 6 10.67 6 11.96M20.66 10.03C19.68 10.19 19 11.12 19 12.12V15H5V12C5 10.9 4.11 10 3 10S1 10.9 1 12V17C1 18.1 1.9 19 3 19V21H5V19H19V21H21V19C22.1 19 23 18.1 23 17V12C23 10.79 21.91 9.82 20.66 10.03Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            صالة:
            {{ apartmentForRentInfo.hall_title }}
          </span>
        </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M18 10V7C18 5.34 16.66 4 15 4S12 5.34 12 7H14C14 6.45 14.45 6 15 6S16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18M13 18H11V12H13V18Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            المطابخ:
            {{ apartmentForRentInfo.kitchen_title }}
          </span>
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M9,22H17V19.5C19.41,17.87 21,15.12 21,12V4A2,2 0 0,0 19,2H15C13.89,2 13,2.9 13,4V12H3C3,15.09 5,18 9,19.5V22M5.29,14H18.71C18.14,15.91 16.77,17.5 15,18.33V20H11V18.33C9,18 5.86,15.91 5.29,14M15,4H19V12H15V4M16,5V8H18V5H16Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            حمام:
            {{ apartmentForRentInfo.bathroom_title }}
          </span>
        </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M10 10V12H8V10H10M16 12V10H14V12H16M21 14V22H3V14H4V10C4 5.58 7.58 2 12 2S20 5.58 20 10V14H21M7 16H5V20H7V16M11 16H9V20H11V16M11 4.08C8.16 4.56 6 7.03 6 10V14H11V4.08M13 14H18V10C18 7.03 15.84 4.56 13 4.08V14M15 16H13V20H15V16M19 16H17V20H19V16Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            بلكونة:
            {{ apartmentForRentInfo.balcony_title }}
          </span>
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M6.59,0.66C8.93,-1.15 11.47,1.06 12.04,4.5C12.47,4.5 12.89,4.62 13.27,4.84C13.79,4.24 14.25,3.42 14.07,2.5C13.65,0.35 16.06,-1.39 18.35,1.58C20.16,3.92 17.95,6.46 14.5,7.03C14.5,7.46 14.39,7.89 14.16,8.27C14.76,8.78 15.58,9.24 16.5,9.06C18.63,8.64 20.38,11.04 17.41,13.34C15.07,15.15 12.53,12.94 11.96,9.5C11.53,9.5 11.11,9.37 10.74,9.15C10.22,9.75 9.75,10.58 9.93,11.5C10.35,13.64 7.94,15.39 5.65,12.42C3.83,10.07 6.05,7.53 9.5,6.97C9.5,6.54 9.63,6.12 9.85,5.74C9.25,5.23 8.43,4.76 7.5,4.94C5.37,5.36 3.62,2.96 6.59,0.66M5,16H7A2,2 0 0,1 9,18V24H7V22H5V24H3V18A2,2 0 0,1 5,16M5,18V20H7V18H5M12.93,16H15L12.07,24H10L12.93,16M18,16H21V18H18V22H21V24H18A2,2 0 0,1 16,22V18A2,2 0 0,1 18,16Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            تكييف:
            {{ apartmentForRentInfo.air_condition_title }}
          </span>
        </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M8.5 6C9.19 6 9.75 6.56 9.75 7.25S9.19 8.5 8.5 8.5 7.25 7.94 7.25 7.25 7.81 6 8.5 6M11 14H10V18H7V14H6V11.5C6 10.4 6.9 9.5 8 9.5H9C10.1 9.5 11 10.4 11 11.5V14M15.5 17L13 13H18L15.5 17M13 11L15.5 7L18 11H13Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            مصعد:
            {{ apartmentForRentInfo.elevator_title }}
          </span>
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M12,21L15.6,16.2C14.6,15.45 13.35,15 12,15C10.65,15 9.4,15.45 8.4,16.2L12,21M12,3C7.95,3 4.21,4.34 1.2,6.6L3,9C5.5,7.12 8.62,6 12,6C15.38,6 18.5,7.12 21,9L22.8,6.6C19.79,4.34 16.05,3 12,3M12,9C9.3,9 6.81,9.89 4.8,11.4L6.6,13.8C8.1,12.67 9.97,12 12,12C14.03,12 15.9,12.67 17.4,13.8L19.2,11.4C17.19,9.89 14.7,9 12,9Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            واي فاي:
            {{ apartmentForRentInfo.general_title }}
          </span>
        </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M17.13,17C15.92,18.85 14.11,20.24 12,20.92C9.89,20.24 8.08,18.85 6.87,17C6.53,16.5 6.24,16 6,15.47C6,13.82 8.71,12.47 12,12.47C15.29,12.47 18,13.79 18,15.47C17.76,16 17.47,16.5 17.13,17Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            تامين:
            {{ apartmentForRentInfo.guard_title }}
          </span>
        </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M17.13,17C15.92,18.85 14.11,20.24 12,20.92C9.89,20.24 8.08,18.85 6.87,17C6.53,16.5 6.24,16 6,15.47C6,13.82 8.71,12.47 12,12.47C15.29,12.47 18,13.79 18,15.47C17.76,16 17.47,16.5 17.13,17Z"
              />
            </svg>
          </span>
          <span class="titleItem">
          موقف عربات :
          {{ apartmentForRentInfo.parking_title }}
        </span>
        </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M10 2L12 9H2L4 2M6 10H8V20H11V22H3V20H6V10M20 8L22 15H12L14 8M16 16H18V20H21V22H13V20H16V16Z"
              />
            </svg>
          </span>
          نوع التشطيب:
          {{ apartmentForRentInfo.finishing_type_title }}
        </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M3.33 16H11V13H4L3.33 16M13 16H20.67L20 13H13V16M21.11 18H13V22H22L21.11 18M2 22H11V18H2.89L2 22M11 8H13V11H11V8M15.76 7.21L17.18 5.79L19.3 7.91L17.89 9.33L15.76 7.21M4.71 7.91L6.83 5.79L8.24 7.21L6.12 9.33L4.71 7.91M3 2H6V4H3V2M18 2H21V4H18V2M12 7C14.76 7 17 4.76 17 2H7C7 4.76 9.24 7 12 7Z"
              />
            </svg>
          </span>
          <span class="titleItem">
            مصدر طاقة إضافي:
            {{ apartmentForRentInfo.power_title }}
          </span>
        </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
              />
            </svg>
          </span>
          <span class="titleItem" style="color: #f15bb5; font-size: 25px; font-weight:900;">
            {{ apartmentForRentInfo.apartment_for_rent_owner_phone_number }}
          </span>
        </div>
        <div class="singleMainPara pb-2">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
              />
            </svg>
          </span>
          <span class="titleItem" style="color: #f15bb5; font-size: 25px; font-weight:900;">
            {{ apartmentForRentInfo.apartment_for_rent_whatsApp_number }}
          </span>
        </div>
        <div class="date">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
              />
            </svg>
          </span>
          {{  apartmentForRentInfo.created_At }}
        </div>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 md4 class="mt-2 ml-4  notesFlex">
        <p class="singleMainPara pb-1">
          <span class="svgItem">
            <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
              <path
                fill="#00bbf9"
                d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M8,14H6V12H8V14M8,11H6V9H8V11M8,8H6V6H8V8M15,14H10V12H15V14M18,11H10V9H18V11M18,8H10V6H18V8Z"
              />
            </svg>
          </span>
          مميزات العقار:
          {{ apartmentForRentInfo.apartment_for_rent_notes }}
        </p>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
const ROOT_URL = "/api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ApartmentsForRentInfo",

  data() {
    return {
      id: this.$route.params.id,
      apartmentForRentInfo: {},
    };
  },
  methods: {
    ...mapActions(["fetchApartmentsForRent"]),
  },
  created() {
    this.fetchApartmentsForRent();

    axios
      .get(`${ROOT_URL}/apartments_for_rent/get/` + this.id)
      .then((response) => {
        const singlePost = response.data.apartment_for_rent;
        this.apartmentForRentInfo = singlePost;
      })
      .catch((error) => {
        error;
      });
  },
  computed: {
    ...mapGetters(["allApartmentsForRent"]),
  },
};
</script>

<style scoped>
#singlePost {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.adsForWhat{
  color: #000;
  font-size: 25px;
  font-weight: 600;
   padding: 1px;
}
.singleMainTitle {
  color: gray;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.7;
  padding: 1px;
}
.customerNmae{
  color: #f15bb5;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.7;
  padding: 1px;
}
.notesFlex{
  margin-right: 20px;

}
.singleMainPara {
  color: #000;
  font-size: 16px;
  white-space: pre-wrap;
}
.secondFlex {
  margin-right: 15px;
}
.svgItem {
  opacity: 0.6;
}
.titleItem {
  padding-left: 15px;
}
.price {
  color: #f15bb5;
}
.date {
  font-weight: 500;
  padding: 5px;
  opacity: 0.5;
}
/* Media Queries */
/* most mobiles has width about 400px */
@media screen and (max-width: 500px) {
  #singlePost {
    padding: 30px;
    margin-top: 15px;
}
  .notesFlex{
  margin-bottom: 100px;
  
}
 .villa_neighborhoodPara {
  font-size: 15px;
  font-weight: 900;
}
} 
@media screen and (max-width: 800px) {
  /* any device has 800px and less  "Ipad"*/
}
</style>
