<template>
<!-- Single Template To Delete Any Template -->
  <v-dialog v-model="dialog" persistent max-width="350px">
    <template v-slot:activator="{ on }">
        <svg v-on="on" class="deleteSvg" style="width: 25px; height: 25px; " viewBox="0 0 24 24">
          <path
            fill="#FF0000"
            d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
          />
        </svg>
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        بوسكو للتسويق العقاري 
      </v-card-title>
      <v-card-text class="text-h6 mt-4 lighten-2">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <h4>هل أنت متأكد من  الحزف؟</h4>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
      
        <v-btn @click="onDeleteBtnClicked"  class="btnDelete">
          مسح
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="cancleBtn"  @click="dialog = false">
         الغاء
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteItems",
  data: () => ({
    dialog: false,
  }),
    mounted: function() {
    if (!this.isLoggedIn) {
      this.$router.push({ path: "/" });
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    onDeleteBtnClicked: function () {
      this.$emit("want_to_delete_item");
    },
  },
};
</script>

<style scoped>

.btnElga {
  font-size: 16px;
  font-weight: 700;
}
.deleteSvg{
  cursor: pointer;
}
.btnDelete {
   margin-right: 15px;
  margin-left: 20px;
  margin-bottom: 10px;
  background:  #CC0000 !important;
  color: #fff;
  font-size: 15px;
}
.cancleBtn{
   color: #9b5de5;
   border: 1px solid #9b5de5;
}


</style>
