import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      powers: [],
};
/////////////// Getters
const getters = {
  allPowers: (state) => state.powers
};
/////////////// Actions
const actions = {
  async fetchPowers({ commit }) {
    const response = await apiServices.fetch_powers();
    commit("setPowers", response.data.powers);
  },
};
/////////////// MUTATIONS 
const mutations = {
      setPowers: (state, powers) => {
      state.powers = powers;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
