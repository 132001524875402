import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      air_conditions: [],
};
/////////////// Getters
const getters = {
  allAirConditions: (state) => state.air_conditions
};
/////////////// Actions
const actions = {
  async fetchAirConditions({ commit }) {
    const response = await apiServices.fetch_air_conditions();
    commit("setAirConditions", response.data.conditions);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setAirConditions: (state, air_conditions) => {
      state.air_conditions = air_conditions;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
