import apiServices from "../../api/apiServices";
///////////////////////State 
const state = {
      lands: [],
      totalLandsByCustomer: [],
};
///////////////////////Getters
const getters = {
  allLands: (state) => state.lands.reverse(),
  allLandsByCustomer: (state) => state.totalLandsByCustomer
};
//////////////////////Actions
const actions = {
  async fetchLands({ commit, rootGetters }) {
    const response = await apiServices.fetch_lands();
      //filter shops posts by customer
      let total_lands_posts = response.data.lands.filter((items) => {
        const matchPost = items.bosco_customer_email.includes(
          rootGetters.get_bosco_customer.customer_email
        );
        return matchPost;
      });
        commit("setLandsByCustomer", total_lands_posts);
        commit("setLands", response.data.lands);
  }
};

///////////////////MUTATIONS
const mutations = {
  setLands: (state, lands) => {
      state.lands = lands;
  },
  setLandsByCustomer: (state, total_lands_posts) => {
    state.totalLandsByCustomer = total_lands_posts;
}
}
export default {
  state,
  getters,
  actions,
  mutations,
};
