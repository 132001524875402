import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      bathrooms: [],
};
/////////////// Getters
const getters = {
  allBathrooms: (state) => state.bathrooms
};
/////////////// Actions
const actions = {
  async fetchBathrooms({ commit }) {
    const response = await apiServices.fetch_bathrooms();
    commit("setBathrooms", response.data.bathrooms);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setBathrooms: (state, bathrooms) => {
      state.bathrooms = bathrooms;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
