import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      finishings: [],
};
/////////////// Getters
const getters = {
  allFinishings: (state) => state.finishings
};
/////////////// Actions
const actions = {
  async fetchHFinishings({ commit }) {
    const response = await apiServices.fetch_finishings();
    commit("setFinishings", response.data.finishings);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setFinishings: (state, finishings) => {
      state.finishings = finishings;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
