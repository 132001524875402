
import apiServices from "../../api/apiServices";
///////////////////////State 
const state = {
      buildings: [],
      totalBuildingsByCustomer: [],
};
///////////////////////Getters
const getters = {
  allBuildings: (state) => state.buildings.reverse(),
  allBuildingsByCustomer: (state) => state.totalBuildingsByCustomer.reverse(),

};
//////////////////////Actions
const actions = {
  async fetchBuildings({ commit, rootGetters }) {
    const response = await apiServices.fetch_buildings();
              //filter buildings posts by customer
              let total_buildings_posts =
              response.data.buildings.filter((items) => {
                const matchPost = items.bosco_customer_email.includes(
                  rootGetters.get_bosco_customer.customer_email
                );
                return matchPost;
              });
   
    commit("setBuildingsByCustomer", total_buildings_posts);
    commit("setBuildings", response.data.buildings);
  }
};

///////////////////MUTATIONS
const mutations = {
      setBuildings: (state, buildings) => {
      state.buildings = buildings;
  },
  setBuildingsByCustomer: (state, total_buildings_posts) => {
    state.totalBuildingsByCustomer =
    total_buildings_posts;
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
};
