<template>
  <v-container id="culture">
      <v-layout row wrap class="adsImageLayout">
      <!-- <v-spacer></v-spacer> -->
      <v-flex xs12 md12 class="adsImagFlex">
        <v-img :src="wesco5Logo" class="pannerImg"></v-img>
      </v-flex>
      <!-- <v-spacer></v-spacer> -->
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md12 class="">
        <h3 class="wescoTitle" style="color: #9b5de5">ويسكو للطاقة الشمسية</h3>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12>
    <h4 class="wescoTitle">
      ويسكو شركة تعمل في مجال الطاقة الشمسية والطاقة البديلة.
    </h4>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="adsImageLayout">
      <!-- <v-spacer></v-spacer> -->

      <v-flex xs12 md3 class="adsImagFlex">
            <v-img :src="wesco1Logo"  class="adsImage"></v-img>
      </v-flex>
      <v-flex xs12 md3 class="adsImagFlex">
            <v-img :src="wesco2Logo"  class="adsImage"></v-img>
      </v-flex>
      <v-flex xs12 md3 class="adsImagFlex">
            <v-img :src="wesco6Logo"  class="adsImage"></v-img>
      </v-flex>
      
      <!-- <v-spacer></v-spacer> -->

    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
     <p class="wescoTitle" style="color: #9b5de5">
     مفاجاة عروض الشتاء
    </p>
      </v-flex>
      <v-flex xs12 md12>
     <p class="wescoTitle">
      نحن دائما نسعي إلي إرضاء عملائنا الكرام ودوما نهدف لتوفير افضل الاسعار والخدمات
    </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12>
     <p class="wescoTitle">
        اتصل الان    
      </p>
      </v-flex>

    </v-layout>
    <v-layout row wrap class="">
      <v-spacer></v-spacer>
      <v-flex xs5 md5 class="whatsApFlex">
       <a href ="https://wa.me/+249122123420" target="_blank">
        <v-btn  class="mx-4" icon>
          <svg  class="socialIcon" viewBox="0 0 24 24">
            <path
              fill="#fee440"
              d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
            />
          </svg>
        </v-btn>
      </a>
      </v-flex>
      <v-flex xs5 md5 class="whatsApFlex">
            <a href ="https://www.facebook.com/profile.php?id=100065494031101" target="_blank">
        <v-btn
          class="mx-4"
          icon
        >
          <svg class="socialIcon" viewBox="0 0 24 24">
            <path
              fill="#fee440"
              d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
            />
          </svg>
        </v-btn>
        </a>
      </v-flex>
      <v-spacer></v-spacer>

    </v-layout>
    <v-layout row wrap class="lastCard">
      <v-spacer></v-spacer>
      <v-flex xs12 md12 class="addressFlex">
       <p class="addressPara">
            الخرطوم -  شارع ابراهيم شمس الدين
             
       </p>
       <p class="addressPara">
        wesco.sudan@gmail.com
        </p>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
  </v-container>
</template>

<script>
//const wesco = require("../../assets/images/wesco.png");
const wesco1 = require("../../assets/images/wesco1.jpg");
const wesco2 = require("../../assets/images/wesco2.jpg");
const wesco5 = require("../../assets/images/wesco5.jpg");
const wesco6 = require("../../assets/images/wesco6.jpg");


export default {
  name: "Wesco",
  data() {
    return {
      search: "",
     // wescoLogo: wesco,
      wesco1Logo: wesco1,
      wesco2Logo: wesco2,
      wesco5Logo: wesco5,
      wesco6Logo: wesco6
    };
  },
  components: {},
  computed: {},
  methods: {},

};
</script>

<style scoped>
#culture {
 text-align: center;
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.adsImageLayout{
      margin-top: 20px;
      margin-bottom: 20px;
}
.adsImagFlex{
     margin: 0 auto;
}
.adsImage {
  width: 100%!important;
  height: 350px!important;
  border: 1px solid #9b5de5;
  margin-bottom: 10px;
}
.pannerImg{
      height: 100px;
}
.mainTitle {
  color: #000;
}

.whatsApFlex{
      margin: 10px;
      padding: 20px;
      background: linear-gradient(to right, #000, #9b5de5);
      border: 3px dashed ;
      border-radius: 5px;
}

.wescoTitle {
  padding: 20px;     
  color: #000;
  font-size: 30px;
  font-weight: 600;
}
.addressFlex{
      background: #9b5de5;
}
.addressPara{
      padding: 20px;
      font-size: 18px;
      font-weight: 900;
}
.lastCard {
 margin-top: 40px;
  margin-bottom: 140px;
}
.backgroundImageFlex{
  margin: 0 auto;
}
/* Media Queries */

/* most mobiles has width about 400px */
@media screen and (max-width: 500px) {
  #culture {
    padding: 8px;
    margin-top: 15px;
  }
  .employeeTableTitle {
    margin-right: 8px;
    padding-top: 10px;
  }
}
.socialIcon {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 800px) {
  /* any device has 800px and less  "Ipad"*/

}
</style>
