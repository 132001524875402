import apiServices from "../../api/apiServices";
/////////////// State
const state = {
  offices: [],
  totalOfficesByCustomer: [],
};
/////////////// Getters
const getters = {
  allOffices: (state) => state.offices.reverse(),
  allOfficesByCustomer: (state) => state.totalOfficesByCustomer.reverse(),
};
/////////////// Actions
const actions = {
  async fetchOffices({ commit, rootGetters }) {
    const response = await apiServices.fetch_offices();
    //filter offices posts by customer
    let total_offices_posts = response.data.offices.filter((items) => {
      const matchPost = items.bosco_customer_email.includes(
        rootGetters.get_bosco_customer.customer_email
      );
      return matchPost;
    });

    commit("setOfficesByCustomer", total_offices_posts);
    commit("setOffices", response.data.offices);
  },
};
/////////////// MUTATIONS
const mutations = {
  setOffices: (state, offices) => {
    state.offices = offices;
  },
  setOfficesByCustomer: (state, total_offices_posts) => {
    state.totalOfficesByCustomer = total_offices_posts;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
