import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      parkings: [],
};
/////////////// Getters
const getters = {
  allParkings: (state) => state.parkings
};
/////////////// Actions
const actions = {
  async fetchParkings({ commit }) {
    const response = await apiServices.fetch_parkings();
    commit("setParkings", response.data.parkings);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setParkings: (state, parkings) => {
      state.parkings = parkings;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
