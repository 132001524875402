import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      balconies: [],
};
/////////////// Getters
const getters = {
  allBalconies: (state) => state.balconies
};
/////////////// Actions
const actions = {
  async fetchBalconies({ commit }) {
    const response = await apiServices.fetch_balconies();
    commit("setBalconies", response.data.balconies);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setBalconies: (state, balconies) => {
      state.balconies = balconies;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
