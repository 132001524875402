import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      ownerships: [],
};
/////////////// Getters
const getters = {
  allOwnerships: (state) => state.ownerships
};
/////////////// Actions
const actions = {
  async fetchOwnerships({ commit }) {
    const response = await apiServices.fetch_ownerships();
    commit("setOwnerships", response.data.ownerships);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setOwnerships: (state, ownerships) => {
      state.ownerships = ownerships;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
