<template>
  <div id="employees">
    <template>
      <v-row justify="center">
        <v-dialog class="mainDialog" v-model="dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title class="dialogCardTitle">
              <h4>يجب تجديد الباقات الاتية:</h4>
            </v-card-title>
            <v-card-text
              v-for="(item, value) in finished_bouquets"
              :key="item._id"
              class="text-h6 lighten-2 DialogCardText"
            >
              <h6 class="dialog_date">
                {{ value + 1 }} - {{ item.customer_name }}: تاريخ انتهاء الباقة:
                {{ item.bosco_bouquet_expiration_day }}
              </h6>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                class="dialog_btn"
                text
                @click="dialog = false"
              >
                موافق
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-layout row wrap class="">
      <v-flex xs7 md10 class="">
        <p class="employeeTableTitle"> 
          عدد العملاء:
          {{allUsers.length}}
        </p>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs5 md2 class="">
        <router-link to="/createUser">
          <v-btn class="addEmployeeBtn" elevation="0">
            إضافة عميل
            <svg
              style="width: 24px; height: 24px; margin-right: 3px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#9b5de5"
                d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z"
              />
            </svg>
          </v-btn>
        </router-link>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <v-toolbar elevation="0" class="customerTableToolBar">
          <v-text-field
            flat
            hide-details
            v-model="search"
            class="search"
            label="بحث"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
          >
          </v-text-field>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <!-- الجدول الاول -->
        <v-simple-table
          class="simpleTable-4 mainTable"
          fixed-header
          height="500px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="tHead">رقم العميل</th>
                <th class="tHead">اسم العميل</th>
                <th class="tHead">الرقم الوطني</th>
                <th class="tHead">رقم الهاتف</th>
                <th class="tHead">رقم الواتساب</th>
                <th class="tHead">نوع الباقة</th>
                <th class="tHead">صلاحية العميل</th>
                <th class="tHead">رصيد الإعلانات</th>
                <th class="tHead">الإيميل</th>
                <th class="tHead">المدينة</th>
                <th class="tHead">الحي</th>
                <th class="tHead">العنوان</th>
                <th class="tHead">المهنة</th>
                <th class="tHead">تاريخ الميلاد</th>
                <!-- <th class="tHead">فضل كم إعلان؟</th> -->
                <th class="tHead">انتهاء الباقة</th>
                <th class="tHead">مكان العمل</th>
                <th class="tHead">ملاحظات</th>
                <th class="tHead">تعديل</th>
                <th class="tHead">مسح</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in search_customers" :key="item._id">
                <td class="tds">
                  {{ item.customer_number}}
                </td>
                <td class="tds" style="color: #f15bb5">
                  {{ item.customer_name }}
                </td>
                <td class="tds">
                  {{ item.customer_national_id_card_number }}
                </td>
                <td class="tds">
                  {{ item.customer_phone_number }}
                </td>
                <td class="tds">
                  {{ item.customer_whatsApp_number }}
                </td>
                <td class="tds">
                  {{ item.bosco_bouquet_title }}
                </td>
                <td class="tds">
                  {{ item.bosco_customer_role_title }}
                </td>
                <td class="tds">
                  {{ item.bosco_bouquet_number_of_posts }}
                </td>
                <td class="tds">
                  {{ item.customer_email }}
                </td>

                <td class="tds">
                  {{ item.customer_city }}
                </td>
                <td class="tds">
                  {{ item.customer_neighborhood }}
                </td>
                <td class="tds">
                  {{ item.customer_address }}
                </td>
                <td class="tds">
                  {{ item.customer_occupation }}
                </td>
                <td class="tds">
                  {{ item.customer_birthday }}
                </td>
                <td class="tds">
                  {{ item.bosco_bouquet_expiration_day }}
                </td>
                <td class="tds">
                  {{ item.customer_work_area }}
                </td>
                <td class="tds">
                  {{ item.customer_notes.slice(0, 20) }}
                </td>
                <td class="tds">
                  <router-link
                    :to="{
                      name: 'editUser',
                      params: { id: item._id },
                    }"
                  >
                    <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
                      <path
                        fill="#f15bb5"
                        d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z"
                      />
                    </svg>
                  </router-link>
                </td>
                <td class="tds" style="color: #5538c8">
                  <delete-items
                    v-on:want_to_delete_item="
                      deleteBoscoCustomer(item, item._id)
                    "
                  >
                  </delete-items>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import DeleteItems from "../deletes/DeleteItems";
import { mapActions, mapGetters } from "vuex";
const ROOT_URL = "/api";

export default {
  name: "BoscoUsers",
  components: {
    DeleteItems,
  },
  data: () => ({
    dialog: false,
    search: "",
    ////////////////
    bouquet_expireArr: [],
  }),
  mounted: function () {
    if (!this.isLoggedIn) {
      this.$router.push({ path: "/" });
    }
  },
  computed: {
    ...mapGetters([
      "allUsers",

      ////////////aparth
      "allVillas",

      ////Feach Number of posts by Customer///////
      "allAgriculturalFieldsPostsByCustomer",
      "allApartmentsForRentByCustomer",
      "allApartmentsForSaleByCustomer",
      "allBuildingsByCustomer",
      "allHousesByCustomer",
      "allLandsByCustomer",
      "allOfficesByCustomer",
      "allShopsByCustomer",
      "allStudentsHouseByCustomer",
      "allVillasByCustomer",
    ]),
    ////////////////////////////////////////////
        isLoggedIn() {
          return this.$store.getters.isLoggedIn;
        },
    ////////////////////////////////////////////////
    finished_bouquets: function (){
      this.allUsers.forEach((user) => {
        let bouquet_expiration_date = new Date(user.bosco_bouquet_expiration_day);
        let date_today = new Date();
        let difference = bouquet_expiration_date.getTime() - date_today.getTime();
        let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
        if(totalDays <= 0){
          this.bouquet_expireArr.push(user)
          this.dialog = true;
        }
      })
        return [
        ...new Map(
         this.bouquet_expireArr.map((item) => [item.customer_name, item])
        ).values(),
      ];
    },
    search_customers: function () {
      return this.allUsers.filter((item) => {
        return (
          item.customer_name.includes(this.search) ||
          item.customer_number.includes(this.search) ||
          item.customer_national_id_card_number.includes(this.search) ||
          item.customer_phone_number.includes(this.search) ||
          item.customer_whatsApp_number.includes(this.search) ||
          item.bosco_bouquet_title.includes(this.search) ||
          item.bosco_bouquet_number_of_posts.includes(this.search) ||
          item.customer_email.includes(this.search) ||
          item.customer_city.includes(this.search) ||
          item.customer_neighborhood.includes(this.search) ||
          item.customer_address.includes(this.search) ||
          item.customer_occupation.includes(this.search) ||
          item.customer_birthday.includes(this.search) ||
          item.bosco_bouquet_expiration_day.includes(this.search) ||
          item.customer_work_area.includes(this.search) ||
          item.customer_notes.includes(this.search)
        );
      });
    },
  },
  created() {
    this.fetchUsers();
    ////Feach Number of posts by Customer///////
    this.fetchAgriculturalFields();
    this.fetchApartmentsForRent();
    this.fetchApartmentsForSale();
    this.fetchBuildings();
    this.fetchHouses();
    this.fetchLands();
    this.fetchOffices();
    this.fetchShops();
    this.fetchStudentsHouse();
    this.fetchVillas();
  },
  methods: {
    ...mapActions([
      "fetchUsers",
      ////Feach Number of posts by Customer///////
      "fetchAgriculturalFields",
      "fetchApartmentsForRent",
      "fetchApartmentsForSale",
      "fetchBuildings",
      "fetchHouses",
      "fetchLands",
      "fetchOffices",
      "fetchShops",
      "fetchStudentsHouse",
      "fetchVillas",
    ]),
    ///////////////////////////////////////
    deleteBoscoCustomer(item, _id) {
      axios
        .delete(`${ROOT_URL}/users/delete/${_id}`)
        .then((res) => res.data.users);
      this.$router.push({ path: "/deleteMessage" });
    },
  },
};
</script>

<style scoped>
#employees {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.employeeTableTitle {
  color: #f15bb5;
  font-weight: 600;
}

.tHead {
  color: #fff !important;
  background: #9b5de5 !important;
}

.addEmployeeBtn {
  background: #f15bb5 !important;
  color: #fff;
}
.addEmployeeBtn:hover {
  color: #f15bb5;
  background: #fff !important;
  transition: color 0.8s;
}
.dialogCardTitle {
  background: #9b5de5;
  color: #fff;
}
.dialgCustomerName {
  color: #9b5de5;
}
.dialog_btn {
  color: #9b5de5;
  border: 1px solid #9b5de5;
}
.DialogCardText {
  border-bottom: 1px dotted #9b5de5;
}
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #employees {
  padding: 18px;
  margin-top: 15px;

}
  .addEmployeeBtn {
    font-size: 12px;
    font-weight: 700;
  }
  .employeeTableTitle {
    font-weight: 500;
  }
}
</style>
