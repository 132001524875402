import apiServices from "../../api/apiServices";
///////////////////////State 
const state = {
      houses: [],
      totalHousesByCustomer: [],
};
///////////////////////Getters
const getters = {
  allHouses: (state) => state.houses.reverse(),
  allHousesByCustomer: (state) => state.totalHousesByCustomer.reverse(),
};
//////////////////////Actions
const actions = {
  async fetchHouses({ commit, rootGetters }) {
    const response = await apiServices.fetch_houses();
           //filter houses posts by customer
           let total_houses_posts =
           response.data.houses.filter((items) => {
             const matchPost = items.bosco_customer_email.includes(
               rootGetters.get_bosco_customer.customer_email
             );
             return matchPost;
           });

           commit("setHousesByCustomer", total_houses_posts);
           commit("setHouses", response.data.houses);
  }
};

///////////////////MUTATIONS
const mutations = {
      setHouses: (state, houses) => {
      state.houses = houses;
  },
  setHousesByCustomer: (state, totalHousesByCustomer) => {
    state.totalHousesByCustomer =
    totalHousesByCustomer;
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
};
