<template>
  <div class="employees">
    <v-layout row wrap class="">
      <v-flex xs7 md10 class="">
        <p class="employeeTableTitle"> قائمة شقق للبيع</p>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs5 md2 class="">
        <router-link to="/createApartmentForSale">
          <v-btn class="addEmployeeBtn" elevation="0"
            >شقة للبيع
            <svg
              style="width: 24px; height: 24px; margin-right: 3px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#9b5de5"
                d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z"
              />
            </svg>
          </v-btn>
        </router-link>
      </v-flex>
    </v-layout>
    <!--Layout -->
    <v-layout row wrap class="">
      <v-flex xs12 md10 class="">
        <p>
          عدد إعلانات الشقق للبيع:
          {{ search_apartments_for_sale.length }}
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <v-toolbar elevation="0" class="customerTableToolBar">
          <v-text-field
            flat
            hide-details
            v-model="search"
            class="search"
            label="بحث"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
          >
          </v-text-field>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <!-- الجدول الاول -->
        <v-simple-table
          class="simpleTable-4 mainTable"
          fixed-header
          height="500px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="tHead">اسم المعلن</th>
                <th class="tHead">رقم الهاتف</th>
                <th class="tHead">رقم الواتساب</th>
                <th class="tHead">سعر الشقة</th>
                <th class="tHead">المدينة</th>
                <th class="tHead">الحي</th>
                <th class="tHead">العنوان</th>
                <th class="tHead">المساحة</th>
                <th class="tHead">الغرف</th>
                <th class="tHead">بلكونة</th>
                <th class="tHead">الطابق</th>
                <th class="tHead">التكييف</th>
                <th class="tHead">نوع التشطيب</th>
                <th class="tHead">تأمين</th>
                <th class="tHead">اسانسير</th>
                <th class="tHead">موقف سيارات</th>
                <th class="tHead">واي فاي</th>
                <th class="tHead"> مصدر طاقة إضافي</th>
                <th class="tHead">المطابخ</th>
                <th class="tHead">هــول</th>
                <th class="tHead">أثاثات</th>
                <th class="tHead">الكروكي</th>
                <th class="tHead">الحمامات</th>
                <th class="tHead">ملاحظات</th>
                <th class="tHead">صورة</th>
                <th class="tHead">تعديل</th>
                <th class="tHead">مسح</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in search_apartments_for_sale"
                :key="item._id"
              >
                <td class="tds" style="color: #f15bb5">
                  {{ item.bosco_logged_in_customer }}
                </td>
                <td class="tds">
                  {{ item.apartment_for_sale_owner_phone_number }}
                </td>
                <td class="tds">
                  {{ item.apartment_for_sale_whatsApp_number }}
                </td>
                <td class="tds">
                  {{ item.apartment_for_sale_price }}
                </td>
                <td class="tds">
                  {{ item.city_title }}
                </td>
                <td class="tds">
                  {{ item.apartment_for_sale_neighborhood }}
                </td>
                <td class="tds">
                  {{ item.apartment_for_sale_address }}
                </td>
                <td class="tds">
                  {{ item.apartment_for_sale_area }}
                </td>
                <td class="tds">
                  {{ item.room_title }}
                </td>
                <td class="tds">
                  {{ item.balcony_title }}
                </td>
                <td class="tds">
                  {{ item.floor_title }}
                </td>
                <td class="tds">
                  {{ item.air_condition_title }}
                </td>
                <td class="tds">
                  {{ item.finishing_type_title }}
                </td>
                <td class="tds">
                  {{ item.guard_title }}
                </td>
                <td class="tds">
                  {{ item.elevator_title }}
                </td>
                <td class="tds">
                  {{ item.parking_title }}
                </td>
                <td class="tds">
                  {{ item.general_title }}
                </td>
                <td class="tds">
                  {{ item.power_title }}
                </td>
                <td class="tds">
                  {{ item.kitchen_title }}
                </td>
                <td class="tds">
                  {{ item.hall_title }}
                </td>
                <td class="tds">
                  {{ item.furniture_title }}
                </td>
                <td class="tds">
                  {{ item.sketch_title }}
                </td>
                <td class="tds">
                  {{ item.bathroom_title }}
                </td>
                <td class="tds">
                  {{ item.apartment_for_sale_notes }}
                </td>

                <td class="tds">
                  <v-img :src="item.image" id="carouselImage"></v-img>
                </td>
                <td class="tds">
                  <router-link
                    :to="{
                      name: 'editApartmentForSale',
                      params: { id: item._id },
                    }"
                  >
                    <svg style="width: 25px; height: 25px" viewBox="0 0 24 24">
                      <path
                        fill="#f15bb5"
                        d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z"
                      />
                    </svg>
                  </router-link>
                </td>
                <td class="tds" style="color: #5538c8">
                  <delete-items
                    v-on:want_to_delete_item="
                      deleteApartmentsForSale(item, item._id)
                    "
                  >
                  </delete-items>
               </td> 
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import DeleteItems from "../deletes/DeleteItems";
import { mapActions, mapGetters } from "vuex";
const ROOT_URL = "/api";

export default {
  name: "AdminApartmentForSale",
  components: {
    DeleteItems,
  },
  data: () => ({
    dialog: false,
    search: "",
    ////////////////////////
  }),
  mounted: function () {
    if (!this.isLoggedIn) {
      this.$router.push({ path: "/" });
    }
  },
  computed: {
    ////////////////////////////////////////////
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    /////////////////////////////////////////////////
    search_apartments_for_sale: function () {
      return this.allApartmentsForSale.filter((item) => {
        return (
          item.bosco_logged_in_customer.includes(this.search) ||
          item.apartment_for_sale_owner_phone_number.includes(this.search) ||
          item.apartment_for_sale_whatsApp_number.includes(this.search) ||
          item.apartment_for_sale_price.includes(this.search) ||
          item.city_title.includes(this.search) ||
          item.apartment_for_sale_neighborhood.includes(this.search) ||
          item.apartment_for_sale_address.includes(this.search) ||
          item.apartment_for_sale_area.includes(this.search) ||
          item.room_title.includes(this.search) ||
          item.balcony_title.includes(this.search) ||
          item.floor_title.includes(this.search) ||
          item.air_condition_title.includes(this.search) ||
          item.finishing_type_title.includes(this.search) ||
          item.guard_title.includes(this.search) ||
          item.elevator_title.includes(this.search) ||
          item.parking_title.includes(this.search) ||
          item.general_title.includes(this.search) ||
          item.power_title.includes(this.search) ||
          item.kitchen_title.includes(this.search) ||
          item.hall_title.includes(this.search) ||
          item.furniture_title.includes(this.search) ||
          item.bosco_customer_email.includes(this.search) ||
          item.currency_type_title.includes(this.search) ||
          item.measurement_title.includes(this.search) ||
          item.sketch_title.includes(this.search) ||
          item.created_At.includes(this.search) ||
          item.bathroom_title.includes(this.search) ||
          item.apartment_for_sale_notes.includes(this.search)
        );
      });
    },
    ...mapGetters(["allApartmentsForSale"]),
  },
  created() {
    this.fetchApartmentsForSale();
  },
  methods: {
    ...mapActions(["fetchApartmentsForSale"]),
    ///////////////////////////////////////
    deleteApartmentsForSale(item, _id) {
      axios
        .delete(`${ROOT_URL}/apartments_for_sale/delete/${_id}`)
        .then((res) => res.data.apartments_for_sale);
      this.$router.push({ path: "/deleteMessage" });
    },
  },
};
</script>

<style scoped>
.employees {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.employeeTableTitle {
  color: #f15bb5;
  font-weight: 600;
}

.tHead {
  color: #fff !important;
  background: #9b5de5 !important;
}

.addEmployeeBtn {
  background: #f15bb5 !important;
  color: #fff;
}
.addEmployeeBtn:hover {
  color: #f15bb5;
  background: #fff !important;
  transition: color 0.8s;
}

#carouselImage {
  width: 40px;
  height: 30px;
}
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  .addEmployeeBtn {
    font-size: 10px;
    font-weight: 600;
  }
  .employeeTableTitle {
    font-weight: 500;
  }
}
</style>
