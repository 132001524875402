<template>
 
  <v-container id="culture">
    <v-layout row wrap>
      <v-flex xs12 md10 class="">
        <p class="employeeTableTitle">بوسكو للتسويق العقاري</p>
      </v-flex>
      <v-flex xs12 md10 class="">
        <p class="employeeTableTitle">اختار نوع الباقة</p>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md10 class="">
        <p class="howtoSuportYouTitle">
          بنقدم خدمة ترضيكم و تكون جذابة لي كل المتصفحين والمستخدمين وتناسب حجم
          النشاط بتاعكم. بوسكو بتقدم ليكم الباقات بتاعتا عشان تعلنوا عن العقار
          بصورة عصرية، مبتكرة وبتشبهنا.
        </p>
      </v-flex>
    </v-layout>
    <!-- Layout -->
    <v-layout row wrap class="">
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكونا</p>

            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 1 منشور</p>
              <p class="para">مدة الباقة يوم كامل 24 ساعة</p>
              <p class="para">سعر الباقة 2 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
                 <span style="color: #000; font-weight: 900;">
                 %50
                </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <router-link to="/boLogIn">
                <button class="advertisment">
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="#9b5de5"
                      d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
                    />
                  </svg>
                  عايز تعلن؟
                </button>
              </router-link>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>

      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو يومي</p>
            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 4 منشورات</p>
              <p class="para">مدة الباقة يوم كامل 24 ساعة</p>
              <p class="para">سعر الباقة 6 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
                 <span style="color: #000; font-weight: 900;">
                 %50
                </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <router-link to="/boLogIn">
                <button class="advertisment">
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="#9b5de5"
                      d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
                    />
                  </svg>
                  عايز تعلن؟
                </button>
              </router-link>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                 إتصل الان  
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Layout -->
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو إسبوعي</p>
            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 30 منشور</p>
              <p class="para">مدة الباقة اسبوع تمام</p>
              <p class="para">سعر الباقة 42 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
                 <span style="color: #000; font-weight: 900;">
                 %50
                </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <router-link to="/boLogIn">
                <button class="advertisment">
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="#9b5de5"
                      d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
                    />
                  </svg>
                  عايز تعلن؟
                </button>
              </router-link>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Layout -->
    </v-layout>
    <!-- Layout -->
    <v-layout row wrap class="secLayout">
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو إسبوعين</p>

            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 65 منشور</p>
              <p class="para">مدة الباقة إسبوعين قمر دورين</p>
              <p class="para">سعر الباقة 84 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
                 <span style="color: #000; font-weight: 900;">
                 %50
                </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <router-link to="/boLogIn">
                <button class="advertisment">
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="#9b5de5"
                      d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
                    />
                  </svg>
                  عايز تعلن؟
                </button>
              </router-link>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو شهري</p>

            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 130 منشور</p>
              <p class="para">مدة الباقة شهر كامل</p>
              <p class="para">سعر الباقة 155 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
              <span style="color: #000; font-weight: 900;">
                 %50
              </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <router-link to="/boLogIn">
                <button class="advertisment">
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="#9b5de5"
                      d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
                    />
                  </svg>
                  عايز تعلن؟
                </button>
              </router-link>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Layout -->
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو غير محدود</p>

            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">اتواصل معانا لمن تلقي فرصة</p>
            </div>
            <div>
              <router-link to="/boLogIn">
                <button class="advertisment">
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="#9b5de5"
                      d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
                    />
                  </svg>
                  عايز تعلن؟
                </button>
              </router-link>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249016099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "BoBouquets",
  data() {
    return {
      phone: "+249916099219"
    };
  },
  components: {},
  computed: {},
  methods: {},
  created() {},
};
</script>

<style scoped>
#culture {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
}
.employeeTableTitle {
  font-size: 30px;
  color: #9b5de5;
  font-weight: 500;
}
.howtoSuportYouTitle {
  font-size: 20px;
  text-align: center;
  word-spacing: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.addOne {
  margin-left: 10px;
  background: #e7e6f7;
  color: #000;
  border: 2px dotted #000;
  width: 425px;
  height: 650px;
}
.content{
  margin-top: 20px;
}
.advertisment {
  font-size: 16px;
  font-weight: 500;
  border-radius: 2px;
  padding: 7px;
  background: linear-gradient(to top, #9b5de5, #fee440 );
  color: #fff;
}
.boscoAdvertismentTitle {
  padding-top: 10px;
  font-size: 30px;
  font-weight: 500;
}
.boscoAdvertismentsubTitle{
  font-size: 25px;
  font-weight: 500;
}
.phone {
  font-size: 18px;
  padding-top: 15px;
}
.svgItem {
  opacity: 0.6;
}
.bosco {
  color: #9b5de5;
  font-size: 50px;
  font-weight: 900;
}
.para {
  font-size: 18px;
  font-weight: 500;
}
.paraDiscount{
  color: #fff;
  background: linear-gradient(to top, #9b5de5, #fee440 );
  border-radius: 2px;
  padding: 5px;
  font-size: 18px;
  font-weight: 500;
}
.addFlex {
  margin-top: 10px;
}
.firstphoneContactSpan {
  font-size: 18px;
  padding-left: 20px;
}

/* Media Queries */

/* most mobiles has width about 400px */
@media screen and (max-width: 500px) {
  #culture {
    padding: 10px;
    margin-top: 15px;
  }
  .employeeTableTitle {
  font-size: 25px;
}
  .addOne {
  width: 360px;
  height: 700px;
}
.para {
  font-size: 18px;
}
.paraDiscount{
  font-size: 19px;
}
.boscoAdvertismentsubTitle{
  font-size: 22px;
}
.boscoAdvertismentTitle {
  font-size: 22px;
}
.advertisment {
  font-size: 20px;
  padding: 5px;

}
.firstphoneContactSpan {
  font-size: 20px;
}
.phone {
  font-size: 20px;
}
}
@media screen and (max-width: 800px) {
  /* any device has 800px and less  "Ipad"*/
}
</style>
