import apiServices from "../../api/apiServices";
///////////////////////State
const state = {
  agriculturalFields: [],
  totalAgriculturalsFieldsByCustomer: [],
};
///////////////////////Getters
const getters = {
  allAgriculturalFields: (state) => state.agriculturalFields.reverse(),
  allAgriculturalFieldsPostsByCustomer: (state) =>
    state.totalAgriculturalsFieldsByCustomer,
};
//////////////////////Actions
const actions = {
  async fetchAgriculturalFields({ commit, rootGetters }) {
    const response = await apiServices.fetch_agricultural_fields();
    //filter agriculturals fields posts by customer
    let total_customer_agriculturals_fields_posts =
      response.data.agriculturals.filter((items) => {
        const matchPost = items.bosco_customer_email.includes(
          rootGetters.get_bosco_customer.customer_email
        );
        return matchPost;
      });

  

    commit("setAgriculturalFields", response.data.agriculturals);
    commit( "setCustomerAgriculturalsFieldsPosts",total_customer_agriculturals_fields_posts);
  },
};

///////////////////MUTATIONS
const mutations = {
  setAgriculturalFields: (state, agriculturalFields) => {
    state.agriculturalFields = agriculturalFields;
  },
  setCustomerAgriculturalsFieldsPosts: (state, total_customer_agriculturals_fields_posts) => {
    state.totalAgriculturalsFieldsByCustomer = total_customer_agriculturals_fields_posts;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
