<template>
  <v-container id="culture">
    <v-layout row wrap class="">
      <v-spacer></v-spacer>
      <v-flex xs12 md12 class="main_frist_area3">
        <v-img :src="boscoAds1"  class="boscoAdsImage"></v-img>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <!-- layout 1 -->
    <v-layout row wrap class="frsitLayout">
      <v-flex xs12 md12 class="main_frist_area3">
        <h3 class="homeTitle">شقق للإيجار</h3>
      </v-flex>
    </v-layout>
    <!-- layout 2 -->
    <v-layout row wrap>
      <v-flex
        xs12
        md3
        class="pl-2 pr-2 text-xs-center mb-4 mt-2"
        v-for="apartmentForRentInfo in allApartmentsForRent.slice(0, 8)"
        :key="apartmentForRentInfo._id"
      >
        <router-link
          v-bind:to="'/apartmentForRentInfo/' + apartmentForRentInfo._id"
        >
          <v-hover v-slot:default="{ hover }">
            <div :elevation="hover ? 16 : 2">
              <v-card
                class="mx-auto postCard"
                max-width="1000"
                :elevation="hover ? 16 : 2"
              >
                <v-img 
                  :src="`data:image/png;base64, ${apartmentForRentInfo.image}`"

                  height="200"
                  class=""
                ></v-img>
                <v-card-text class="text--primary">
                  <h3 class="mainTitle">
                    {{ apartmentForRentInfo.city_title.slice(0, 20) }} -
                    {{
                      apartmentForRentInfo.apartment_for_rent_neighborhood.slice(
                        0,
                        15
                      )
                    }}
                  </h3>
                  <h4 class="mb-4">
                    <span style="opacity: 0.7">
                      {{ apartmentForRentInfo.apartment_for_rent_address }}
                    </span>
                  </h4>
                  <h4>
                    <span style="font-size: 20px;
                        margin-right: 10px;
                        color: #f15bb5;
                        font-weight: 900;
                      "
                    >
                      {{ apartmentForRentInfo.apartment_for_rent_price }}
                      {{ apartmentForRentInfo.currency_type_title }}
                    </span>
                  </h4>
                  <h5 class="mt-4">
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ apartmentForRentInfo.room_title }} غرف
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M2 7H22V10H20L21 19H18.5L17.94 14H6.06L5.5 19H3L4 10H2V7M17.5 10H6.5L6.29 12H17.71L17.5 10Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ apartmentForRentInfo.furniture_title }}
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M18 10V7C18 5.34 16.66 4 15 4S12 5.34 12 7H14C14 6.45 14.45 6 15 6S16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18M13 18H11V12H13V18Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ apartmentForRentInfo.kitchen_title }} مطبخ
                    </span>
                  </h5>
                  <div class="date">
                    <span class="svgItem">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
                        />
                      </svg>
                    </span>
                    {{ apartmentForRentInfo.created_At }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </router-link>
      </v-flex>
    </v-layout>
    <!-- layout 3 -->
    <v-layout row wrap class="apartmenyForSale">
      <v-flex xs5 md3 class="main_frist_area3">
        <h3 class="homeTitle">شقق للبيع</h3>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs5 md4 class="main_frist_area3">
        <router-link to="/boBouquets">
          <button class="advertisment">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="#9b5de5"
                d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
              />
            </svg>
            عايز تعلن؟
          </button>
        </router-link>
      </v-flex>
    </v-layout>
    <!-- layout 4 -->
    <v-layout row wrap>
      <v-flex
        xs12
        md3
        class="pl-2 pr-2 text-xs-center mb-4 mt-2"
        v-for="apartmentForSaleInfo in allApartmentsForSale.slice(0, 4)"
        :key="apartmentForSaleInfo._id"
      >
        <router-link
          v-bind:to="'/apartmentForSaleInfo/' + apartmentForSaleInfo._id"
        >
          <v-hover v-slot:default="{ hover }">
            <div :elevation="hover ? 16 : 2">
              <v-card
                class="mx-auto postCard"
                max-width="1000"
                :elevation="hover ? 16 : 2"
              >
                <v-img
                  :src="`data:image/png;base64, ${apartmentForSaleInfo.image}`"
                  height="200"
                  class=""
                ></v-img>
                <v-card-text class="text--primary">
                  <h3 class="mainTitle">
                    {{ apartmentForSaleInfo.city_title.slice(0, 20) }} -
                    {{
                      apartmentForSaleInfo.apartment_for_sale_neighborhood.slice(
                        0,
                        15
                      )
                    }}
                  </h3>
                  <h4 class="mb-4">
                    <span style="opacity: 0.7">
                      {{ apartmentForSaleInfo.apartment_for_sale_address }}
                    </span>
                  </h4>
                  <h4>
                    <span  style="font-size: 20px;
                        margin-right: 10px;
                        color: #f15bb5;
                        font-weight: 900;
                      "
                    >
                      {{ apartmentForSaleInfo.apartment_for_sale_price }}
                      {{ apartmentForSaleInfo.currency_type_title }}
                    </span>
                  </h4>
                  <h5 class="mt-4">
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 20px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ apartmentForSaleInfo.room_title }} غرف
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M2 7H22V10H20L21 19H18.5L17.94 14H6.06L5.5 19H3L4 10H2V7M17.5 10H6.5L6.29 12H17.71L17.5 10Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ apartmentForSaleInfo.furniture_title }}
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M18 10V7C18 5.34 16.66 4 15 4S12 5.34 12 7H14C14 6.45 14.45 6 15 6S16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18M13 18H11V12H13V18Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ apartmentForSaleInfo.kitchen_title }} مطبخ
                    </span>
                  </h5>
                  <div class="date">
                    <span class="svgItem">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
                        />
                      </svg>
                    </span>
                    {{ apartmentForSaleInfo.created_At }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </router-link>
      </v-flex>
    </v-layout>
    <!-- layout 7 -->
    <v-layout row wrap class="vipAdsLayout">
      <v-flex xs12 md12 class="main_frist_area3">
        <router-link to="/boBouquets">
          <h3 class="homeTitle vipadsTitle">عايز إعلان VIP؟</h3>
        </router-link>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="adsLayout">
      <v-flex xs5 md3 class="main_frist_area3 adsFlex">
        <router-link v-bind:to="'/wesco/'">
          <v-card elevation="0" class="mx-auto adsCard" max-width="434">
            <v-avatar size="100">
              <v-img :src="wescoLogo" class="cudtomerImage"></v-img>
            </v-avatar>
            <p class="ads_name">WESCO</p>
          </v-card>
        </router-link>
      </v-flex>
      <v-flex xs5 md3 class="main_frist_area3 adsFlex">
        <router-link v-bind:to="'/ghalia/'">
          <v-card elevation="0" class="mx-auto adsCard" max-width="434">
            <v-avatar size="100" class="adsAvatar">
              <p class="adsTitle">غالية للعقارات</p>
              <!-- <v-img
              :src="galiaLogo"
              class="cudtomerImage"
            ></v-img> -->
            </v-avatar>
            <p class="ads_name">غالية للعقارات</p>
          </v-card>
        </router-link>
      </v-flex>
      <v-flex xs5 md3 class="main_frist_area3 adsFlex">
        <v-card elevation="0" class="mx-auto adsCard" max-width="434">
          <v-avatar size="100" class="adsAvatar">
            <p class="adsTitle">TACO</p>
            <!-- <v-img
              :src="galiaLogo"
              class="cudtomerImage"
            ></v-img> -->
          </v-avatar>
          <p class="ads_name">تاكو الهندسة</p>
        </v-card>
      </v-flex>
      <v-flex xs5 md3 class="main_frist_area3 adsFlex">
        <v-card elevation="0" class="mx-auto adsCard" max-width="434">
          <v-avatar size="100" class="adsAvatar">
            <v-img :src="wadbaLogo" class="cudtomerImage"></v-img>
          </v-avatar>
          <p class="ads_name">ودبا ليموزين</p>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- layout 7 -->
    <v-layout row wrap class="frsitLayout">
      <v-flex xs12 md12 class="main_frist_area3">
        <h3 class="homeTitle">أراضي</h3>
      </v-flex>
    </v-layout>
    <!-- layout 5 -->
    <v-layout row wrap>
      <v-flex
        xs12
        md3
        class="pl-2 pr-2 text-xs-center mb-4 mt-2"
        v-for="landInfo in allLands"
        :key="landInfo._id"
      >
        <router-link v-bind:to="'/landInfo/' + landInfo._id">
          <v-hover v-slot:default="{ hover }">
            <div :elevation="hover ? 16 : 2">
              <v-card
                class="mx-auto postCard"
                max-width="1000"
                :elevation="hover ? 16 : 2"
              >
                <v-img 
                :src="`data:image/png;base64, ${landInfo.image}`"
                height="200" class=""></v-img>
                <v-card-text class="text--primary">
                  <h3 class="mainTitle">
                    {{ landInfo.city_title.slice(0, 20) }} -
                    {{ landInfo.land_neighborhood.slice(0, 15) }}
                  </h3>
                  <h4 class="mb-4">
                    <span style="opacity: 0.7">
                      {{ landInfo.land_address }}
                    </span>
                  </h4>
                  <h4>
                    <span
                      style="
                        font-size: 20px;
                        margin-right: 10px;
                        color: #f15bb5;
                        font-weight: 900;
                      "
                    >
                      {{ landInfo.land_price }}
                      {{ landInfo.currency_type_title }}
                    </span>
                  </h4>
                  <h5 class="mt-4">
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M18 10V7C18 5.34 16.66 4 15 4S12 5.34 12 7H14C14 6.45 14.45 6 15 6S16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18M13 18H11V12H13V18Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      المساحة
                      {{ landInfo.land_area }}
                      {{ landInfo.measurement_title }}
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M12,3L2,12H5V20H19V12H22M9,18H7V12H9M13,18H11V10H13M17,18H15V14H17"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ landInfo.advertisment_title }}
                    </span>
                  </h5>
                  <div class="date">
                    <span class="svgItem">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
                        />
                      </svg>
                    </span>
                    {{ landInfo.created_At }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </router-link>
      </v-flex>
    </v-layout>
    <!-- layout 5 -->
    <v-layout row wrap class="frsitLayout">
      <v-flex xs5 md3 class="main_frist_area3">
        <h3 class="homeTitle">بيوت</h3>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs5 md4 class="main_frist_area3">
        <router-link to="/boBouquets">
          <button class="advertisment">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="#9b5de5"
                d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
              />
            </svg>
            عايز تعلن؟
          </button>
        </router-link>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex
        xs12
        md3
        class="pl-2 pr-2 text-xs-center mb-4 mt-2"
        v-for="houseInfo in allHouses.slice(0, 4)"
        :key="houseInfo._id"
      >
        <router-link v-bind:to="'/houseInfo/' + houseInfo._id">
          <v-hover v-slot:default="{ hover }">
            <div :elevation="hover ? 16 : 2">
              <v-card
                class="mx-auto postCard"
                max-width="1000"
                :elevation="hover ? 16 : 2"
              >
                <v-img :src="`data:image/png;base64, ${houseInfo.image}`"
                height="200" class=""></v-img>
                <v-card-text class="text--primary">
                  <h3 class="mainTitle">
                    {{ houseInfo.city_title.slice(0, 20) }} -
                    {{ houseInfo.house_neighborhood.slice(0, 15) }}
                  </h3>
                  <h4 class="mb-4">
                    <span style="opacity: 0.7">
                      {{ houseInfo.house_address }}
                    </span>
                  </h4>
                  <h4>
                    <span
                      style=" font-size: 20px;
                        margin-right: 10px;
                        color: #f15bb5;
                        font-weight: 900;
                      "
                    >
                      {{ houseInfo.house_price }}
                      {{ houseInfo.currency_type_title }}
                    </span>
                  </h4>
                  <h5 class="mt-4">
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 20px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ houseInfo.room_title }} غرف
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M18 10V7C18 5.34 16.66 4 15 4S12 5.34 12 7H14C14 6.45 14.45 6 15 6S16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18M13 18H11V12H13V18Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ houseInfo.kitchen_title }} مطبخ
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M12,3L2,12H5V20H19V12H22M9,18H7V12H9M13,18H11V10H13M17,18H15V14H17"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ houseInfo.advertisment_title }}
                    </span>
                  </h5>
                  <div class="date">
                    <span class="svgItem">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
                        />
                      </svg>
                    </span>
                    {{ houseInfo.created_At }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </router-link>
      </v-flex>
    </v-layout>
    <!--layout 7-->
    <v-layout row wrap class="frsitLayout">
      <v-flex xs12 md12 class="main_frist_area3">
        <h3 class="homeTitle">فلل</h3>
      </v-flex>
    </v-layout>
    <!-- layout 5 -->
    <v-layout row wrap>
      <v-flex
        xs12
        md3
        class="pl-2 pr-2 text-xs-center mb-4 mt-2"
        v-for="villaInfo in allVillas.slice(0, 4)"
        :key="villaInfo._id"
      >
        <router-link v-bind:to="'/villaInfo/' + villaInfo._id">
          <v-hover v-slot:default="{ hover }">
            <div :elevation="hover ? 16 : 2">
              <v-card
                class="mx-auto postCard"
                max-width="1000"
                :elevation="hover ? 16 : 2"
              >
                <v-img  :src="`data:image/png;base64, ${villaInfo.image}`" height="200" class=""></v-img>
                <v-card-text class="text--primary">
                  <h3 class="mainTitle">
                    {{ villaInfo.city_title.slice(0, 20) }} -
                    {{ villaInfo.villa_neighborhood.slice(0, 15) }}
                  </h3>
                  <h4 class="mb-4">
                    <span style="opacity: 0.7">
                      {{ villaInfo.villa_address }}
                    </span>
                  </h4>
                  <h4>
                    <span
                      style="font-size: 20px;
                        margin-right: 10px;
                        color: #f15bb5;
                        font-weight: 900;
                      "
                    >
                      {{ villaInfo.villa_price }}
                      {{ villaInfo.currency_type_title }}
                    </span>
                  </h4>
                  <h5 class="mt-4">
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 20px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ villaInfo.room_title }} غرف
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M2 7H22V10H20L21 19H18.5L17.94 14H6.06L5.5 19H3L4 10H2V7M17.5 10H6.5L6.29 12H17.71L17.5 10Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ villaInfo.furniture_title }}
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M12,3L2,12H5V20H19V12H22M9,18H7V12H9M13,18H11V10H13M17,18H15V14H17"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ villaInfo.advertisment_title }}
                    </span>
                  </h5>
                  <div class="date">
                    <span class="svgItem">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
                        />
                      </svg>
                    </span>
                    {{ villaInfo.created_At }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </router-link>
      </v-flex>
    </v-layout>

    <!-- layout 7 -->
    <v-layout row wrap class="frsitLayout">
      <v-flex xs12 md12 class="main_frist_area3">
        <h3 class="homeTitle">مكاتب</h3>
      </v-flex>
    </v-layout>
    <!-- layout 5 -->
    <v-layout row wrap>
      <v-flex
        xs12
        md3
        class="pl-2 pr-2 text-xs-center mb-4 mt-2"
        v-for="officeInfo in allOffices.slice(0, 4)"
        :key="officeInfo._id"
      >
        <router-link v-bind:to="'/officeInfo/' + officeInfo._id">
          <v-hover v-slot:default="{ hover }">
            <div :elevation="hover ? 16 : 2">
              <v-card
                class="mx-auto postCard"
                max-width="1000"
                :elevation="hover ? 16 : 2"
              >
                <v-img :src="`data:image/png;base64, ${officeInfo.image}`" height="200" class=""></v-img>
                <v-card-text class="text--primary">
                  <h3 class="mainTitle">
                    {{ officeInfo.city_title.slice(0, 20) }} -
                    {{ officeInfo.office_address.slice(0, 15) }}
                  </h3>
                  <!-- Good fro desigo -->
                  <h4 class="mb-4">
                    <!-- <span style="opacity: 0.7">
                      {{ officeInfo.building_address }}
                    </span> -->
                  </h4>
                  <h4>
                    <span
                      style="font-size: 20px;
                        margin-right: 10px;
                        color: #f15bb5;
                        font-weight: 900;
                      "
                    >
                      {{ officeInfo.office_price }}
                      {{ officeInfo.currency_type_title }}
                    </span>
                  </h4>
                  <h5 class="mt-4">
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 20px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      الطابق {{ officeInfo.floor_title }}
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M18 10V7C18 5.34 16.66 4 15 4S12 5.34 12 7H14C14 6.45 14.45 6 15 6S16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18M13 18H11V12H13V18Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      المساحة {{ officeInfo.office_area }}
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M12,3L2,12H5V20H19V12H22M9,18H7V12H9M13,18H11V10H13M17,18H15V14H17"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ officeInfo.advertisment_title }}
                    </span>
                  </h5>
                  <div class="date">
                    <span class="svgItem">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
                        />
                      </svg>
                    </span>
                    {{ officeInfo.created_At }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </router-link>
      </v-flex>
    </v-layout>
    <!-- layout 7 -->
    <v-layout row wrap class="frsitLayout">
      <v-flex xs5 md3 class="main_frist_area3">
        <h3 class="homeTitle">مزارع</h3>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs5 md4 class="main_frist_area3">
        <router-link to="/boBouquets">
          <button class="advertisment">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="#9b5de5"
                d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
              />
            </svg>
            عايز تعلن؟
          </button>
        </router-link>
      </v-flex>
    </v-layout>
    <!-- layout 5 -->
    <v-layout row wrap class="lastLayout">
      <v-flex
        xs12
        md3
        class="pl-2 pr-2 text-xs-center mt-2"
        v-for="agriculturalFieldInfo in allAgriculturalFields.slice(0, 4)"
        :key="agriculturalFieldInfo._id"
      >
        <router-link
          v-bind:to="'/agriculturalFieldInfo/' + agriculturalFieldInfo._id"
        >
          <v-hover v-slot:default="{ hover }">
            <div :elevation="hover ? 16 : 2">
              <v-card
                class="mx-auto postCard"
                max-width="1000"
                :elevation="hover ? 16 : 2"
              >
                <v-img
                  :src="`data:image/png;base64, ${agriculturalFieldInfo.image}`"
                  height="200"
                  class=""
                ></v-img>
                <v-card-text class="text--primary">
                  <h3 class="mainTitle">
                    {{ agriculturalFieldInfo.city_title.slice(0, 20) }} -
                    {{ agriculturalFieldInfo.agricultural_address.slice(0, 15) }}
                  </h3>
                  <!-- Good fro desigo -->
                  <h4 class="mb-4">
                    <!-- <span style="opacity: 0.7">
                      {{ agriculturalFieldInfo.shop_address }}
                    </span> -->
                  </h4>
                  <h4>
                    <span
                      style="font-size: 20px;
                        margin-right: 10px;
                        color: #f15bb5;
                        font-weight: 900;
                      "
                    >
                      {{ agriculturalFieldInfo.agricultural_price }}
                      {{ agriculturalFieldInfo.currency_type_title }}
                    </span>
                  </h4>
                  <h5 class="mt-4">
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M18 10V7C18 5.34 16.66 4 15 4S12 5.34 12 7H14C14 6.45 14.45 6 15 6S16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18M13 18H11V12H13V18Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      المساحة {{ agriculturalFieldInfo.agricultural_area }}
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M12,3L2,12H5V20H19V12H22M9,18H7V12H9M13,18H11V10H13M17,18H15V14H17"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ agriculturalFieldInfo.advertisment_title }}
                    </span>
                  </h5>
                  <div class="date">
                    <span class="svgItem">
                      <svg
                        style="width: 17px; height: 17px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
                        />
                      </svg>
                    </span>
                    {{ agriculturalFieldInfo.created_At }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </router-link>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const wesco = require("../assets/images/wesco.png");
const galia = require("../assets/images/galia.jpg");
const wadba = require("../assets/images/wadba.jpg");

const ads1 = require("../assets/ads/boscoAds01.png");

export default {
  name: "BoHome",
  components: {},
  data: () => ({
    wescoLogo: wesco,
    galiaLogo: galia,
    wadbaLogo: wadba,
    boscoAds1: ads1,
  }),
  computed: {
    ...mapGetters([
      "allApartmentsForRent",
      "allApartmentsForSale",
      "allHouses",
      "allVillas",
      "allOffices",
      "allAgriculturalFields",
      "allLands",
    ]),
  },
  created() {
    this.fetchApartmentsForRent();
    this.fetchApartmentsForSale();
    this.fetchHouses();
    this.fetchVillas();
    this.fetchOffices();
    this.fetchAgriculturalFields();
    this.fetchLands();
  },
  methods: {
    ...mapActions([
      "fetchApartmentsForRent",
      "fetchApartmentsForSale",
      "fetchHouses",
      "fetchVillas",
      "fetchOffices",
      "fetchAgriculturalFields",
      "fetchLands",
    ]),
  },
};
</script>
<style scoped>
#culture {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.boscoAdsImage {
  width: 100%;
}

.secondLayout {
  margin-bottom: 20px;
}
.postCard {
  height: 400;
}

.main_frist_area {
  margin-top: 15px;
  margin-left: 2px;
}
.apartmenyForSale {
  margin-bottom: 20px;
}
.lastLayout {
  margin-bottom: 140px;
}
.main_frist_area3 {
  color: #9b5de5;
  padding: 5px;
}

.advertisment {
  padding: 7px;
  background: #fee440 !important;
  color: #9b5de5;
  border: 1px solid #9b5de5;
}
.advertisment:hover {
  background: #fff !important;
  transition: color 0.8s;
}
.date {
  font-weight: 600;
  padding: 5px;
  opacity: 0.4;
}
.vipAdsLayout {
  margin-top: 30px;
}
.adsCard {
  margin-top: 30px;
  text-align: center;
  color: #9b5de5;
}
.ads_name {
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
  margin-top: 5px;
  background: #fee440;
  border: 2px solid #9b5de5;
  border-radius: 50%;
}
.adsAvatar {
  background: #000;
  color: #fee440;
}
.adsTitle {
  font-size: 16px;
  font-weight: 600;
}
/* Media Queries */
/* most mobiles has width about 400px */
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #culture {
    padding: 8px;
    margin-top: 15px;
  }

  .homeTitle {
    margin-right: 5px;
  }
  .vipadsTitle {
    background: #000;
    font-size: 25px;
    padding: 10px;
    color: #9b5de5 !important;
  }
  .adsLayout {
    margin: 0 auto;
  }
  .adsFlex {
    margin: 0 auto;
  }
  .ads_name {
    font-size: 15px;
    padding: 4px;
  }
}
@media screen and (max-width: 800px) {
  /* any device has 800px and less  "Ipad"*/
  .postCard {
    height: 430;
  }
}
</style>
