import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      bosco_customer_roles: [],
};
/////////////// Getters
const getters = {
  allCustomerRolesRoles: (state) => state.bosco_customer_roles
};
/////////////// Actions
const actions = {
  async fetchBoscoCustomerRoles({ commit }) {
    const response = await apiServices.fetch_bosco_customer_roles();
    commit("setBoscoCustomerRoles", response.data.roles);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setBoscoCustomerRoles: (state, roles) => {
      state.bosco_customer_roles = roles;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
