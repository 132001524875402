<template>
  <v-container id="culture">
    <v-layout row wrap>
      <v-flex xs12 md10 class="">
        <h3 class="employeeTableTitle">أحدث عروض الشقق للإيجار</h3>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <v-toolbar elevation="0" class="customerTableToolBar">
          <v-text-field
            flat
            hide-details
            v-model="search"
            class="search"
            label="فتش بالمدينة،الحي،السعر،ايجار،بيع...الخ"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
          >
          </v-text-field>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="lastCard">
      <v-flex
        xs12
        md3
        class="pl-2 pr-2 text-xs-center  mt-2"
        v-for="apartmentForRentInfo in search_apartments_for_rent"
        :key="apartmentForRentInfo._id"
      >
        <router-link
          v-bind:to="'/apartmentForRentInfo/' + apartmentForRentInfo._id"
        >
          <v-hover v-slot:default="{ hover }">
            <div :elevation="hover ? 16 : 2">
              <v-card
                class="mx-auto postCard"
                max-width="1000"
                :elevation="hover ? 16 : 2"
              >
                <v-img
                :src="`data:image/png;base64, ${apartmentForRentInfo.image}`"
                  height="200"
                  class=""
                ></v-img>
                <v-card-text class="text--primary">
                  <h3 class="mainTitle">
                    {{ apartmentForRentInfo.city_title.slice(0, 20) }} -
                    {{ apartmentForRentInfo.apartment_for_rent_neighborhood.slice(0, 15) }}
                  </h3>
                  <h4 class="mb-4">
                    <span style="opacity: 0.7">
                      {{ apartmentForRentInfo.apartment_for_rent_address }}
                    </span>
                  </h4>
                  <h4>
                    <span style=" font-size: 20px; margin-right: 10px;
                        color: #f15bb5;
                        font-weight: 900;">

                      {{ apartmentForRentInfo.apartment_for_rent_price}}  
                      {{apartmentForRentInfo.currency_type_title}}

                    </span>
                  </h4>
                  <h5 class="mt-4">
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 20px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ apartmentForRentInfo.room_title }} غرف
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M2 7H22V10H20L21 19H18.5L17.94 14H6.06L5.5 19H3L4 10H2V7M17.5 10H6.5L6.29 12H17.71L17.5 10Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ apartmentForRentInfo.furniture_title }}
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M18 10V7C18 5.34 16.66 4 15 4S12 5.34 12 7H14C14 6.45 14.45 6 15 6S16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18M13 18H11V12H13V18Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ apartmentForRentInfo.kitchen_title }} مطبخ
                    </span>
                  </h5>
                  <h5 class="date">
                    <span class="svgItem">
                      <svg
                        style="width: 18px; height: 18px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
                        />
                      </svg>
                    </span>
                    {{ apartmentForRentInfo.created_At }}
                  </h5>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </router-link>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BoApartmentForRent",
  data() {
    return {
      search: "",
    };
  },
  components: {},
  computed: {
    search_apartments_for_rent: function () {
      return this.allApartmentsForRent.filter((item) => {
        return (
          item.bosco_logged_in_customer.includes(this.search) ||
          item.apartment_for_rent_owner_phone_number.includes(this.search) ||
          item.apartment_for_rent_whatsApp_number.includes(this.search) ||
          item.apartment_for_rent_price.includes(this.search) ||
          item.city_title.includes(this.search) ||
          ///item.bosco_customer_email.includes(this.search) ||
          item.measurement_title.includes(this.search) ||
          item.apartment_for_rent_neighborhood.includes(this.search) ||
          item.apartment_for_rent_address.includes(this.search) ||
          item.apartment_for_rent_area.includes(this.search) ||
          item.balcony_title.includes(this.search) ||
          item.floor_title.includes(this.search) ||
          item.air_condition_title.includes(this.search) ||
          item.guard_title.includes(this.search) ||
          item.elevator_title.includes(this.search) ||
          item.parking_title.includes(this.search) ||
          item.kitchen_title.includes(this.search) ||
          item.hall_title.includes(this.search) ||
          item.furniture_title.includes(this.search) ||
          item.bathroom_title.includes(this.search) ||
          item.currency_type_title.includes(this.search) ||
          item.advanced_payment_title.includes(this.search) ||
          item.general_title.includes(this.search) ||
          item.power_title.includes(this.search) ||
          item.finishing_type_title.includes(this.search) ||
          item.room_title.includes(this.search) ||
          item.created_At.includes(this.search) ||
          item.apartment_for_rent_notes.includes(this.search)
        );
      });
    },
    ////////////////////////////////////////
    ...mapGetters(["allApartmentsForRent"]),
  },
  methods: {
    ...mapActions(["fetchApartmentsForRent"]),
  },
  created() {
    this.fetchApartmentsForRent();
  },
};
</script>

<style scoped>
#culture {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.mainTitle {
  color: #000;
}
.postCard{
  height: 400;
}
.cltureToolBar {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.7;
}
.employeeTableTitle {
  color: #f15bb5;
  font-weight: 600;
}
.date {
  font-size: 15px;
  font-weight: 500;
  opacity: 0.5;
}
.lastCard{
    margin-bottom: 140px;
  }
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #culture {
  padding: 8px;
  margin-top: 15px;
}
.employeeTableTitle {
  margin-right: 8px;
  padding-top: 10px;
}
}
@media screen and (max-width: 800px) {
  /* any device has 800px and less  "Ipad"*/
  .postCard{
  height: 430;
}
}
</style>
