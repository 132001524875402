<template>
  <div class="createReportDiv">
    <!-- Layout 1 -->
    <v-layout row wrap class="" mt-3>
         <v-spacer></v-spacer>
     <v-flex v-if="progressBar" xs12 md4 class="">
       <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
         ></v-progress-circular>
       </v-flex>
        <v-spacer></v-spacer>
    </v-layout>                                  
    <!-- Layout 2 -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title class="text-h5 grey lighten-2">
              بوسكو للتسويق العقاري 
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2"> تم النشر </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/boSettings">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Layout 3 -->
    <v-layout row wrap class="">
      <v-flex xs12 md6 class="">
        <h3 class="addReportTitle">انشاء عدد الحمامات :</h3>
      </v-flex>
    </v-layout>
    <!-- Layout 4 -->
    <v-layout row wrap class="">
      <v-flex xs12 md3 class="">
        <v-text-field
          style="margin-right: 15px"
          label="نوع الحمام "
          outlined
          v-model="bathroom_title"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
    <!-- Layout 6 -->
    <v-layout row wrap class="">
      <v-flex xs12 md4 class="">
        <v-btn @click="createBathroom" class="createReportBtn" elevation="0">
          نشر
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
const ROOT_URL = "/api";

export default {
  name: "createBathroom",
  data: () => ({
    progressBar: false,
    dialog: false,
    //////////////////
    bathroom_title: "",
  }),
  computed: {
        isLoggedIn() {
          return this.$store.getters.isLoggedIn;
        },
  },
    mounted: function () {
      if (!this.isLoggedIn) {
        this.$router.push({ path: "/" });
      }
    },
  methods: {
    createBathroom() {
      const bathroom = {
            bathroom_title: this.bathroom_title,
      };
      this.progressBar = true;
      axios
        .post(`${ROOT_URL}/bathrooms/create`, bathroom)
        .then((response) => {
          this.progressBar = false;
          this.dialog = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.createReportDiv {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.addReportTitle {
  font-size: 18px;
  color: #9b5de5;
  padding: 10px;
}
.createReportBtn {
  margin-right: 15px;
  margin-bottom: 10px;
  background: #9b5de5 !important;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
}
.createReportBtn:hover {
  color: #9b5de5;
  background: #fff !important;
  transition: color 0.8s;
}

.dialog_card {
  border: 1px solid #5538c8;
}
.dialog_btn {
  color: #5538c8;
}
</style>
