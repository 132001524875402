<template>
  <div id="create">
    <!-- Layout 1 -->
    <v-layout row wrap class="" mt-3>
      <v-spacer></v-spacer>
      <v-flex v-if="progressBar" xs12 md4 class="">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <!-- chat erro snackbar -->
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="snackbarFlex">
        <v-snackbar
          class="SnackBarAlert"
          shaped
          :timeout="3000"
          :top="true"
          color="red"
          :multi-line="multiLine"
          v-model="showAlert"
        >
          <span style="font-size: 14px; font-weight: 900">
            يا
            {{ this.bosco_logged_in_customer }} :
            {{ chat_error_message }}
          </span>
        </v-snackbar>
      </v-flex>
    </v-layout>
    <!-- Layout 2 -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="bouquet_empty_dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title
              style="background: #9b5de5; color: #fff"
              class="text-h5 lighten-2"
            >
            بوسكو للتسويق العقاري 
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2">
              يا مكتب {{ bosco_logged_in_customer }} الرصيد انتهي.
            </v-card-text>
            <v-card-text class="text-h6 mt-4 lighten-2">
              اتواصل معانا وجدد الباقة.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/boAdmin">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- LayOut 3-->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title class="text-h5 grey lighten-2">
              بوسكو للتسويق العقاري 
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2">
              تم الإعلان عن المزرعة
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/agriculturalFields">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Layout 3 -->
    <v-layout row wrap class="">
      <v-flex xs12 md6 class="addReportTitleFlex">
        <h3 class="addReportTitle">الإعلان عن مزرعة:</h3>
      </v-flex>
    </v-layout>
    <!-- Layout 3 -->
    <form @submit.prevent="onSubmit" enctype="multipart/form-data">
      <div>
        <!-- Layout 4 -->
        <v-layout row wrap class="">
          <v-flex xs12 md6 class="dropper">
            <input
              class="imageInput"
              name="image"
              type="file"
              ref="file"
              @change="onSelect"
            />
          </v-flex>
        </v-layout>
        <!-- Layout 4 -->
        <v-layout row wrap class="">
         <!-- agricultural owner phone number  input -->
          <v-flex xs12 md3 class="customer_phone_number allInputsflex">
            <v-text-field
              label="رقم التلفون"
              outlined
              v-model="agricultural_owner_phone_number"
              v-bind:rules="PhoneRules"
            >
            </v-text-field>
          </v-flex>
           <!-- agricultural owner whatsApp number address input -->
          <v-flex xs12 md3 class="allInputsflex">
            <v-text-field
              label="رقم الواتساب"
              outlined
              v-model="agricultural_owner_whatsApp_number"
            >
            </v-text-field>
          </v-flex>
           <!-- agricultural address input -->
          <v-flex xs12 md3 class="allInputsflex">
            <v-text-field
              label="السعر"
              outlined
              v-model="agricultural_price"
              v-bind:rules="PriceRules"
            >
            </v-text-field>
          </v-flex>
          <!-- this will be dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">نوع العملة</span>
            <select v-model="currency_type_title" id="currency">
              <option
                :value="currency.currency_type_title"
                id="currencyOptions"
                v-for="currency in allCurrencies"
                :key="currency._id"
              >
                {{ currency.currency_type_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <!-- Layout 5 -->
        <v-layout row wrap class="">
          <!-- city title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">المدينة</span>
            <select v-model="city_title" id="cities">
              <option
                :value="city.city_title"
                id="cityOptions"
                v-for="city in allCities"
                :key="city._id"
              >
                {{ city.city_title }}
              </option>
            </select>
          </v-flex>
           <!-- agricultural address input -->
          <v-flex xs12 md3 class="allInputsflex">
            <v-text-field
              label="العنوان"
              outlined
              v-model="agricultural_address"
              v-bind:rules="AddressRules"
            >
            </v-text-field>
          </v-flex>
         <!-- agricultural area input -->
          <v-flex xs12 md3 class="allInputsflex">
            <v-text-field
              label="مساحة المزرعة"
              outlined
              v-model="agricultural_area"
            >
            </v-text-field>
          </v-flex>
          <!-- measurement title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">وحدة القياس</span>
            <select v-model="measurement_title" id="measurement">
              <option
                :value="measurement.measurement_title"
                id="measurementOptions"
                v-for="measurement in allMeasurements"
                :key="measurement._id"
              >
                {{ measurement.measurement_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <!--Layout -->
        <v-layout row wrap class="">
          <!-- advertisment title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">إعلان شنو</span>
            <select v-model="advertisment_title" id="advertisment">
              <option
                :value="advertisment.advertisment_title"
                id="advertismentOptions"
                v-for="advertisment in allAdvertisments"
                :key="advertisment._id"
              >
                {{ advertisment.advertisment_title }}
              </option>
            </select>
          </v-flex>
          <v-flex xs12 md3 class="">
            <!-- source of water title dropdown menu-->
            <span class="selectSpan">مصدر الماء</span>
            <select v-model="source_of_water_title" id="source_of_water_title">
              <option
                :value="water.source_of_water_title"
                id="waterOptions"
                v-for="water in allWaters"
                :key="water._id"
              >
                {{ water.source_of_water_title }}
              </option>
            </select>
          </v-flex>
          <v-flex xs12 md3 class="">
            <!-- power title dropdown menu-->
            <span class="selectSpan">مصدر الطاقة</span>
            <select v-model="power_title" id="power">
              <option
                :value="power.power_title"
                id="powerOptions"
                v-for="power in allPowers"
                :key="power._id"
              >
                {{ power.power_title }}
              </option>
            </select>
          </v-flex>
          <v-flex xs12 md3 class="">
            <!-- wall title dropdown menu-->
            <span class="selectSpan">سور</span>
            <select v-model="wall_title" id="wall">
              <option
                :value="wall.wall_title"
                id="wallOptions"
                v-for="wall in allWalls"
                :key="wall._id"
              >
                {{ wall.wall_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="">
          <v-flex xs12 md6 class="allInputsflex">
            <v-textarea
              label="مميزات المزرعة والشروط..."
              auto-grow
              outlined
              rows="12"
              row-height="10"
              v-model="agricultural_notes"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <!-- Layout 6 -->
        <v-layout row wrap class="createReportBtnLayout">
          <v-flex xs12 md4 class="createReportBtnFlex">
            <button class="createReportBtn" elevation="0">نشر</button>
            <router-link to="/agriculturalFields">
              <button class="cancelBtn">الغاء</button>
            </router-link>
          </v-flex>
        </v-layout>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
const ROOT_URL = "/api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CreateAgriculturalFields",
  data: () => ({
    progressBar: false,
    dialog: false,
    ////////////////////
    showAlert: false,
    multiLine: true,
    chat_error_message: "يجب ادخال جميع البيانات",
    ////////////////////
    AddressRules: [(v) => !!v || "العنوان مطلوب"],
    PhoneRules: [(v) => !!v || "رقم التلفون مطلوب"],
    PriceRules: [(v) => !!v || "السعر مطلوب"],

    ////////////////////
    bouquet_empty_dialog: false,
    //////////////////
    file: "",
    //////////////////
    agricultural_owner_phone_number: "",
    agricultural_owner_whatsApp_number: "",
    agricultural_price: "",
    currency_type_title: "",
    city_title: "",
    agricultural_address: "",
    agricultural_area: "",
    source_of_water_title: "",
    power_title: "",
    wall_title: "",
    advertisment_title: "",
    measurement_title: "",
    agricultural_notes: "",
  }),

  computed: {
    ////////////////////////////////////////////////
    total_published_customer_adds: function () {
      let total = Number(
        this.allAgriculturalFieldsPostsByCustomer.length +
          this.allApartmentsForRentByCustomer.length +
          this.allApartmentsForSaleByCustomer.length +
          this.allBuildingsByCustomer.length +
          this.allHousesByCustomer.length +
          this.allLandsByCustomer.length +
          this.allOfficesByCustomer.length +
          this.allShopsByCustomer.length +
          this.allStudentsHouseByCustomer.length +
          this.allVillasByCustomer.length
      );
      return total;
    },
    bosco_customer_email: function () {
      return this.get_bosco_customer.customer_email;
    },
    bosco_logged_in_customer: function () {
      return this.loggedInUser;
    },
    ///////////////mapGetters
    ...mapGetters([
      "get_bosco_customer",
      "loggedInUser",
      "allBoscoCustomerNumberOfPosts",
      ////Feach Number of posts by Customer///////
      "allAgriculturalFieldsPostsByCustomer",
      "allApartmentsForRentByCustomer",
      "allApartmentsForSaleByCustomer",
      "allBuildingsByCustomer",
      "allHousesByCustomer",
      "allLandsByCustomer",
      "allOfficesByCustomer",
      "allShopsByCustomer",
      "allStudentsHouseByCustomer",
      "allVillasByCustomer",
      ///////////////////////////////
      "allWalls",
      "allPowers",
      "allWaters",
      "allCities",
      "allAdvertisments",
      "allMeasurements",
      "allCurrencies",
    ]),
   isLoggedIn() {
          return this.$store.getters.isLoggedIn;
        },
  },
    mounted: function () {
      if (!this.isLoggedIn) {
        this.$router.push({ path: "/" });
      }
    },
  created() {
    this.fetchWalls();
    this.fetchPowers();
    this.fetchWaters();
    this.fetchCities();
    this.fetchAdvertisments();
    this.fetchMeasurements();
    this.fetchCurrencies();
    ////Feach Number of posts by Customer///////
    this.fetchAgriculturalFields();
    this.fetchApartmentsForRent();
    this.fetchApartmentsForSale();
    this.fetchBuildings();
    this.fetchHouses();
    this.fetchLands();
    this.fetchOffices();
    this.fetchShops();
    this.fetchStudentsHouse();
    this.fetchVillas();
  },

  methods: {
    ...mapActions([
      "fetchWalls",
      "fetchPowers",
      "fetchWaters",
      "fetchCities",
      "fetchAdvertisments",
      "fetchMeasurements",
      "fetchCurrencies",
      ////Feach Number of posts by Customer///////
      "fetchAgriculturalFields",
      "fetchApartmentsForRent",
      "fetchApartmentsForSale",
      "fetchBuildings",
      "fetchHouses",
      "fetchLands",
      "fetchOffices",
      "fetchShops",
      "fetchStudentsHouse",
      "fetchVillas",
    ]),

    onSelect() {
      const file = this.$refs.file.files[0];
      this.file = file;
    },
    async onSubmit() {
      if (
        this.file === "" ||
        this.bosco_logged_in_customer === "" ||
        this.bosco_customer_email === "" ||
        this.agricultural_price === "" ||
        this.agricultural_owner_phone_number === "" ||
        this.agricultural_address === "" ||
        this.city_title === "" ||
        this.measurement_title === "" ||
        this.advertisment_title === "" ||
        this.currency_type_title === ""
      ) {
        return (this.showAlert = true);
      } else {
        const fd = new FormData();
        fd.append("image", this.file);
        ///////////////////////////////
        fd.append("bosco_customer_email", this.bosco_customer_email);
        fd.append("bosco_logged_in_customer", this.bosco_logged_in_customer);
        ///////////////////////////////
        fd.append(
          "agricultural_owner_phone_number",
          this.agricultural_owner_phone_number
        );
        fd.append(
          "agricultural_owner_whatsApp_number",
          this.agricultural_owner_whatsApp_number
        );
        fd.append("agricultural_price", this.agricultural_price);
        fd.append("currency_type_title", this.currency_type_title);
        fd.append("city_title", this.city_title);
        fd.append("agricultural_address", this.agricultural_address);
        fd.append("agricultural_area", this.agricultural_area);
        fd.append("source_of_water_title", this.source_of_water_title);
        fd.append("power_title", this.power_title);
        fd.append("advertisment_title", this.advertisment_title);
        fd.append("measurement_title", this.measurement_title);
        fd.append("wall_title", this.wall_title);
        fd.append("agricultural_notes", this.agricultural_notes);
        ///Calculate bosco customer bouquet remain days
        let date_1 = new Date(
          this.get_bosco_customer.bosco_bouquet_expiration_day
        );
        let date_2 = new Date();
        let difference = date_1.getTime() - date_2.getTime();
        let customer_bouquet_remain_days = difference;

        if (
          Number(
            this.get_bosco_customer.bosco_bouquet_number_of_posts -
              this.total_published_customer_adds
          ) <= 0 ||
          customer_bouquet_remain_days <= 0
        ) {
          this.bouquet_empty_dialog = true;
        } else {
          //////Show progressBar
          this.progressBar = true;
          try {
            await axios.post(`${ROOT_URL}/agriculturals/create`, fd);
            //////Hide progressBar and show dialog
            this.progressBar = false;
            this.dialog = true;
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
  },
};
</script>
<style scoped>
#create {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.addReportTitleFlex {
  margin-bottom: 30px;
}
.addReportTitle {
  font-size: 18px;
  color: #9b5de5;
  padding: 10px;
}
.createReportBtn {
  width: 80px;
  padding: 10px;
  margin-left: 30px;
  margin-bottom: 60px;
  background: #9b5de5 !important;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  border: 1px solid #9b5de5;
  border-radius: 2px;
}
.cancelBtn {
  width: 80px;
  padding: 10px;
  margin-left: 15px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  background: #f15bb5;
  border: 1px solid #9b5de5;
  border-radius: 2px;
}
.createReportBtn:hover {
  color: #9b5de5;
  background: #fff !important;
  transition: color 0.8s;
}

.dialog_card {
  border: 1px solid #9b5de5;
}
.dialog_btn {
  border: 1px solid #9b5de5;
  color: #9b5de5;
}
.allInputsflex {
    margin-right: 15px;
  }
.dropper {
  margin-right: 15px;
  margin-bottom: 20px;
  margin-top: 5px;
  background: #00f5d4;
  height: 10vh;
  border: 10px dashed #9b5de5;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropper:hover {
  background: #eee;
}
.imageInput {
  color: #9b5de5 !important;
}
select {
  margin-bottom: 30px;
  margin-right: 15px;
  padding: 5px;
  border: 1px solid rgb(191, 186, 208);
  border-radius: 4px;
  width: 90%;
}
.selectSpan {
  margin-right: 15px;
}
.createReportBtnFlex{
    text-align: center;
  }
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #create{
    padding: 18px;
    margin-top: 15px;
  }
  .allInputsflex {
    margin-right: 0px;
  }
  select {
    margin-right: 0px;
  }
  .dropper {
    border: 5px dashed #9b5de5;
    margin: 0 auto;
  }
  .customer_phone_number {
    margin-top: 15px;
  }
  .createReportBtnLayout {
    margin-bottom: 40px;
  }
}
</style>
