<template>
  <div id="create">
    <!-- Layout 1 -->
    <v-layout row wrap class="" mt-3>
      <v-spacer></v-spacer>
      <v-flex v-if="progressBar" xs12 md4 class="">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <!-- chat erro snackbar -->
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="snackbarFlex">
        <v-snackbar
          class="SnackBarAlert"
          shaped
          :timeout="3000"
          :top="true"
          color="red"
          :multi-line="multiLine"
          v-model="showAlert"
        >
          <span style="font-size: 14px; font-weight: 900">
            يا
            {{ this.bosco_logged_in_customer }} :
            {{ chat_error_message }}
          </span>
        </v-snackbar>
      </v-flex>
    </v-layout>
    <!-- Layout 2 -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="bouquet_empty_dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title
              style="background: #9b5de5; color: #fff"
              class="text-h5 lighten-2"
            >
              بوسكو للتسويق العقاري
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2">
              يا مكتب {{ bosco_logged_in_customer }} الرصيد انتهي.
            </v-card-text>
            <v-card-text class="text-h6 mt-4 lighten-2">
              اتواصل معانا وجدد الباقة.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/boAdmin">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Layout 2 -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title class="text-h5 grey lighten-2">
              بوسكو للتسويق العقاري 
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2">
              تم تعديل بيانات الفيلا
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/villas">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Layout 3 -->
    <v-layout row wrap>
      <v-flex xs12 md12>
          <p class="countToolBar">
            تعديل بيانات الفيلا
          </p>
      </v-flex>
    </v-layout>
    <!-- Data -->
    <div>
      <!-- Layout 4 -->
      <v-layout row wrap class="">
        <v-flex xs12 md3 class="customer_phone_number">
          <v-text-field
            label="رقم التلفون"
            outlined
            v-model="edit_villa.villa_owner_phone_number"
            v-bind:rules="PhoneRules"
          >
          </v-text-field>
        </v-flex>
        <v-flex xs12 md3 class="">
          <v-text-field
            label="رقم الواتساب"
            outlined
            v-model="edit_villa.villa_owner_whatsApp_number"
          >
          </v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="">
        <v-flex xs12 md3 class="">
          <v-text-field
            label="السعر"
            outlined
            v-model="edit_villa.villa_price"
            v-bind:rules="PriceRules"
          >
          </v-text-field>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan">نوع العملة</span>
          <select v-model="edit_villa.currency_type_title" id="currency">
            <option
              :value="currency.currency_type_title"
              id="currencyOptions"
              v-for="currency in allCurrencies"
              :key="currency._id"
            >
              {{ currency.currency_type_title }}
            </option>
          </select>
        </v-flex>
      </v-layout>
      <!-- Layout 5 -->
      <v-layout row wrap class="">
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan">المدينة</span>
          <select v-model="edit_villa.city_title" id="cities">
            <option
              :value="city.city_title"
              id="cityOptions"
              v-for="city in allCities"
              :key="city._id"
            >
              {{ city.city_title }}
            </option>
          </select>
        </v-flex>
        <v-flex xs12 md3 class="">
          <v-text-field
            label="المنطقة/الحي"
            outlined
            v-model="edit_villa.villa_neighborhood"
            v-bind:rules="NeighborhoodRules"
          >
          </v-text-field>
        </v-flex>
        <v-flex xs12 md3 class="">
          <v-text-field
            label="العنوان"
            outlined
            v-model="edit_villa.villa_address"
            v-bind:rules="AddressRules"
          >
          </v-text-field>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan">إعلان شنو</span>
          <select v-model="edit_villa.advertisment_title" id="advertisment">
            <option
              :value="advertisment.advertisment_title"
              id="advertismentOptions"
              v-for="advertisment in allAdvertisments"
              :key="advertisment._id"
            >
              {{ advertisment.advertisment_title }}
            </option>
          </select>
        </v-flex>
      </v-layout>
      <!-- Layout 5 -->
      <v-layout row wrap class="">
        <v-flex xs12 md3 class="">
          <v-text-field
            label="مساحة الفيلا"
            outlined
            v-model="edit_villa.villa_area"
          >
          </v-text-field>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan">وحدة القياس</span>
          <select v-model="edit_villa.measurement_title" id="measurement">
            <option
              :value="measurement.measurement_title"
              id="measurementOptions"
              v-for="measurement in allMeasurements"
              :key="measurement._id"
            >
              {{ measurement.measurement_title }}
            </option>
          </select>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan"> الطابق</span>
          <select v-model="edit_villa.floor_title" id="floor">
            <option
              :value="floor.floor_title"
              id="floorOptions"
              v-for="floor in allFloors"
              :key="floor._id"
            >
              {{ floor.floor_title }}
            </option>
          </select>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan"> نوع التشطيب</span>
          <select
            v-model="edit_villa.finishing_type_title"
            id="finishingTypeTitle"
          >
            <option
              :value="finishingTypeTitle.finishing_type_title"
              id="finishingTypeTitleOptions"
              v-for="finishingTypeTitle in allFinishings"
              :key="finishingTypeTitle._id"
            >
              {{ finishingTypeTitle.finishing_type_title }}
            </option>
          </select>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="">
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan"> المطابخ</span>
          <select v-model="edit_villa.kitchen_title" id="kitchen">
            <option
              :value="kitchen.kitchen_title"
              id="kitchenOptions"
              v-for="kitchen in allKitchens"
              :key="kitchen._id"
            >
              {{ kitchen.kitchen_title }}
            </option>
          </select>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan"> حمام</span>
          <select v-model="edit_villa.bathroom_title" id="bathroom">
            <option
              :value="bathroom.bathroom_title"
              id="bathroomOptions"
              v-for="bathroom in allBathrooms"
              :key="bathroom._id"
            >
              {{ bathroom.bathroom_title }}
            </option>
          </select>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan"> مصدر طاقة إضافي</span>
          <select v-model="edit_villa.power_title" id="powers">
            <option
              :value="power.power_title"
              id="powersOptions"
              v-for="power in allPowers"
              :key="power._id"
            >
              {{ power.power_title }}
            </option>
          </select>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan">الكروكي</span>
          <select v-model="edit_villa.sketch_title" id="sketch">
            <option
              :value="sketch.sketch_title"
              id="sketchOptions"
              v-for="sketch in allSketches"
              :key="sketch._id"
            >
              {{ sketch.sketch_title }}
            </option>
          </select>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="">
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan"> أثاثات</span>
          <select v-model="edit_villa.furniture_title" id="furniture">
            <option
              :value="furniture.furniture_title"
              id="furnitureOptions"
              v-for="furniture in allFurnitures"
              :key="furniture._id"
            >
              {{ furniture.furniture_title }}
            </option>
          </select>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan"> موقف سيارات</span>
          <select v-model="edit_villa.parking_title" id="parking">
            <option
              :value="parking.parking_title"
              id="parkingOptions"
              v-for="parking in allParkings"
              :key="parking._id"
            >
              {{ parking.parking_title }}
            </option>
          </select>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan"> تكييف</span>
          <select v-model="edit_villa.air_condition_title" id="air_condition">
            <option
              :value="condition.air_condition_title"
              id="air_conditionOptions"
              v-for="condition in allAirConditions"
              :key="condition._id"
            >
              {{ condition.air_condition_title }}
            </option>
          </select>
        </v-flex>
        <!-- this will be dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan"> مقدم</span>
          <select
            v-model="edit_villa.advanced_payment_title"
            id="advancedPayment"
          >
            <option
              :value="advancedPayment.advanced_payment_title"
              id="bathroomOptions"
              v-for="advancedPayment in allAdvancedPayments"
              :key="advancedPayment._id"
            >
              {{ advancedPayment.advanced_payment_title }}
            </option>
          </select>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="">
        <v-flex xs12 md3 class="">
          <!-- this will be select-->
          <span class="selectSpan"> الغرف</span>
          <select v-model="edit_villa.room_title" id="room">
            <option
              :value="room.room_title"
              id="jobOptions"
              v-for="room in allRooms"
              :key="room._id"
            >
              {{ room.room_title }}
            </option>
          </select>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="">
        <v-flex xs12 md6 class="">
          <v-textarea
            label=" مميزات الفيلا والشروط..."
            auto-grow
            outlined
            rows="12"
            row-height="10"
            v-model="edit_villa.villa_notes"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <!-- Layout 6 -->
      <v-layout row wrap class="createReportBtnLayout">
        <v-flex xs12 md4 class="createReportBtnFlex">
          <button class="createReportBtn" @click="updateVilla" elevation="0">
            نشر
          </button>
          <router-link to="/villas">
            <button class="cancelBtn">الغاء</button>
          </router-link>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const ROOT_URL = "/api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditVilla",
  data: () => ({
    ///////////////
    progressBar: false,
    dialog: false,
    ///////////////
    showAlert: false,
    multiLine: true,
    chat_error_message: "يجب ادخال جميع البيانات",
    ///////////////
    NeighborhoodRules: [(v) => !!v || "اسم الحي/المنطقة مطلوب"],
    AddressRules: [(v) => !!v || "العنوان مطلوب"],
    PhoneRules: [(v) => !!v || "رقم التلفون مطلوب"],
    PriceRules: [(v) => !!v || "السعر مطلوب"],
    ////////////////
    bouquet_empty_dialog: false,
    ///////////////
    edit_villa: {
      villa_owner_phone_number: "",
      villa_owner_whatsApp_number: "",
      villa_price: "",
      currency_type_title: "",
      city_title: "",
      villa_neighborhood: "",
      villa_address: "",
      villa_area: "",
      measurement_title: "",
      floor_title: "",
      finishing_type_title: "",
      room_title: "",
      kitchen_title: "",
      bathroom_title: "",
      power_title: "",
      parking_title: "",
      sketch_title: "",
      furniture_title: "",
      air_condition_title: "",
      advertisment_title: "",
      advanced_payment_title: "",
      villa_notes: "",
    },
  }),
  computed: {
    ////////////////////////////////////////////////
    total_published_customer_adds: function () {
      let total = Number(
        this.allAgriculturalFieldsPostsByCustomer.length +
          this.allApartmentsForRentByCustomer.length +
          this.allApartmentsForSaleByCustomer.length +
          this.allBuildingsByCustomer.length +
          this.allHousesByCustomer.length +
          this.allLandsByCustomer.length +
          this.allOfficesByCustomer.length +
          this.allShopsByCustomer.length +
          this.allStudentsHouseByCustomer.length +
          this.allVillasByCustomer.length
      );
      return total;
    },
    /////Logged In Customer
    bosco_logged_in_customer: function () {
      return this.loggedInUser;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    ///////////////mapGetters
    ...mapGetters([
      "get_bosco_customer",
      "loggedInUser",
      ////Feach Number of posts by Customer///////
      "allAgriculturalFieldsPostsByCustomer",
      "allApartmentsForRentByCustomer",
      "allApartmentsForSaleByCustomer",
      "allBuildingsByCustomer",
      "allHousesByCustomer",
      "allLandsByCustomer",
      "allOfficesByCustomer",
      "allShopsByCustomer",
      "allStudentsHouseByCustomer",
      "allVillasByCustomer",
      ///////////////////
      "allCities",
      "allAdvertisments",
      "allFloors",
      "allFinishings",
      "allRooms",
      "allKitchens",
      "allBathrooms",
      "allPowers",
      "allFurnitures",
      "allParkings",
      "allAirConditions",
      "allSketches",
      "allAdvancedPayments",
      "allMeasurements",
      "allCurrencies",
    ]),
  },
    mounted: function () {
      if (!this.isLoggedIn) {
        this.$router.push({ path: "/" });
      }
    },
  created() {
    this.fetchCities();
    this.fetchAdvertisments();
    this.fetchFloors();
    this.fetchHFinishings();
    this.fetchRooms();
    this.fetchKitchens();
    this.fetchBathrooms();
    this.fetchPowers();
    this.fetchFurnitures();
    this.fetchAirConditions();
    this.fetchParkings();
    this.fetchSketches();
    this.fetchAdvancedPayments();
    this.fetchMeasurements();
    this.fetchCurrencies();
    ////Feach Number of posts by Customer///////
    this.fetchAgriculturalFields();
    this.fetchApartmentsForRent();
    this.fetchApartmentsForSale();
    this.fetchBuildings();
    this.fetchHouses();
    this.fetchLands();
    this.fetchOffices();
    this.fetchShops();
    this.fetchStudentsHouse();
    this.fetchVillas();
    ///////////////////////////////
    let url = `${ROOT_URL}/villas/get/${this.$route.params.id}`;
    axios
      .get(url)
      .then((response) => {
        this.edit_villa = response.data.villa;
      })
      .catch((error) => {
        error;
      });
  },
  methods: {
    ...mapActions([
      "fetchAdvertisments",
      "fetchCities",
      "fetchFloors",
      "fetchHFinishings",
      "fetchHFinishings",
      "fetchRooms",
      "fetchKitchens",
      "fetchBathrooms",
      "fetchPowers",
      "fetchSketches",
      "fetchFurnitures",
      "fetchAirConditions",
      "fetchParkings",
      "fetchAdvancedPayments",
      "fetchMeasurements",
      "fetchCurrencies",
      ////Feach Number of posts by Customer///////
      "fetchAgriculturalFields",
      "fetchApartmentsForRent",
      "fetchApartmentsForSale",
      "fetchBuildings",
      "fetchHouses",
      "fetchLands",
      "fetchOffices",
      "fetchShops",
      "fetchStudentsHouse",
      "fetchVillas",
    ]),
    /////////////////////////////////////////
    updateVilla() {
      if (
        this.bosco_logged_in_customer === "" ||
        this.edit_villa.villa_price === "" ||
        this.edit_villa.villa_owner_phone_number === "" ||
        this.edit_villa.villa_address === "" ||
        this.edit_villa.city_title === "" ||
        this.edit_villa.measurement_title === "" ||
        this.edit_villa.currency_type_title === ""
      ) {
        return (this.showAlert = true);
      } else {
        ///Calculate bosco customer bouquet remain days
        let date_1 = new Date(
          this.get_bosco_customer.bosco_bouquet_expiration_day
        );
        let date_2 = new Date();
        let difference = date_1.getTime() - date_2.getTime();
        let customer_bouquet_remain_days = difference;
        if (
          Number(
            this.get_bosco_customer.bosco_bouquet_number_of_posts -
              this.total_published_customer_adds
          ) <= 0 ||
          customer_bouquet_remain_days <= 0
        ) {
          //////Show dialog
          this.bouquet_empty_dialog = true;
        } else {
          const villa = {
            villa_owner_phone_number: this.edit_villa.villa_owner_phone_number,
            villa_owner_whatsApp_number:
              this.edit_villa.villa_owner_whatsApp_number,
            villa_price: this.edit_villa.villa_price,
            currency_type_title: this.edit_villa.currency_type_title,
            city_title: this.edit_villa.city_title,
            villa_neighborhood: this.edit_villa.villa_neighborhood,
            villa_address: this.edit_villa.villa_address,
            villa_area: this.edit_villa.villa_area,
            measurement_title: this.edit_villa.measurement_title,
            floor_title: this.edit_villa.floor_title,
            finishing_type_title: this.edit_villa.finishing_type_title,
            room_title: this.edit_villa.room_title,
            kitchen_title: this.edit_villa.kitchen_title,
            bathroom_title: this.edit_villa.bathroom_title,
            power_title: this.edit_villa.power_title,
            parking_title: this.edit_villa.parking_title,
            sketch_title: this.edit_villa.sketch_title,
            furniture_title: this.edit_villa.furniture_title,
            air_condition_title: this.edit_villa.air_condition_title,
            advertisment_title: this.edit_villa.advertisment_title,
            advanced_payment_title: this.edit_villa.advanced_payment_title,
            villa_notes: this.edit_villa.villa_notes,
          };
          this.progressBar = true;
          axios
            .patch(`${ROOT_URL}/villas/update/${this.$route.params.id}`, villa)
            .then(() => {
              this.progressBar = false;
              this.dialog = true;
            })
            .catch((error) => {
              console.log("Villa Updating Error: " + " " + error);
              error;
            });
        }
      }
    },
    cancelUpdate() {
      this.$router.push({ path: "/villas" });
    },
  },
};
</script>

<style scoped>
#create {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.countToolBar {
  background: #fff !important;
  color: #9b5de5 !important;
  font-size: 18px;
  font-weight: 500;
}

.createReportBtn {
  width: 80px;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  background: #9b5de5 !important;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  border: 1px solid #9b5de5;
  border-radius: 2px;
}
.cancelBtn {
  width: 80px;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  border: 1px solid #9b5de5;
  border-radius: 2px;
  background: #f15bb5;
}
.createReportBtn:hover {
  color: #9b5de5;
  background: #fff !important;
  transition: color 0.8s;
}
.cancleBtn {
  color: #9b5de5;
  border: 1px solid #9b5de5;
}
select {
  margin-bottom: 30px;
  margin-right: 15px;
  padding: 5px;
  border: 1px solid rgb(191, 186, 208);
  border-radius: 4px;
  width: 90%;
}
.selectSpan {
  margin-right: 15px;
}
.dialog_card {
  border: 1px solid #9b5de5;
}
.dialog_btn {
  color: #9b5de5;
  border: 1px solid #9b5de5;
}
.createReportBtnFlex{
    text-align: center;
  }
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #create {
    margin-top: 15px;
}
  .customer_phone_number {
    margin-top: 15px;
  }
  .createReportBtnLayout{
  margin-bottom: 60px;
}
}
</style>
