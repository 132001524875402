import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      cities: [],
};
/////////////// Getters
const getters = {
  allCities: (state) => state.cities.reverse()
};
/////////////// Actions
const actions = {
  async fetchCities({ commit }) {
    const response = await apiServices.fetch_cities();
    commit("setCities", response.data.cities);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setCities: (state, cities) => {
      state.cities = cities;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
