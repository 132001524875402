<template>
    <v-container id="logIn">
    <div class="boscoTitleDiv">
      <h2> <span style="color: #9b5de5; font-size: 40px;">بوسكو</span><span style="color: #000;"> للتسويق العقاري </span></h2>
      <h4>أعلن...اتصل...أجر</h4>
      <v-layout class="loginCardLayout"  style="margin-top: 40px" align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-0 loginCard">

            <v-toolbar class="logiToolbar" elevation="0">
              <v-toolbar-title class="logiToolbarTitle"
                >تسجيل الدخول
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form class="Loinform">
                <v-text-field
                  v-model="customer_email"
                  class="emailpassInput"
                  name="login"
                  label="ادخل الإيميل "
                  type="email"
                  outlined
                  v-bind:rules="emailRules"
                ></v-text-field>
                <v-text-field
                  autocomplete="off"
                  v-model="customer_password"
                  id="password"
                  class="emailpassInput"
                  name="customer_password"
                  label=" ادخل الباسورد"
                  type="password"
                  outlined
                  v-bind:rules="passwordRules"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <!-- <v-spacer></v-spacer> -->
              <v-btn @click="login" class="loginBtn elevation-0">
                تسجيل </v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <v-container v-if="progressBar" style="height: 400px">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="subtitle-1 text-center" cols="12">
            جاري تسجيل الدخول ....
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
      <v-layout row wrap class="white elevation-2">
        <v-flex xs12 md12 class="snackbarFlex">
          <v-snackbar
            class="SnackBarAlert"
            :timeout="6000"
            :top="true"
            color="red"
            :multi-line="multiLine"
            v-model="emptyDataErrorShowAlert"
            shaped
          >
            <h3>{{ emptyDataError }}</h3>
          </v-snackbar>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="white elevation-2">
        <v-flex xs12 md12 class="">
          <v-snackbar
            class="SnackBarAlert"
            :timeout="6000"
            :top="true"
            color="red"
            :multi-line="multiLine"
            v-model="showAlert"
            shaped
          >
            <h3>{{ loginError }}</h3>
          </v-snackbar>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "LogIn",
  component: {
  },
  data(){
    return {
       progressBar: false,
       showAlert: false,
       multiLine: true,
       emptyDataErrorShowAlert: false,
       emptyDataError: "يجب ادخال البيانات",
       ///////////////
       customer_email: "",
       customer_password: "",

       emailRules: [
        v => !!v || "الأيميل مطلوب",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "الايميل ضروري ومطلوب لاتمام التسجيل"
      ],
      passwordRules: [v => !!v || "الباسوورد مطلوب"]
    }

  },
   computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    loginError() {
      return this.$store.getters.loginError;
    }
  },
  methods: {
        login: function() {
      const payload = {
        customer_email: this.customer_email,
        customer_password: this.customer_password
      };
    if(payload.customer_email === "" || payload.customer_password === ""){
     return this.emptyDataErrorShowAlert = true;
    } else {
      this.$store
        .dispatch("logInUser", payload)

        .then(() => {
          if (this.isLoggedIn) {
              this.progressBar = true;
              this.$router.push({ path: "/boAdmin" });
              this.progressBar = false;

          } else {
            this.showAlert = true;
          }
        });
    }}
  },
};
</script>

<style scoped>
#logIn {
  text-align: center;
  margin-top: 150px;
  background: #fff;
  padding: 20px;
}
.boscoTitleDiv {
  color: #9b5de5;
  font-size: 20px;
}
.Loinform{
  margin-top: 10px;
}
.logiToolbar {
  background: #9b5de5 !important;
  color: #ffff;
}
.loginBtn {
  font-weight: 600;
  background: #9b5de5 !important;
  color: #ffff;
}

.loginBtn:hover {
  color: #9b5de5;
  background: #fff !important;
  transition: color 0.8s;
}

.loginCard {
  border-radius: 5px !important;
}
/* Media Queries */
/* most mobiles has width about 400px */
@media screen and (max-width: 500px) {
  /* most devices has 500px or less  "mobiels"*/
  #logIn {
    margin-top: 8px;
}
.loginCardLayout{
  margin-bottom: 100px;
}
}

@media screen and (max-width: 800px) {
  /* any device has 800px and less  "Ipad"*/

}
</style>
