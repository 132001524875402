import apiServices from "../../api/apiServices";
/////////////// State
const state = {
  shops: [],
  totalShopsByCustomer: [],
};
/////////////// Getters
const getters = {
  allShops: (state) => state.shops.reverse(),
  allShopsByCustomer: (state) => state.totalShopsByCustomer.reverse(),
};
/////////////// Actions
const actions = {
  async fetchShops({ commit, rootGetters }) {
    const response = await apiServices.fetch_shops();
    console.log(response.data)
    //filter shops posts by customer
    let total_shops_posts = response.data.shops.filter((items) => {
      const matchPost = items.bosco_customer_email.includes(
        rootGetters.get_bosco_customer.customer_email
      );
      return matchPost;
    });
    commit("setShopsByCustomer", total_shops_posts);
    commit("setShops", response.data.shops);
  },
};
/////////////// MUTATIONS
const mutations = {
  setShops: (state, shops) => {
    state.shops = shops;
  },
  setShopsByCustomer: (state, total_shops_posts) => {
    state.totalShopsByCustomer = total_shops_posts;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
