<template>
  <v-container id="setting">
    <!-- Layuout 1 -->
    <v-layout row wrap class="">
      <v-spacer></v-spacer>
      <v-flex xs12 md12 class="">
        <h2 class="settingFirstTitle">ضبظ النظام</h2>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <!-- Layout 2-->
<v-layout row wrap class="pagesLauout">
 <v-flex xs12 md6 class="pagesFlex">
  <v-card
    elevation="0"
    class="mx-auto pagesCard"
    max-width="800"
  >
      <v-list >
      <v-list-group
        v-model="active"
        no-action
        class=""
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="addTitle">إضافات خيارات النشر</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item >
          <v-list-item-content class="pagesListItem">
            <router-link to="/createWall">
              <v-list-item-title class="pagesTiltle">
                إضافة سور
            </v-list-item-title>
            </router-link>
            <router-link to="/createSourceOfWater">
              <v-list-item-title class="pagesTiltle">
                إضافة مصدر مياة
            </v-list-item-title>
            </router-link>
            <router-link to="/createSketch">
              <v-list-item-title class="pagesTiltle">
                إضافة كروكي         
                   </v-list-item-title>
            </router-link>
            <router-link to="/createPower">
              <v-list-item-title class="pagesTiltle">
                إضافة مصدر طاقة           
                 </v-list-item-title>
            </router-link>
            <router-link to="/createRoom">
              <v-list-item-title class="pagesTiltle">
                إضافة غرفة
            </v-list-item-title>
            </router-link>
            <router-link to="/createParking">
              <v-list-item-title class="pagesTiltle">
                إضافة موقف عربات
            </v-list-item-title>
            </router-link>
            <router-link to="/createOwnership">
              <v-list-item-title class="pagesTiltle">
                إضافة نوع الملكية   
           </v-list-item-title>
            </router-link>
            <router-link to="/createKitchen">
              <v-list-item-title class="pagesTiltle">
                إضافة مطبخ
            </v-list-item-title>
            </router-link>
            <router-link to="/createHall">
              <v-list-item-title class="pagesTiltle">
                إضافة صالة
            </v-list-item-title>
            </router-link>
            <router-link to="/createGuard">
              <v-list-item-title class="pagesTiltle">
                إضافة تامين
            </v-list-item-title>
            </router-link>
            <router-link to="/createGeneral">
              <v-list-item-title class="pagesTiltle">
                إضافة انترنت
            </v-list-item-title>
            </router-link>
            <router-link to="/createFurniture">
              <v-list-item-title class="pagesTiltle">
                إضافة أثاثات
            </v-list-item-title>
            </router-link>
            <router-link to="/createFloor">
              <v-list-item-title class="pagesTiltle">
                إضافة نوع الطابق
            </v-list-item-title>
            </router-link>
            <router-link to="/createFinishingType">
              <v-list-item-title class="pagesTiltle">
                إضافة نوع التشطيب
            </v-list-item-title>
            </router-link>
            <router-link to="/createElevator">
              <v-list-item-title class="pagesTiltle">
                إضافة نوع الاسانسير
            </v-list-item-title>
            </router-link>
            <router-link to="/createCity">
              <v-list-item-title class="pagesTiltle">
                إضافة مدينة
            </v-list-item-title>
            </router-link>
            <router-link to="/createBalcony">
              <v-list-item-title class="pagesTiltle">
                إضافة نوع البلكونة
            </v-list-item-title>
            </router-link>
            <router-link to="/createAirCondition">
              <v-list-item-title class="pagesTiltle">
                إضافة نوع التكييف
            </v-list-item-title>
            </router-link>
            <router-link to="/createAdvertismentFor">
              <v-list-item-title class="pagesTiltle">
                إضافة نوع الإعلان
            </v-list-item-title>
            </router-link>
            <router-link to="/createAdvancedPayment">
              <v-list-item-title class="pagesTiltle">
                إضافة مقدم دفع
            </v-list-item-title>
            </router-link>
            <router-link to="/createBathroom">
              <v-list-item-title class="pagesTiltle">
                إضافة نوع الحمام
            </v-list-item-title>
            </router-link>
            <router-link to="/createMeasurement">
              <v-list-item-title class="pagesTiltle">
                إضافة وحدة قياس
            </v-list-item-title>
            </router-link>
            <router-link to="/createCurrencyType">
              <v-list-item-title class="pagesTiltle">
                إضافة نوع العملة
            </v-list-item-title>
            </router-link>
            <router-link to="/createBoscoBouquet">
              <v-list-item-title class="pagesTiltle">
                إضافة باقة
            </v-list-item-title>
            </router-link>
            <router-link to="/createRole">
              <v-list-item-title class="pagesTiltle">
                إضافة صلاحية المستخدم
            </v-list-item-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-card>
</v-flex>
    </v-layout>
   <!--Layout 3-->
    <v-layout row wrap class="pagesBtnLayout">
      <v-spacer></v-spacer>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminApartmentsForRent">
          <button class="createReportBtn" elevation="0">شقق للإيجار</button>
        </router-link>
      </v-flex>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminApartmentsForSale">
          <button class="createReportBtn" elevation="0">شقق للبيع</button>
        </router-link>
      </v-flex>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminLands">
          <button class="createReportBtn" elevation="0">أراضي</button>
        </router-link>
      </v-flex>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminHouses">
          <button class="createReportBtn" elevation="0">بيوت</button>
        </router-link>
      </v-flex>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminVillas">
          <button class="createReportBtn" elevation="0">فلل</button>
        </router-link>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <!-- layout 4 -->
    <v-layout row wrap class="pagesBtnLayout">
      <v-spacer></v-spacer>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminStudentsHouse">
          <button class="createReportBtn" elevation="0">داخليات</button>
        </router-link>
      </v-flex>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminOffices">
          <button class="createReportBtn" elevation="0"> مكاتب</button>
        </router-link>
      </v-flex>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminBuildings">
          <button class="createReportBtn" elevation="0">عمارات</button>
        </router-link>
      </v-flex>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminAgriculturalFields">
          <button class="createReportBtn" elevation="0">مزارع</button>
        </router-link>
      </v-flex>
      <v-flex xs12 md2 class="btnFlex">
        <router-link to="/adminShops">
          <button class="createReportBtn" elevation="0">دكاكين</button>
        </router-link>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BoSettings",
  components: {},
  data: () => ({
    active: false,
    }),
  computed: {
        isLoggedIn() {
          return this.$store.getters.isLoggedIn;
        },
    ...mapGetters(["allApartmentsForRent"]),
  },
  mounted: function () {
        if (!this.isLoggedIn) {
          this.$router.push({ path: "/" });
        }
  },
};
</script>
<style scoped>
#setting {
  /* text-align: center; */
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}

.settingFirstTitle {
  margin-right: 70px;
  color: #f15bb5;
  opacity: 0.8;
  margin-bottom: 10px;
}
.tabs {
  margin-top: 10px;
}
.settingSecTitle {
  color: #000;
  font-size: 15px;
}
.pagesBtnLayout {
  margin-top: 15px;
}
.createReportBtn {
  padding: 10px;
  width: 100%;

  margin-bottom: 10px;
  background: #9b5de5 !important;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  border: 1px solid #9b5de5;
  border-radius: 2px;
}
.createReportBtn:hover {
  color: #9b5de5;
  background: #fff !important;
  transition: color 0.8s;
}
.btnFlex{
  margin-left: 10px;
}
.pagesFlex{
  margin-bottom: 20px;
}
.addTitle{
  color: #9b5de5;
  font-size: 15px;
}
.pagesTiltle{
  color: #000;
  margin-bottom: 10px;
  border: 1px solid #9b5de5;
  padding: 10px;
}
/* Media Queries */
/* most mobiles has width about 400px */

@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #setting{
    padding: 8px;
}

}
@media screen and (max-width: 800px) {
  /* any device has 800px and less  "Ipad"*/
}
</style>
