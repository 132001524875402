import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      guards: [],
};
/////////////// Getters
const getters = {
  allGuards: (state) => state.guards.reverse()
};
/////////////// Actions
const actions = {
  async fetchGuards({ commit }) {
    const response = await apiServices.fetch_guards();
    commit("setGuards", response.data.guards);
  },
};
/////////////// MUTATIONS 
const mutations = {
      setGuards: (state, guards) => {
      state.guards = guards;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
