import { render, staticRenderFns } from "./AdminApartmentsForSale.vue?vue&type=template&id=2a56c8cf&scoped=true&"
import script from "./AdminApartmentsForSale.vue?vue&type=script&lang=js&"
export * from "./AdminApartmentsForSale.vue?vue&type=script&lang=js&"
import style0 from "./AdminApartmentsForSale.vue?vue&type=style&index=0&id=2a56c8cf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a56c8cf",
  null
  
)

export default component.exports