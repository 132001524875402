<template>
  <div>
    <!-- Navbar for admin -->
    <nav v-if="isLoggedIn">
      <!-- For Mobile -->
      <v-navigation-drawer v-model="drawer" app clipped class="mobileMenuList">
        <v-list>
          <router-link to="/boAdmin">
            <v-list-item class="boscomobileListItem">
              <p class="boscoAgaratMobile">
                <span class="boscoBo">بو</span>سكو
                <br />
                <span class="boscoAgaratMobilePara">منصة تسويق العقارات </span>
              </p>
            </v-list-item>
          </router-link>
        </v-list>
        <!-- list For Mobile Admin -->
        <div class="advertismentListItem">
          <router-link to="/boAdmin">
            <v-btn class="advertismentForMobile" elevation="1">
              <svg style="width: 30px; height: 30px" viewBox="0 0 24 24">
                <path
                  fill="#9b5de5"
                  d="M12 22H6A2 2 0 0 1 8 20V8H2V5H16V8H10V20A2 2 0 0 1 12 22M22 2V22H20V15H15V22H13V14A2 2 0 0 1 15 12H20V2Z"
                />
              </svg>
              مكتبك
            </v-btn>
          </router-link>
        </div>
        <v-list v-if="howIsUsingBoscoApi">
          <v-list-item>
            <v-list-item-content class="">
              <v-list-item-title class="mobileMenuListTitle">
                <router-link to="/boscoUsers">
                  <!-- Is User Is Admen -->
                  <v-btn class="menuItemsDesktop" elevation="0">
                    العملاء
                  </v-btn>
                </router-link>
              </v-list-item-title>
              <v-list-item-title class="mobileMenuListTitle">
                <router-link to="/boSettings">
                  <v-btn class="menuItemsDesktop" elevation="0"> الضبط </v-btn>
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item
            class="mobileListItem"
            v-for="item in main_toolbar_menu_backend"
            :key="item.title"
            route
            :to="item.route"
          >
            <v-list-item-content class="">
              <v-list-item-title class="mobileMenuListTitle">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- For Desktop backend -->
      <v-app-bar app fixed elevation="0" class="desktopTollbar">
        <!--nav-bar-icon svg-->
        <svg
          @click.stop="drawer = !drawer"
          class="hidden-md-and-up navBarIcon"
          viewBox="0 0 24 24"
        >
          <path
            fill="#fee440"
            d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
          />
        </svg>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <router-link to="/boAdmin">
            <v-btn class="menuItemsDesktop" elevation="0">
              <svg style="width: 30px; height: 30px" viewBox="0 0 24 24">
                <path
                  fill="#fee440"
                  d="M12 22H6A2 2 0 0 1 8 20V8H2V5H16V8H10V20A2 2 0 0 1 12 22M22 2V22H20V15H15V22H13V14A2 2 0 0 1 15 12H20V2Z"
                />
              </svg>
              مكتبك
            </v-btn>
          </router-link>
          <router-link to="/apartmentsForRent">
            <v-btn class="menuItemsDesktop" elevation="0"> شقق للإيجار </v-btn>
          </router-link>
          <router-link to="/apartmentsForSale">
            <v-btn class="menuItemsDesktop" elevation="0"> شقق للبيع </v-btn>
          </router-link>
          <router-link to="/houses">
            <v-btn class="menuItemsDesktop" elevation="0"> بيوت </v-btn>
          </router-link>
          <router-link to="/buildings">
            <v-btn class="menuItemsDesktop" elevation="0"> عمارات </v-btn>
          </router-link>
          <router-link to="/villas">
            <v-btn class="menuItemsDesktop" elevation="0"> فلل </v-btn>
          </router-link>
          <router-link to="/offices">
            <v-btn class="menuItemsDesktop" elevation="0"> مكاتب </v-btn>
          </router-link>
          <router-link to="/shops">
            <v-btn class="menuItemsDesktop" elevation="0"> دكاكين </v-btn>
          </router-link>
          <router-link to="/studentsHouse">
            <v-btn class="menuItemsDesktop" elevation="0"> داخليات </v-btn>
          </router-link>
          <router-link to="/agriculturalFields">
            <v-btn class="menuItemsDesktop" elevation="0"> مزارع </v-btn>
          </router-link>
          <router-link to="/lands">
            <v-btn class="menuItemsDesktop" elevation="0"> أراضي </v-btn>
          </router-link>
          <router-link v-if="howIsUsingBoscoApi" to="/boscoUsers">
            <!-- Is User Is Admen -->
            <v-btn class="menuItemsDesktop" elevation="0"> العملاء </v-btn>
          </router-link>
          <router-link v-if="howIsUsingBoscoApi" to="/boSettings">
            <v-btn class="menuItemsDesktop" elevation="0"> الضبط </v-btn>
          </router-link>
          <!-- <v-btn :to="item.route" class="menuItemsDesktop" elevation="0">
            {{ item.title }}
          </v-btn> -->
        </v-toolbar-items>
        <v-spacer> </v-spacer>
  
          <v-btn @click="boscoApiLogout" class="sign_out" elevation="0">
            <svg
              style="width: 20px; height: 20px; margin-left: 2px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#5538C8"
                d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z"
              />
            </svg>
            تسجيل الخروج
          </v-btn>
 
      </v-app-bar>
    </nav>

    <!-- Navbar for all customers and visitors -->
    <nav v-else>
      <!-- For Mobile -->
      <v-navigation-drawer v-model="drawer" app clipped class="mobileMenuList">
        <v-list>
          <router-link to="/">
            <v-list-item class="boscomobileListItem">
              <p class="boscoAgaratMobile">
                <span class="boscoBo">بو</span>سكو
                <br />
                <span class="boscoAgaratMobilePara">منصة تسويق العقارات </span>
              </p>
            </v-list-item>
          </router-link>
        </v-list>
        <!-- list For Mobile visitors -->
        <div class="advertismentListItem">
          <router-link to="/boBouquets">
            <v-btn class="advertismentForMobile" elevation="1">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="#9b5de5"
                  d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
                />
              </svg>
              عايز تعلن؟
            </v-btn>
          </router-link>
        </div>
        <v-list>
          <v-list-item
            class="mobileListItem"
            v-for="item in main_toolbar_menu_frontend"
            :key="item.title"
            route
            :to="item.route"
          >
            <v-list-item-content class="">
              <v-list-item-title class="mobileMenuListTitle">
                <!-- {{ item.icon }} -->
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!--  main service items for mobile خدمات -->
        <v-list class="service">
          <v-menu>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip>
                <template v-slot:activator="{ on: tooltip }">
                  <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-list-item-content>
                      <v-list-item-title class="serviceTitle">
                        خدمات
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <svg
                        style="width: 20px; height: 20px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fee440"
                          d="M19,3H5A2,2 0 0,0 3,5V19C3,20.11 3.9,21 5,21H19C20.11,21 21,20.11 21,19V5A2,2 0 0,0 19,3M19,19H5V5H19V19M7.41,8.29L12,12.88L16.59,8.29L18,9.71L12,15.71L6,9.71L7.41,8.29Z"
                        />
                      </svg>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-tooltip>
            </template>
            <v-list class="main_system_item_list">
              <v-list-item>
                <v-list-item-content>
                  <router-link to="/boSupports">
                    <v-list-item-title class="helpmeBolicy">
                      نساعدكم كيف؟
                    </v-list-item-title>
                  </router-link>
                  <router-link to="/boPrivacyPolicy">
                    <v-list-item-title class="helpmeBolicy">
                      سياسة الخصوصية
                    </v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </v-navigation-drawer>

      <!-- Desktop Navbar for all customers and visitors-->
      <v-app-bar app fixed elevation="0" class="desktopTollbar">
        <!--nav-bar-icon svg-->
        <!-- class="hidden-md-and-up navBarIcon" -->
        <svg
          @click.stop="drawer = !drawer"
          class=" navBarIcon"
          viewBox="0 0 24 24"
        >
          <path
            fill="#fee440"
            d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
          />
        </svg>
        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">
          <!-- <router-link to="/">
            <v-btn class="menuItemsDesktop" elevation="0">
              <svg style="width: 30px; height: 30px" viewBox="0 0 24 24">
                <path
                  fill="#fee440"
                  d="M21.8,13H20V21H13V17.67L15.79,14.88L16.5,15C17.66,15 18.6,14.06 18.6,12.9C18.6,11.74 17.66,10.8 16.5,10.8A2.1,2.1 0 0,0 14.4,12.9L14.5,13.61L13,15.13V9.65C13.66,9.29 14.1,8.6 14.1,7.8A2.1,2.1 0 0,0 12,5.7A2.1,2.1 0 0,0 9.9,7.8C9.9,8.6 10.34,9.29 11,9.65V15.13L9.5,13.61L9.6,12.9A2.1,2.1 0 0,0 7.5,10.8A2.1,2.1 0 0,0 5.4,12.9A2.1,2.1 0 0,0 7.5,15L8.21,14.88L11,17.67V21H4V13H2.25C1.83,13 1.42,13 1.42,12.79C1.43,12.57 1.85,12.15 2.28,11.72L11,3C11.33,2.67 11.67,2.33 12,2.33C12.33,2.33 12.67,2.67 13,3L17,7V6H19V9L21.78,11.78C22.18,12.18 22.59,12.59 22.6,12.8C22.6,13 22.2,13 21.8,13M7.5,12A0.9,0.9 0 0,1 8.4,12.9A0.9,0.9 0 0,1 7.5,13.8A0.9,0.9 0 0,1 6.6,12.9A0.9,0.9 0 0,1 7.5,12M16.5,12C17,12 17.4,12.4 17.4,12.9C17.4,13.4 17,13.8 16.5,13.8A0.9,0.9 0 0,1 15.6,12.9A0.9,0.9 0 0,1 16.5,12M12,6.9C12.5,6.9 12.9,7.3 12.9,7.8C12.9,8.3 12.5,8.7 12,8.7C11.5,8.7 11.1,8.3 11.1,7.8C11.1,7.3 11.5,6.9 12,6.9Z"
                />
              </svg>
              بيتنا
            </v-btn>
          </router-link> -->
        
        <!--  main service items for mobile شقق -->
        <!-- <v-list class="service">
          <v-menu>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip>
                <template v-slot:activator="{ on: tooltip }">
                  <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-list-item-content>
                      <v-list-item-title class="serviceTitle">
                        شقق
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <svg
                        style="width: 20px; height: 20px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fee440"
                          d="M19,3H5A2,2 0 0,0 3,5V19C3,20.11 3.9,21 5,21H19C20.11,21 21,20.11 21,19V5A2,2 0 0,0 19,3M19,19H5V5H19V19M7.41,8.29L12,12.88L16.59,8.29L18,9.71L12,15.71L6,9.71L7.41,8.29Z"
                        />
                      </svg>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-tooltip>
            </template>
            <v-list class="main_system_item_list">
              <v-list-item>
                <v-list-item-content>
                  <router-link to="/boApartmentsForRent">
                    <v-list-item-title class="helpmeBolicy">
                      شقق للإيجار
                    </v-list-item-title>
                  </router-link>
                  <router-link to="/boApartmentsForSale">
                    <v-list-item-title class="helpmeBolicy">
                      شقق للبيع 
                    </v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list> -->
          <!--  -->
          <!-- <router-link to="/boHouses">
            <v-btn class="menuItemsDesktop" elevation="0"> بيوت </v-btn>
          </router-link>
          <router-link to="/boBuildings">
            <v-btn class="menuItemsDesktop" elevation="0"> عمارات </v-btn>
          </router-link>
          <router-link to="/boShops">
            <v-btn class="menuItemsDesktop" elevation="0"> دكاكين </v-btn>
          </router-link>
          <router-link to="/boStudentsHousing">
            <v-btn class="menuItemsDesktop" elevation="0"> داخليات </v-btn>
          </router-link>
          <router-link to="/boLands">
            <v-btn class="menuItemsDesktop" elevation="0"> أراضي </v-btn>
          </router-link> -->
         <!--  main service items for mobile عقارات إضافية -->
        <!-- <v-list class="service">
          <v-menu>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip>
                <template v-slot:activator="{ on: tooltip }">
                  <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-list-item-content>
                      <v-list-item-title class="serviceTitle">
                        عقارات إضافية
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <svg
                        style="width: 20px; height: 20px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fee440"
                          d="M19,3H5A2,2 0 0,0 3,5V19C3,20.11 3.9,21 5,21H19C20.11,21 21,20.11 21,19V5A2,2 0 0,0 19,3M19,19H5V5H19V19M7.41,8.29L12,12.88L16.59,8.29L18,9.71L12,15.71L6,9.71L7.41,8.29Z"
                        />
                      </svg>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-tooltip>
            </template>
            <v-list class="main_system_item_list">
              <v-list-item>
                <v-list-item-content>
                  <router-link to="/boVillas">
                    <v-list-item-title class="helpmeBolicy">
                       فلل
                    </v-list-item-title>
                  </router-link>
                  <router-link to="/boAgriculturalFields">
                    <v-list-item-title class="helpmeBolicy">
                       مزارع 
                    </v-list-item-title>
                  </router-link>
                  <router-link to="/boOffices">
                    <v-list-item-title class="helpmeBolicy">
                       مكاتب 
                    </v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list> -->
      
          <!--  main service items خدمات -->
          <!-- <v-list class="service">
            <v-menu>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-list-item-content>
                        <v-list-item-title class="serviceTitle">
                          خدمات
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <svg
                          style="width: 20px; height: 20px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#fee440"
                            d="M19,3H5A2,2 0 0,0 3,5V19C3,20.11 3.9,21 5,21H19C20.11,21 21,20.11 21,19V5A2,2 0 0,0 19,3M19,19H5V5H19V19M7.41,8.29L12,12.88L16.59,8.29L18,9.71L12,15.71L6,9.71L7.41,8.29Z"
                          />
                        </svg>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                </v-tooltip>
              </template>
              <v-list class="main_system_item_list">
                <v-list-item>
                  <v-list-item-content>
                    <router-link to="/boOurTeam">
                      <v-list-item-title class="helpmeBolicy">
                        نحن منو؟
                      </v-list-item-title>
                    </router-link>
                    <router-link to="/boSupports">
                      <v-list-item-title class="helpmeBolicy">
                        نساعدكم كيف؟
                      </v-list-item-title>
                    </router-link>
                    <router-link to="/boPrivacyPolicy">
                      <v-list-item-title class="helpmeBolicy">
                        سياسة الخصوصية
                      </v-list-item-title>
                    </router-link>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list> -->
          <router-link to="/boBouquets">
            <v-btn class="advertisment" elevation="1">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="#9b5de5"
                  d="M12.67 20.74L12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 9.93 21.5 11.26 20.62 12.61C20 12.31 19.31 12.11 18.59 12.04C19.5 10.8 20 9.65 20 8.5C20 6.5 18.5 5 16.5 5C14.96 5 13.46 6 12.93 7.36H11.07C10.54 6 9.04 5 7.5 5C5.5 5 4 6.5 4 8.5C4 11.39 7.14 14.24 11.89 18.55L12 18.65L12.04 18.61C12.12 19.37 12.34 20.09 12.67 20.74M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"
                />
              </svg>
              عايز تعلن؟
            </v-btn>
          </router-link>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <a href ="https://wa.me/+249916099219" target="_blank">
        <v-btn  class="mx-4" icon>
          <svg  class="socialIcon" viewBox="0 0 24 24">
            <path
              fill="#fee440"
              d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
            />
          </svg>
        </v-btn>
      </a>
      <a href ="https://www.facebook.com/BoscoMarketing/" target="_blank">
        <v-btn
          class="mx-4"
          icon
        >
          <svg class="socialIcon" viewBox="0 0 24 24">
            <path
              fill="#fee440"
              d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
            />
          </svg>
        </v-btn>
        </a>
        <!-- <v-spacer></v-spacer> -->
        <router-link to="/">
          <v-toolbar-title class="bosco"
            ><span>B</span
            ><span style="color: #000">osco</span></v-toolbar-title
          >
        </router-link>
      </v-app-bar>
    </nav>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      fixed
      bottom
      right
      @click="toTop"
      class="goToTop"
    >
      <svg style="width: 40px; height: 40px" viewBox="0 0 24 24">
        <path
          fill="#9b5de5"
          d="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M12,7L7,12H10V16H14V12H17L12,7Z"
        />
      </svg>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AppHeader",
  components: {},
  data: () => ({
    ////////////
    role: false,
    /////////
    fab: false,
    ///////////
    drawer: false,
    // main toolbar menu for frontend mobile
    main_toolbar_menu_frontend: [
      { title: "بيتنا", route: "/" },
      { title: "شقق للإيجار", route: "/boApartmentsForRent" },
      { title: "شقق للبيع", route: "/boApartmentsForSale" },
      { title: "بيوت", route: "/boHouses" },
      { title: "عمارات", route: "/boBuildings" },
      { title: "فلل", route: "/boVillas" },
      { title: "مكاتب", route: "/boOffices" },
      { title: "دكاكين", route: "/boShops" },
      { title: "داخليات", route: "/boStudentsHousing" },
      { title: "مزارع", route: "/boAgriculturalFields" },
      { title: "أراضي", route: "/boLands" },
      { title: "نحن منو؟", route: "/boOurTeam" },
    ],
    main_toolbar_menu_backend: [
      { title: "شقق للإيجار", route: "/apartmentsForRent" },
      { title: "شقق للبيع", route: "/apartmentsForSale" },
      { title: "أراضي", route: "/lands" },
      { title: "بيوت", route: "/houses" },
      { title: "فلل", route: "/villas" },
      { title: "داخليات", route: "/studentsHouse" },
      { title: "دكاكين", route: "/shops" },
      { title: "مكاتب", route: "/offices" },
      { title: "عمارات", route: "/buildings" },
      { title: "مزارع", route: "/agriculturalFields" },
    ],
  }),
  created() {},
  computed: {
    /////Logged In Customer
    howIsUsingBoscoApi() {
       if(this.get_bosco_customer.bosco_customer_role_title === "isBoscoAdmin"){
         this.role = true;
       } else {
        this.role = false;
       }
       return this.role
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    ...mapGetters(["get_bosco_customer"])
  },
  methods: {
    boscoApiLogout: function (){
      this.$store.dispatch("logout")
      .then(() => {
        this.$router.push({ path: "/" });
      })
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.mobileMenuList {
  background: #fff !important;
}
.sign_out {
  margin-right: 3px;
  background: #fee440 !important;
  color: #000;
  font-weight: 700;
  font-size: 14px;
}
.navBarIcon {
  width: 50px;
  height: 50px;
}
.menuIcon {
  color: #9b5de5 !important;
}
.socialIcon {
  width: 40px;
  height: 40px;
}

.desktopTollbar {
  background: #9b5de5 !important;
}
.advertismentListItem {
  text-align: center;
}
.advertisment {
  background: #fee440 !important;
  color: #9b5de5;
  font-weight: 700;
  font-size: 14px !important;
  margin-left: 1px;
  margin-top: 12px;
}

.advertismentForMobile {
  background: #fee440 !important;
  color: #9b5de5;
  font-weight: 700;
  font-size: 14px !important;
  margin-left: 1px;
  margin-top: 1px;
}
.menuItemsDesktop {
  background: #9b5de5 !important;
  color: #fff;
  font-weight: 700;
  font-size: 14px !important;
  margin-left: 1px;
  margin-top: 12px;
}
.helpmeBolicy {
  padding: 10px;
  background: #9b5de5 !important;
  color: #fee440 !important;
  font-weight: 700;
  font-size: 14px !important;
}
.service {
  background: #9b5de5 !important;
  font-weight: 700;
  font-size: 14px !important;
}
.serviceTitle {
  color: #fee440;
}
.bosco {
  color: #fee440;
  font-size: 50px;
  font-weight: 900;
}
.mobileListItem {
  background: #9b5de5;
  margin-top: 1px;
}
.boscomobileListItem {
  text-align: center;
  background: #9b5de5;
  margin: 0 auto;
}
.mobileMenuListTitle {
  font-size: 18px;
  color: #fee440;
  font-weight: 600;
}
.boscoAgaratMobile {
  margin: 0 auto;
  color: #000;
  font-size: 30px;
  font-weight: 900;
}
.boscoBo {
  color: #fee440;
}
.boscoAgaratMobilePara {
  color: #fff;
  font-size: 14px;
}
.goToTop {
  color: #9b5de5 !important;
  opacity: 0.7;
}
/* Media Queries */
/* most mobiles has width about 400px */
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  .bosco {
    font-size: 30px;
    font-weight: 900;
    margin-top: 5px;
  }
  .navBarIcon {
    margin-top: 5px;
    width: 40px;
    height: 40px;
  }
  .socialIcon {
    margin-top: 7px;
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 800px) {
  /* any device has 800px and less  "Ipad"*/
}
</style>
