import axios from "axios";
const ROOT_URL = "/api";
export default {
  ////////////// Fech Users Info
  fetch_users() {
    return axios.get(`${ROOT_URL}/users/get`);
  },
  ////////////// Fech Villas Info
  fetch_villas() {
    return axios.get(`${ROOT_URL}/villas/get`);
  },
  ////////////// Fech students house Info
  fetch_students_house() {
    return axios.get(`${ROOT_URL}/students_house/get`);
  },
  ////////////// Fech shops Info
  fetch_shops() {
    return axios.get(`${ROOT_URL}/shops/get`);
  },
  ////////////// Fech offices Info
  fetch_offices() {
    return axios.get(`${ROOT_URL}/offices/get`);
  },
  ////////////// Fech offices Info
  fetch_lands() {
    return axios.get(`${ROOT_URL}/lands/get`);
  },
  ////////////// Fech houses Info
  fetch_houses() {
    return axios.get(`${ROOT_URL}/houses/get`);
  },
  ////////////// Fech buildings Info
  fetch_buildings() {
    return axios.get(`${ROOT_URL}/buildings/get`);
  },
  ////////////// Fech apartment for sale Info
  fetch_apartments_for_sale() {
    return axios.get(`${ROOT_URL}/apartments_for_sale/get`);
  },
  ////////////// Fech apartment for rent Info
  fetch_apartments_for_rent() {
    return axios.get(`${ROOT_URL}/apartments_for_rent/get`);
  },
  ////////////// Fech apartment for rent Info
  fetch_agricultural_fields() {
    return axios.get(`${ROOT_URL}/agriculturals/get`);
  },
  ////////////// Fech rooms Info
  fetch_rooms() {
    return axios.get(`${ROOT_URL}/rooms/get`);
  },
  ////////////// Fech balconies Info
  fetch_balconies() {
    return axios.get(`${ROOT_URL}/balconies/get`);
  },
  ////////////// Fech floors Info
  fetch_floors() {
    return axios.get(`${ROOT_URL}/floors/get`);
  },
  ////////////// Fech bathrooms Info
  fetch_bathrooms() {
    return axios.get(`${ROOT_URL}/bathrooms/get`);
  },
  ////////////// Fech advanced_payments Info
  fetch_advanced_payments() {
    return axios.get(`${ROOT_URL}/advanced_payments/get`);
  },
  ////////////// Fech furnitures Info
  fetch_furnitures() {
    return axios.get(`${ROOT_URL}/furnitures/get`);
  },
  ////////////// Fech kitchens Info
  fetch_kitchens() {
    return axios.get(`${ROOT_URL}/kitchens/get`);
  },
  ////////////// Fech walls Info
  fetch_walls() {
    return axios.get(`${ROOT_URL}/walls/get`);
  },
  ////////////// Fech waters Info
  fetch_waters() {
    return axios.get(`${ROOT_URL}/waters/get`);
  },
  ////////////// Fech halls Info
  fetch_halls() {
    return axios.get(`${ROOT_URL}/halls/get`);
  },
  ////////////// Fech finishings Info
  fetch_finishings() {
    return axios.get(`${ROOT_URL}/finishings/get`);
  },
  ////////////// Fech guards Info
  fetch_guards() {
    return axios.get(`${ROOT_URL}/guards/get`);
  },
  ////////////// Fech guards Info
  fetch_powers() {
    return axios.get(`${ROOT_URL}/powers/get`);
  },
  ////////////// Fech guards Info
  fetch_cities() {
    return axios.get(`${ROOT_URL}/cities/get`);
  },
  ////////////// Fech generals Info
  fetch_generals() {
    return axios.get(`${ROOT_URL}/generals/get`);
  },
  ////////////// Fech elevators Info
  fetch_elevators() {
    return axios.get(`${ROOT_URL}/elevators/get`);
  },
  ////////////// Fech parkings Info
  fetch_parkings() {
    return axios.get(`${ROOT_URL}/parkings/get`);
  },
  ////////////// Fech air conditions Info
  fetch_air_conditions() {
    return axios.get(`${ROOT_URL}/air_conditions/get`);
  },
  ////////////// Fech air conditions Info
  fetch_advertisments() {
    return axios.get(`${ROOT_URL}/advertisments/get`);
  },
  ////////////// Fech air sketches Info
  fetch_sketches() {
    return axios.get(`${ROOT_URL}/sketches/get`);
  },
  ////////////// Fech ownerships Info
  fetch_ownerships() {
    return axios.get(`${ROOT_URL}/ownerships/get`);
  },
  ////////////// Fech measurements Info
  fetch_measurements() {
    return axios.get(`${ROOT_URL}/measurements/get`);
  },
  ////////////// Fech currencies Info
  fetch_currencies() {
    return axios.get(`${ROOT_URL}/currencies/get`);
  },
  ////////////// Fech bosco bouquets Info
  fetch_bosco_bouquets() {
    return axios.get(`${ROOT_URL}/bosco_bouquets/get`);
  },
  ////////////// Fech bosco customer roles Info
  fetch_bosco_customer_roles() {
    return axios.get(`${ROOT_URL}/bosco_customer_roles/get`);
  },
};
