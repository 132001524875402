<template>
  <div id="create">
    <!-- Layout 1 -->
    <v-layout row wrap class="" mt-3>
      <v-spacer></v-spacer>
      <v-flex v-if="progressBar" xs12 md4 class="">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <!-- chat erro snackbar -->
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="snackbarFlex">
        <v-snackbar
          class="SnackBarAlert"
          shaped
          :timeout="3000"
          :top="true"
          color="red"
          :multi-line="multiLine"
          v-model="showAlert"
        >
          <span style="font-size: 14px; font-weight: 900">
            يا
            {{ this.bosco_logged_in_customer }} :
            {{ chat_error_message }}
          </span>
        </v-snackbar>
      </v-flex>
    </v-layout>
    <!-- Layout 2 -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="bouquet_empty_dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title
              style="background: #9b5de5; color: #fff"
              class="text-h5 lighten-2"
            >
            بوسكو للتسويق العقاري 
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2">
              يا مكتب {{ bosco_logged_in_customer }} الرصيد انتهي.
            </v-card-text>
            <v-card-text class="text-h6 mt-4 lighten-2">
              اتواصل معانا وجدد الباقة.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/boAdmin">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Layout 3 -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title class="text-h5 grey lighten-2">
              بوسكو للتسويق العقاري 
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2">
              تم الإعلان عن الدكان
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/shops">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Layout 3 -->
    <v-layout row wrap class="">
      <v-flex xs12 md6 class="addReportTitleFlex">
        <h3 class="addReportTitle">الإعلان عن دكان:</h3>
      </v-flex>
    </v-layout>
    <form @submit.prevent="onSubmit" enctype="multipart/form-data">
      <div>
        <!-- Layout 4 -->
        <v-layout row wrap class="">
          <v-flex xs12 md6 class="dropper">
            <input
              class="imageInput"
              name="image"
              type="file"
              ref="file"
              @change="onSelect"
            />
          </v-flex>
        </v-layout>
        <!-- Layout 4 -->
        <v-layout row wrap class="">
         <!-- shop owner phone number input -->
          <v-flex xs12 md3 class="customer_phone_number allInputsflex">
            <v-text-field
              label="رقم التلفون"
              outlined
              v-model="shop_owner_phone_number"
              v-bind:rules="PhoneRules"
            >
            </v-text-field>
          </v-flex>
          <!-- shop owner whatsApp number input -->
          <v-flex xs12 md3 class="allInputsflex">
            <v-text-field
              label="رقم الواتساب"
              outlined
              v-model="shop_owner_whatsApp_number"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
        <!-- Layout 4 -->
        <v-layout row wrap class="">
         <!-- shop price input -->
          <v-flex xs12 md3 class="allInputsflex">
            <v-text-field
              label="السعر"
              outlined
              v-model="shop_price"
              v-bind:rules="PriceRules"
            >
            </v-text-field>
          </v-flex>
          <!-- currency type title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">نوع العملة</span>
            <select v-model="currency_type_title" id="currency">
              <option
                :value="currency.currency_type_title"
                id="currencyOptions"
                v-for="currency in allCurrencies"
                :key="currency._id"
              >
                {{ currency.currency_type_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <!-- Layout 5 -->
        <v-layout row wrap class="">
          <!-- city title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">المدينة</span>
            <select v-model="city_title" id="cities">
              <option
                :value="city.city_title"
                id="cityOptions"
                v-for="city in allCities"
                :key="city._id"
              >
                {{ city.city_title }}
              </option>
            </select>
          </v-flex>
          <!-- shop neighborhood input-->
          <v-flex xs12 md3 class="allInputsflex">
            <v-text-field
              label="المنطقة/الحي"
              outlined
              v-model="shop_neighborhood"
              v-bind:rules="NeighborhoodRules"
            >
            </v-text-field>
          </v-flex>
          <!-- shop address input-->
          <v-flex xs12 md3 class="allInputsflex">
            <v-text-field
              label="العنوان"
              outlined
              v-model="shop_address"
              v-bind:rules="AddressRules"
            >
            </v-text-field>
          </v-flex>
          <!-- advertisment title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">إعلان شنو</span>
            <select v-model="advertisment_title" id="advertisment">
              <option
                :value="advertisment.advertisment_title"
                id="advertismentOptions"
                v-for="advertisment in allAdvertisments"
                :key="advertisment._id"
              >
                {{ advertisment.advertisment_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="">
          <v-flex xs12 md3 class="allInputsflex">
            <v-text-field
              label="مساحة الدكان"
              outlined
              v-model="shop_area"
            >
            </v-text-field>
          </v-flex>
          <!-- measurement title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">وحدة القياس</span>
            <select v-model="measurement_title" id="measurement">
              <option
                :value="measurement.measurement_title"
                id="measurementOptions"
                v-for="measurement in allMeasurements"
                :key="measurement._id"
              >
                {{ measurement.measurement_title }}
              </option>
            </select>
          </v-flex>
          <!-- sketch title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">الكروكي</span>
            <select v-model="sketch_title" id="sketch">
              <option
                :value="sketch.sketch_title"
                id="sketchOptions"
                v-for="sketch in allSketches"
                :key="sketch._id"
              >
                {{ sketch.sketch_title }}
              </option>
            </select>
          </v-flex>
          <!-- bathroom title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan"> حمام</span>
            <select v-model="bathroom_title" id="bathroom">
              <option
                :value="bathroom.bathroom_title"
                id="bathroomOptions"
                v-for="bathroom in allBathrooms"
                :key="bathroom._id"
              >
                {{ bathroom.bathroom_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="">
          <!-- advanced payment title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan"> مقدم</span>
            <select v-model="advanced_payment_title" id="advancedPayment">
              <option
                :value="advancedPayment.advanced_payment_title"
                id="bathroomOptions"
                v-for="advancedPayment in allAdvancedPayments"
                :key="advancedPayment._id"
              >
                {{ advancedPayment.advanced_payment_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="">
          <v-flex xs12 md6 class="allInputsflex">
            <v-textarea
              label=" مميزات الدكان والشروط..."
              auto-grow
              outlined
              rows="12"
              row-height="10"
              v-model="shop_notes"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <!-- Layout 6 -->
        <v-layout row wrap class="createReportBtnLayout">
          <v-flex xs12 md4 class="createReportBtnFlex">
            <button class="createReportBtn" elevation="0">نشر</button>
            <router-link to="/shops">
              <button class="cancelBtn">الغاء</button>
            </router-link>
          </v-flex>
        </v-layout>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
const ROOT_URL = "/api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "createShop",
  data: () => ({
    progressBar: false,
    dialog: false,
    ////////////////////
    showAlert: false,
    multiLine: true,
    chat_error_message: "يجب ادخال جميع البيانات",
    ////////////////////
    NeighborhoodRules: [(v) => !!v || "اسم الحي/المنطقة مطلوب"],
    AddressRules: [(v) => !!v || "العنوان مطلوب"],
    PhoneRules: [(v) => !!v || "رقم التلفون مطلوب"],
    PriceRules: [(v) => !!v || "السعر مطلوب"],
    ////////////////////
    bouquet_empty_dialog: false,

    //////////////////
    file: "",
    //////////////////
    shop_owner_phone_number: "",
    shop_owner_whatsApp_number: "",
    shop_price: "",
    currency_type_title: "",
    city_title: "",
    shop_neighborhood: "",
    shop_address: "",
    shop_area: "",
    measurement_title: "",
    sketch_title: "",
    bathroom_title: "",
    advanced_payment_title: "",
    advertisment_title: "",
    shop_notes: "",
  }),
  computed: {
    ////////////////////////////////////////////////
    total_published_customer_adds: function () {
      let total = Number(
        this.allAgriculturalFieldsPostsByCustomer.length +
          this.allApartmentsForRentByCustomer.length +
          this.allApartmentsForSaleByCustomer.length +
          this.allBuildingsByCustomer.length +
          this.allHousesByCustomer.length +
          this.allLandsByCustomer.length +
          this.allOfficesByCustomer.length +
          this.allShopsByCustomer.length +
          this.allStudentsHouseByCustomer.length +
          this.allVillasByCustomer.length
      );
      return total;
    },
    //////////////////////////////
    bosco_customer_email: function () {
      return this.get_bosco_customer.customer_email;
    },
    /////Logged In Customer
    bosco_logged_in_customer: function () {
      return this.loggedInUser;
    },
    ///////////////mapGetters
    ...mapGetters([
      "loggedInUser",
      "get_bosco_customer",
      ////Feach Number of posts by Customer///////
      "allAgriculturalFieldsPostsByCustomer",
      "allApartmentsForRentByCustomer",
      "allApartmentsForSaleByCustomer",
      "allBuildingsByCustomer",
      "allHousesByCustomer",
      "allLandsByCustomer",
      "allOfficesByCustomer",
      "allShopsByCustomer",
      "allStudentsHouseByCustomer",
      "allVillasByCustomer",
      //////////
      "allAdvertisments",
      "allCities",
      "allBathrooms",
      "allSketches",
      "allAdvancedPayments",
      "allMeasurements",
      "allCurrencies",
    ]),
        isLoggedIn() {
          return this.$store.getters.isLoggedIn;
        },
  },
    mounted: function () {
      if (!this.isLoggedIn) {
        this.$router.push({ path: "/" });
      }
    },
  created() {
    this.fetchCities();
    this.fetchAdvertisments();
    this.fetchBathrooms();
    this.fetchSketches();
    this.fetchAdvancedPayments();
    this.fetchMeasurements();
    this.fetchCurrencies();
    ////Feach Number of posts by Customer///////
    this.fetchAgriculturalFields();
    this.fetchApartmentsForRent();
    this.fetchApartmentsForSale();
    this.fetchBuildings();
    this.fetchHouses();
    this.fetchLands();
    this.fetchOffices();
    this.fetchShops();
    this.fetchStudentsHouse();
    this.fetchVillas();
  },
  methods: {
    ...mapActions([
      "fetchCities",
      "fetchAdvertisments",
      "fetchBathrooms",
      "fetchSketches",
      "fetchAdvancedPayments",
      "fetchMeasurements",
      "fetchCurrencies",
      ////Feach Number of posts by Customer///////
      "fetchAgriculturalFields",
      "fetchApartmentsForRent",
      "fetchApartmentsForSale",
      "fetchBuildings",
      "fetchHouses",
      "fetchLands",
      "fetchOffices",
      "fetchShops",
      "fetchStudentsHouse",
      "fetchVillas",
    ]),
    onSelect() {
      const file = this.$refs.file.files[0];
      this.file = file;
    },
    async onSubmit() {
      if (
        this.file === "" ||
        this.bosco_logged_in_customer === "" ||
        this.bosco_customer_email === "" ||
        this.shop_price === "" ||
        this.shop_owner_phone_number === "" ||
        this.shop_address === "" ||
        this.city_title === "" ||
        this.measurement_title === "" ||
        this.currency_type_title === ""
      ) {
        return (this.showAlert = true);
      } else {
        const fd = new FormData();
        fd.append("image", this.file);
        ///////////////////////////////
        fd.append("bosco_logged_in_customer", this.bosco_logged_in_customer);
        fd.append("bosco_customer_email", this.bosco_customer_email);
        ///////////////////////////////
        fd.append("shop_owner_phone_number", this.shop_owner_phone_number);
        fd.append(
          "shop_owner_whatsApp_number",
          this.shop_owner_whatsApp_number
        );
        fd.append("shop_price", this.shop_price);
        fd.append("currency_type_title", this.currency_type_title);
        fd.append("city_title", this.city_title);
        fd.append("shop_neighborhood", this.shop_neighborhood);
        fd.append("shop_address", this.shop_address);
        fd.append("shop_area", this.shop_area);
        fd.append("measurement_title", this.measurement_title);
        fd.append("sketch_title", this.sketch_title);
        fd.append("bathroom_title", this.bathroom_title);
        fd.append("advanced_payment_title", this.advanced_payment_title);
        fd.append("advertisment_title", this.advertisment_title);
        fd.append("shop_notes", this.shop_notes);
        ///Calculate bosco customer bouquet remain days
        let date_1 = new Date(
          this.get_bosco_customer.bosco_bouquet_expiration_day
        );
        let date_2 = new Date();
        let difference = date_1.getTime() - date_2.getTime();
        let customer_bouquet_remain_days = difference;

        if (
          Number(
            this.get_bosco_customer.bosco_bouquet_number_of_posts -
              this.total_published_customer_adds
          ) <= 0 ||
          customer_bouquet_remain_days <= 0
        ) {
          //////Show dialog
          this.bouquet_empty_dialog = true;
        } else {
          //////Show progressBar
          this.progressBar = true;
          try {
            await axios.post(`${ROOT_URL}/shops/create`, fd);
            //////Hide progressBar and show dialog
            this.progressBar = false;
            this.dialog = true;
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
#create {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.addReportTitleFlex {
  margin-bottom: 30px;
}
.addReportTitle {
  font-size: 18px;
  color: #9b5de5;
  padding: 10px;
}

.createReportBtn {
  width: 80px;
  padding: 10px;
  margin-left: 30px;
  margin-bottom: 60px;
  background: #9b5de5 !important;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  border: 1px solid #9b5de5;
  border-radius: 2px;
}
.cancelBtn {
  width: 80px;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  background: #f15bb5;
  border: 1px solid #9b5de5;
  border-radius: 2px;
}
.createReportBtn:hover {
  color: #9b5de5;
  background: #fff !important;
  transition: color 0.8s;
}

.dialog_card {
  border: 1px solid #9b5de5;
}
.dialog_btn {
  border: 1px solid #9b5de5;
  color: #9b5de5;
}
.allInputsflex {
    margin-right: 15px;
  }
.dropper {
  margin-right: 15px;
  margin-bottom: 20px;
  margin-top: 5px;
  background: #00f5d4;
  height: 10vh;
  border: 10px dashed #9b5de5;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropper:hover {
  background: #eee;
}
.imageInput {
  color: #9b5de5 !important;
}
select {
  margin-bottom: 30px;
  margin-right: 15px;
  padding: 5px;
  border: 1px solid rgb(191, 186, 208);
  border-radius: 4px;
  width: 90%;
}
.selectSpan {
  margin-right: 15px;
}
.createReportBtnFlex{
    text-align: center;
  }
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #create {
    padding: 18px;
    margin-top: 15px;
  }
  .allInputsflex {
    margin-right: 0px;
  }
  select {
    margin-right: 0px;
  }
  .dropper {
    border: 5px dashed #9b5de5;
    margin: 0 auto;
  }
  .customer_phone_number {
    margin-top: 15px;
  }
  .createReportBtnLayout {
    margin-bottom: 100px;
  }
}
</style>
