<template>
  <v-container id="create">
    <!-- Layout 1 -->
    <v-layout row wrap class="" mt-3>
      <v-spacer></v-spacer>
      <v-flex v-if="progressBar" xs12 md4 class="">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title class="text-h5 grey lighten-2">
              بوسكو للتسويق العقاري 
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2">
              تم تعديل بيانات المكتب
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/boscoUsers">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Layout 1 -->
    <div>
      <v-layout row wrap class="">
        <v-flex xs12 md12>
          <p class="countToolBar">
            تعديل بيانات المكتب 
          </p>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="" elevation="0">
        <v-flex xs12 md3 class="customer_name">
          <v-text-field
            outlined
            v-model="edit_user.customer_name"
            label=" اسم العميل"
            required
            class="mainInput"
            type="name"
            v-bind:rules="customer_name_rules"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            outlined
            v-model="edit_user.customer_national_id_card_number"
            label="الرقم الوطني"
            required
            class="mainInput"
            type="text"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            outlined
            v-model="edit_user.customer_phone_number"
            label=" رقم الهاتف"
            required
            class="mainInput"
            type="text"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            outlined
            v-model="edit_user.customer_whatsApp_number"
            label="رقم الواتساب"
            required
            class="mainInput"
            type="text"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="" elevation="0">
        <!-- bosoc bouquet type dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan">نوع الباقة</span>
          <select v-model="edit_user.bosco_bouquet_title" id="bouquet">
            <option
              :value="bouquet.bosco_bouquet_title"
              id="bouquetOptions"
              v-for="bouquet in allBoscoBouquets"
              :key="bouquet._id"
            >
              {{ bouquet.bosco_bouquet_title }}
            </option>
          </select>
        </v-flex>
        <!-- Number Of Posts -->
        <v-flex xs12 md3 class="">
          <v-text-field
            outlined
            v-model="edit_user.bosco_bouquet_number_of_posts"
            label="عدد البوستات في الباقة"
            required
            class="mainInput"
            type="text"
          ></v-text-field>
        </v-flex>
        <!-- bosoc bouquet type dropdown menu -->
        <v-flex xs12 md3 class="">
          <span class="selectSpan">نوع المستخدم</span>
          <select v-model="edit_user.bosco_customer_role_title" id="role">
            <option
              :value="role.bosco_customer_role_title"
              id="roleOptions"
              v-for="role in allCustomerRolesRoles"
              :key="role._id"
            >
              {{ role.bosco_customer_role_title }}
            </option>
          </select>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            outlined
            v-model="edit_user.customer_email"
            label="الايميل"
            required
            class="mainInput"
            type="email"
            v-bind:rules="emailRules"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <!-- Layout -->
      <v-layout row wrap class="" elevation="0">
        <v-flex xs12 md3>
          <v-text-field
            outlined
            autocomplete="off"
            v-model="edit_user.customer_password"
            label=" الباسوورد"
            required
            class="mainInput"
            v-bind:rules="passwordRules"
            v-bind:type="'password'"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            outlined
            autocomplete="off"
            v-model="edit_user.customer_city"
            label="المدينة"
            required
            class="mainInput"
            type="text"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            outlined
            v-model="edit_user.customer_neighborhood"
            label="الحي"
            required
            class="mainInput"
            type="text"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            outlined
            v-model="edit_user.customer_address"
            label="العنوان"
            required
            class="mainInput"
            type="text"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="" elevation="0">
        <v-flex xs12 md3>
          <v-text-field
            outlined
            v-model="edit_user.customer_occupation"
            label="المهنة"
            required
            class="mainInput"
            type="text"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            outlined
            v-model="edit_user.customer_birthday"
            label="تاريخ الميلاد"
            required
            class="mainInput"
            type="date"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3 class="">
          <v-text-field
            outlined
            v-model="edit_user.bosco_bouquet_expiration_day"
            label="تاريخ انتهاء الباقة"
            required
            class="mainInput"
            type="date"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            outlined
            v-model="edit_user.customer_work_area"
            label="مكان العمل"
            required
            class="mainInput"
            type="text"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="">
        <v-flex xs12 md6>
          <v-textarea
            label="  ملاحظات حول العميل ..."
            auto-grow
            outlined
            rows="10"
            row-height="10"
            v-model="edit_user.customer_notes"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="createReportBtnLayout">
        <v-flex xs12 md4 class="createReportBtnFlex">
          <button class="createReportBtn" @click="updateUser" elevation="0">
            نشر
          </button>
          <router-link to="/boscoUsers">
            <button class="cancelBtn">الغاء</button>
          </router-link>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
const ROOT_URL = "/api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CreateUser",
  data() {
    return {
      progressBar: false,
      dialog: false,
      //////////////////
      customer_name_rules: [(v) => !!v || "الاسم مطلوب"],
      emailRules: [
        (v) => !!v || "الايميل مطلوب",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "الايميل مطلوب",
      ],
      password: "",
      passwordRules: [(v) => !!v || "الباسوورد مطلوب"],

      edit_user: {
        customer_name: "",
        customer_password: "",
        customer_email: "",
        customer_phone_number: "",
        customer_whatsApp_number: "",
        bosco_bouquet_title: "",
        bosco_customer_role_title: "",
        bosco_bouquet_number_of_posts: "",
        customer_city: "",
        customer_neighborhood: "",
        customer_address: "",
        customer_occupation: "",
        customer_birthday: "",
        bosco_bouquet_expiration_day: "",
        customer_national_id_card_number: "",
        customer_work_area: "",
        customer_notes: "",
      },
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    ///////////////mapGetters
    ...mapGetters(["allBoscoBouquets", "allCustomerRolesRoles"]),
  },
  created() {
    this.fetchBoscoBouquets();
    this.fetchBoscoCustomerRoles();
    ///////////////////////////////
    let url = `${ROOT_URL}/users/get/${this.$route.params.id}`;
    axios
      .get(url)
      .then((response) => {
        this.edit_user = response.data.user;
      })
      .catch((error) => {
        error;
      });
  },
    mounted: function() {
    if (!this.isLoggedIn) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    updateUser() {
      const user = {
        customer_name: this.edit_user.customer_name,
        customer_password: this.edit_user.customer_password,
        customer_email: this.edit_user.customer_email,
        customer_phone_number: this.edit_user.customer_phone_number,
        customer_whatsApp_number: this.edit_user.customer_whatsApp_number,
        bosco_bouquet_title: this.edit_user.bosco_bouquet_title,
        bosco_customer_role_title: this.edit_user.bosco_customer_role_title,
        bosco_bouquet_number_of_posts:
          this.edit_user.bosco_bouquet_number_of_posts,
        customer_city: this.edit_user.customer_city,
        customer_neighborhood: this.edit_user.customer_neighborhood,
        customer_address: this.edit_user.customer_address,
        customer_occupation: this.edit_user.customer_occupation,
        customer_birthday: this.edit_user.customer_birthday,
        bosco_bouquet_expiration_day:
          this.edit_user.bosco_bouquet_expiration_day,
        customer_national_id_card_number:
          this.edit_user.customer_national_id_card_number,
        customer_work_area: this.edit_user.customer_work_area,
        customer_notes: this.edit_user.customer_notes,
      };

      this.progressBar = true;
      axios
        .patch(`${ROOT_URL}/users/update/${this.$route.params.id}`, user)
        .then(() => {
          this.progressBar = false;
          this.dialog = true;
        })
        .catch((error) => {
          console.log("User Updating Error: " + " " + error);
          error;
        });
    },
    cancelUpdate() {
      this.$router.push({ path: "/users" });
    },
    ...mapActions(["fetchBoscoBouquets", "fetchBoscoCustomerRoles"]),
  },
};
</script>

<style scoped>
#create {
  padding: 20px;
  margin-top: 50px;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
}
.countToolBar {
  background: #fff !important;
  color: #9b5de5 !important;
  font-size: 18px;
  font-weight: 500;
}
.mainInput {
  margin-left: 5px;
}
.createReportBtn {
  width: 80px;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  background: #9b5de5 !important;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  border: 1px solid #9b5de5;
  border-radius: 2px;
}
.cancelBtn {
  width: 80px;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  border: 1px solid #9b5de5;
  border-radius: 2px;
  background: #f15bb5;
}
.createReportBtn:hover {
  color: #9b5de5;
  background: #fff !important;
  transition: color 0.8s;
}
.cancleBtn {
  color: #9b5de5;
  border: 1px solid #9b5de5;
}
select {
  margin-bottom: 30px;
  margin-right: 15px;
  padding: 5px;
  border: 1px solid rgb(191, 186, 208);
  border-radius: 4px;
  width: 90%;
}
.selectSpan {
  margin-right: 15px;
}

.dialog_card {
  border: 1px solid #9b5de5;
}
.dialog_btn {
  color: #9b5de5;
  border: 1px solid #9b5de5;
}
.createReportBtnFlex{
    text-align: center;
  }
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #create {
    margin-top: 10px;
}
  .customer_name {
    margin-top: 10px;
  }
  .createReportBtnLayout{
  margin-bottom: 60px;
}
}
</style>
