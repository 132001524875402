import apiServices from "../../api/apiServices";

/////////////// State /////////////////////
const state = {
      apartmentsForRent: [],
      totalApartmentsForRentByCustomer: [],

};
/////////////// Getters /////////////////////
const getters = {
  allApartmentsForRent: (state) => state.apartmentsForRent.reverse(),
  allApartmentsForRentByCustomer: (state) =>
    state.totalApartmentsForRentByCustomer.reverse(),
};

/////////////// Actions /////////////////////
const actions = {
  async fetchApartmentsForRent({ commit, rootGetters }) {
    const response = await apiServices.fetch_apartments_for_rent();
       //filter apartments for sale posts by customer
       let total_apartment_for_rent_posts =
       response.data.apartments_for_rent.filter((items) => {
         const matchPost = items.bosco_customer_email.includes(
           rootGetters.get_bosco_customer.customer_email
         );
         return matchPost;
       });

    commit("totalApartmentsForRentByCustomer", total_apartment_for_rent_posts);
    commit("setApartmentsForRent", response.data.apartments_for_rent);
  },
};

/////////////// MUTATIONS /////////////////////
const mutations = {
      setApartmentsForRent: (state, apartmentsForRent) => {
      state.apartmentsForRent = apartmentsForRent;
  },
  totalApartmentsForRentByCustomer: (state, totalApartmentsForRentByCustomer) => {
    state.totalApartmentsForRentByCustomer =
    totalApartmentsForRentByCustomer;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
