import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
  currencies: [],
};
/////////////// Getters
const getters = {
  allCurrencies: (state) => state.currencies
};
/////////////// Actions
const actions = {
  async fetchCurrencies({ commit }) {
    const response = await apiServices.fetch_currencies();
    commit("setCurrencies", response.data.currencies);
  }
};
/////////////// MUTATIONS /////////////////////
const mutations = {
  setCurrencies: (state, currencies) => {
      state.currencies = currencies;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
