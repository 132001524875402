import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      measurements: [],
};
/////////////// Getters
const getters = {
  allMeasurements: (state) => state.measurements
};
/////////////// Actions
const actions = {
  async fetchMeasurements({ commit }) {
    const response = await apiServices.fetch_measurements();
    commit("setMeasurements", response.data.measurements);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setMeasurements: (state, measurements) => {
      state.measurements = measurements;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
