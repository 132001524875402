import { render, staticRenderFns } from "./CreateBathroom.vue?vue&type=template&id=d2979eec&scoped=true&"
import script from "./CreateBathroom.vue?vue&type=script&lang=js&"
export * from "./CreateBathroom.vue?vue&type=script&lang=js&"
import style0 from "./CreateBathroom.vue?vue&type=style&index=0&id=d2979eec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2979eec",
  null
  
)

export default component.exports