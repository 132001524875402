import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      rooms: [],
};
/////////////// Getters
const getters = {
  allRooms: (state) => state.rooms
};
/////////////// Actions
const actions = {
  async fetchRooms({ commit }) {
    const response = await apiServices.fetch_rooms();
    commit("setRooms", response.data.rooms);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setRooms: (state, rooms) => {
      state.rooms = rooms;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
