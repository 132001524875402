import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      generals: [],
};
/////////////// Getters
const getters = {
  allGenerals: (state) => state.generals
};
/////////////// Actions
const actions = {
  async fetchGenerals({ commit }) {
    const response = await apiServices.fetch_generals();
    commit("setGenerals", response.data.generals);
  },
};
/////////////// MUTATIONS 
const mutations = {
      setGenerals: (state, generals) => {
      state.generals = generals;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
