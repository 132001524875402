import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      advertisments: [],
};
/////////////// Getters
const getters = {
  allAdvertisments: (state) => state.advertisments
};
/////////////// Actions
const actions = {
  async fetchAdvertisments({ commit }) {
    const response = await apiServices.fetch_advertisments();
    commit("setAdvertisments", response.data.advertisments);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setAdvertisments: (state, advertisments) => {
      state.advertisments = advertisments;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
