<template>
  <v-app>
    <app-header />
    <v-main class="main_content">
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import AppHeader from "./components/AppHeader";

export default {
  name: "App",
  components: {
    AppHeader,
  },
  data: () => ({
    //
  }),
};
</script>
<style>
#app {
  font-family: "Almarai", sans-serif, Verdana;

  background: #ebf2f5;
  letter-spacing: 1px;
}
a {
  text-decoration: none;
}

.main_content{
  width: 90%;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  .main_content{
   width: 100%;

}
}
</style>
