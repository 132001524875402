<template>
  <v-container id="culture">
    <v-layout row wrap>
      <v-flex xs12 md10 class="">
        <h3 class="employeeTableTitle">أحدث عروض العمارات</h3>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <v-toolbar elevation="0" class="customerTableToolBar">
          <v-text-field
            flat
            hide-details
            v-model="search"
            class="search"
            label="فتش بالمدينة،الحي،السعر،ايجار،بيع...الخ"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
          >
          </v-text-field>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="lastCard">
      <v-flex
        xs12
        md3
        class="pl-2 pr-2 text-xs-center mt-2"
        v-for="buildingInfo in search_buildings"
        :key="buildingInfo._id"
      >
        <router-link v-bind:to="'/buildingInfo/' + buildingInfo._id">
          <v-hover v-slot:default="{ hover }">
            <div :elevation="hover ? 16 : 2">
              <v-card
                class="mx-auto postCard"
                max-width="1000"
        
                :elevation="hover ? 16 : 2"
              >
                <v-img 
                :src="`data:image/png;base64, ${buildingInfo.image}`"
                 height="200" class=""></v-img>
                <v-card-text class="text--primary">
                  <h3 class="mainTitle">
                    {{ buildingInfo.city_title.slice(0, 20) }} -
                    {{ buildingInfo.building_neighborhood.slice(0, 15) }}
                  </h3>
                  <h4 class="mb-4">
                    <span style="opacity: 0.7">
                      {{ buildingInfo.building_address }}
                    </span>
                  </h4>
                  <h4>
                    <span style="font-size: 20px; margin-right: 10px;
                        color: #f15bb5;
                        font-weight: 900;">
                      {{ buildingInfo.building_price}}  
                      {{buildingInfo.currency_type_title}}

                    </span>
                  </h4>
                  <h5 class="mt-4">
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 20px; height: 20px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M15 6H22V9H18V13H14V17H10V21H3V18H7V14H11V10H15V6M10.17 6.66L4.66 12.17L2.83 10.34L8.34 4.83L6.5 3H12V8.5L10.17 6.66Z"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      الطابق: {{ buildingInfo.floor_title }}
                    </span> 
                    <span style="margin-left: 5px">
                    <svg style="width: 15px; height: 15px" viewBox="0 0 24 24">
                      <path
                        fill="#00bbf9"
                        d="M20 2H4C2.9 2 2 2.9 2 4V20C2 21.11 2.9 22 4 22H20C21.11 22 22 21.11 22 20V4C22 2.9 21.11 2 20 2M4 6L6 4H10.9L4 10.9V6M4 13.7L13.7 4H18.6L4 18.6V13.7M20 18L18 20H13.1L20 13.1V18M20 10.3L10.3 20H5.4L20 5.4V10.3Z"
                      />
                    </svg>
                  </span>
                    <span style="margin-left: 10px">
                      المساحة:  
                      {{ buildingInfo.building_area }}  
                      {{ buildingInfo.measurement_title }} 
                    </span>
                    <span style="margin-left: 5px">
                      <svg
                        style="width: 15px; height: 15px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M12,3L2,12H5V20H19V12H22M9,18H7V12H9M13,18H11V10H13M17,18H15V14H17"
                        />
                      </svg>
                    </span>
                    <span style="margin-left: 10px">
                      {{ buildingInfo.advertisment_title }}
                    </span>
                  </h5>
                  <h5 class="date">
                    <span class="svgItem">
                      <svg
                        style="width: 18px; height: 18px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00bbf9"
                          d="M16 14H17.5V16.8L19.9 18.2L19.1 19.5L16 17.7V14M21 11.3C20.8 7.9 17 7.2 13.3 9.2C13 8.8 12.6 8.5 12.1 8.3C12.3 7.4 12.7 6.3 13.7 5.8C16.1 4.6 16 1 11.5 1C7.9 1 7.2 5 9.1 8.7C8.7 9 8.4 9.4 8.2 9.9C7.3 9.7 6.2 9.3 5.7 8.3C4.6 5.9 1 6 1 10.5C1 14.1 5 14.8 8.7 12.9C9 13.3 9.5 13.6 9.9 13.8C9.7 14.7 9.3 15.8 8.3 16.3C5.9 17.4 6 21 10.5 21C10.8 21 11 21 11.2 20.9C12.5 22.8 14.6 24 17 24C20.9 24 24 20.9 24 17C24 14.6 22.8 12.5 21 11.3M11 10C11.6 10 12 10.4 12 11S11.6 12 11 12 10 11.6 10 11 10.4 10 11 10M17 22C14.2 22 12 19.8 12 17S14.2 12 17 12 22 14.2 22 17 19.8 22 17 22Z"
                        />
                      </svg>
                    </span>
                    {{ buildingInfo.created_At }}
                  </h5>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </router-link>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BoBuildings",
  data() {
    return {
      search: "",

    };
  },
  components: {},
  computed: {
    search_buildings: function () {
      return this.allBuildings.filter((item) => {
        return (
          item.bosco_logged_in_customer.includes(this.search) ||
          item.building_owner_phone_number.includes(this.search) ||
          item.building_owner_whatsApp_number.includes(this.search) ||
          item.building_price.includes(this.search) ||
          item.city_title.includes(this.search) ||
          item.building_neighborhood.includes(this.search) ||
          item.building_address.includes(this.search) ||
          item.building_area.includes(this.search) ||
          item.finishing_type_title.includes(this.search) ||
          item.floor_title.includes(this.search) ||
          item.power_title.includes(this.search) ||
          item.elevator_title.includes(this.search) ||
          item.advertisment_title.includes(this.search) ||
          item.advanced_payment_title.includes(this.search) ||
         // item.bosco_customer_email.includes(this.search) ||
          item.created_At.includes(this.search) ||
          item.measurement_title.includes(this.search) ||
          item.sketch_title.includes(this.search) ||
          item.currency_type_title.includes(this.search) ||
          item.building_notes.includes(this.search)
        );
      });
    },
    ...mapGetters(["allBuildings"]),
  },
  methods: {
    ...mapActions(["fetchBuildings"]),
  },
  created() {
    this.fetchBuildings();
  },
};
</script>

<style scoped>
#culture {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.mainTitle {
  color: #000;
}
.postCard{
  height: 400;
}
.cltureToolBar {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.7;
}
.employeeTableTitle {
  color: #f15bb5;
  font-weight: 600;
}
.date {
  font-size: 15px;
  font-weight: 500;
  opacity: 0.5;
}
.lastCard{
    margin-bottom: 140px;
  }
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #culture {
  padding: 8px;
  margin-top: 15px;

}
.employeeTableTitle {
  margin-right: 8px;
  padding-top: 10px;
}
}
@media screen and (max-width: 800px) {
  /* any device has 800px and less  "Ipad"*/
  .postCard{
  height: 430;
}
}
</style>
