import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      waters: [],
};
/////////////// Getters
const getters = {
  allWaters: (state) => state.waters
};
/////////////// Actions
const actions = {
  async fetchWaters({ commit }) {
    const response = await apiServices.fetch_waters();
    commit("setWaters", response.data.waters);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setWaters: (state, waters) => {
      state.waters = waters;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
