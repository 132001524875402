<template>
  <div id="create">
    <!-- Layout 1 -->
    <v-layout row wrap class="" mt-3>
      <v-spacer></v-spacer>
      <v-flex v-if="progressBar" xs12 md4 class="">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
        <!-- chat erro snackbar -->
        <v-layout row wrap class="">
      <v-flex xs12 md12 class="snackbarFlex">
        <v-snackbar
          class="SnackBarAlert"
          shaped
          :timeout="3000"
          :top="true"
          color="red"
          :multi-line="multiLine"
          v-model="showAlert"
        >
          <span style="font-size: 14px; font-weight: 900">
            يا
            {{ this.bosco_logged_in_customer }} :
            {{ chat_error_message }}
          </span>
        </v-snackbar>
      </v-flex>
    </v-layout>
        <!-- Layout 2 -->
        <template>
      <v-row justify="center">
        <v-dialog v-model="bouquet_empty_dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title
              style="background: #9b5de5; color: #fff"
              class="text-h5 lighten-2"
            >
              بوسكو للتسويق العقاري
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2">
              يا مكتب {{ bosco_logged_in_customer }} الرصيد انتهي.
            </v-card-text>
            <v-card-text class="text-h6 mt-4 lighten-2">
              اتواصل معانا وجدد الباقة.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/boAdmin">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Layout 2 -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="400">
          <v-card class="dialog_card">
            <v-card-title class="text-h5 grey lighten-2">
              بوسكو للتسويق العقاري 
            </v-card-title>
            <v-card-text class="text-h6 mt-4 lighten-2">
              تم تعديل بيانات المزرعة 
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link to="/AgriculturalFields">
                <v-btn class="dialog_btn" text @click="dialog = false">
                  موافق
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Layout 3 -->
    <v-layout row wrap >
      <v-flex xs12 md12>
        <v-toolbar elevation="0">
          <v-toolbar-title class="countToolBar">
            تعديل بيانات المزرعة
          </v-toolbar-title>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <!-- Layout 4 -->
  <!-- Layout 4 -->
  <v-layout row wrap class="">
          <v-flex xs12 md3 class="customer_phone_number">
            <v-text-field
              style="margin-right: 15px"
              label="رقم التلفون"
              outlined
              v-model="edit_agricultural_field.agricultural_owner_phone_number"
              v-bind:rules="PhoneRules"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 md3 class="">
            <v-text-field
              style="margin-right: 15px"
              label="رقم الواتساب"
              outlined
              v-model="edit_agricultural_field.agricultural_owner_whatsApp_number"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 md3 class="">
            <v-text-field
              style="margin-right: 15px"
              label="السعر"
              outlined
              v-model="edit_agricultural_field.agricultural_price"
              v-bind:rules="PriceRules"
            >
            </v-text-field>
          </v-flex>
          <!-- this will be dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">نوع العملة</span>
            <select v-model="edit_agricultural_field.currency_type_title" id="currency">
              <option
                :value="currency.currency_type_title"
                id="currencyOptions"
                v-for="currency in allCurrencies"
                :key="currency._id"
              >
                {{ currency.currency_type_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <!-- Layout 5 -->
        <v-layout row wrap class="">
          <!-- city title dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">المدينة</span>
            <select v-model="edit_agricultural_field.city_title" id="cities">
              <option
                :value="city.city_title"
                id="cityOptions"
                v-for="city in allCities"
                :key="city._id"
              >
                {{ city.city_title }}
              </option>
            </select>
          </v-flex>
          <v-flex xs12 md3 class="">
            <v-text-field
              style="margin-right: 15px"
              label="العنوان"
              outlined
              v-model="edit_agricultural_field.agricultural_address"
              v-bind:rules="AddressRules"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 md3 class="">
            <v-text-field
              style="margin-right: 15px"
              label="مساحة المزرعة"
              outlined
              v-model="edit_agricultural_field.agricultural_area"
            >
            </v-text-field>
          </v-flex>
          <!-- this will be dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">وحدة القياس</span>
            <select v-model="edit_agricultural_field.measurement_title" id="measurement">
              <option
                :value="measurement.measurement_title"
                id="measurementOptions"
                v-for="measurement in allMeasurements"
                :key="measurement._id"
              >
                {{ measurement.measurement_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <!--Layout -->
        <v-layout row wrap class="">
          <!-- this will be dropdown menu -->
          <v-flex xs12 md3 class="">
            <span class="selectSpan">إعلان شنو</span>
            <select v-model="edit_agricultural_field.advertisment_title" id="advertisment">
              <option
                :value="advertisment.advertisment_title"
                id="advertismentOptions"
                v-for="advertisment in allAdvertisments"
                :key="advertisment._id"
              >
                {{ advertisment.advertisment_title }}
              </option>
            </select>
          </v-flex>
          <v-flex xs12 md3 class="">
            <!-- this will be select-->
            <span class="selectSpan">مصدر الماء</span>
            <select v-model="edit_agricultural_field.source_of_water_title" id="source_of_water_title">
              <option
                :value="water.source_of_water_title"
                id="waterOptions"
                v-for="water in allWaters"
                :key="water._id"
              >
                {{ water.source_of_water_title }}
              </option>
            </select>
          </v-flex>
          <v-flex xs12 md3 class="">
            <!-- this will be select-->
            <span class="selectSpan">مصدر الطاقة</span>
            <select v-model="edit_agricultural_field.power_title" id="power">
              <option
                :value="power.power_title"
                id="powerOptions"
                v-for="power in allPowers"
                :key="power._id"
              >
                {{ power.power_title }}
              </option>
            </select>
          </v-flex>
          <v-flex xs12 md3 class="">
            <!-- this will be select-->
            <span class="selectSpan">سور</span>
            <select v-model="edit_agricultural_field.wall_title" id="wall">
              <option
                :value="wall.wall_title"
                id="wallOptions"
                v-for="wall in allWalls"
                :key="wall._id"
              >
                {{ wall.wall_title }}
              </option>
            </select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="">
          <v-flex xs12 md6 class="">
            <v-textarea
              style="margin-right: 15px"
              label="مميزات المزرعة والشروط..."
              auto-grow
              outlined
              rows="12"
              row-height="10"
              v-model="edit_agricultural_field.agricultural_notes"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <!-- Layout 6 -->
        <v-layout row wrap class="createReportBtnLayout">
          <v-flex xs12 md4 class="">
            <button @click="updateAgriculturalField" class="createReportBtn" elevation="0">نشر</button>
            <router-link to="/agriculturalFields">
              <button class="cancelBtn">الغاء</button>
            </router-link>
          </v-flex>
        </v-layout>
      
    <!--  <v-spacer> </v-spacer> -->
  </div>
</template>

<script>
import axios from "axios";
const ROOT_URL = "/api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditAgriculturalField",

  data: () => ({
    progressBar: false,
    dialog: false,
    ///////////////
    showAlert: false,
    multiLine: true,
    chat_error_message: "يجب ادخال جميع البيانات",
    ///////////////
    AddressRules: [(v) => !!v || "العنوان مطلوب"],
    PhoneRules: [(v) => !!v || "رقم التلفون مطلوب"],
    PriceRules: [(v) => !!v || "السعر مطلوب"],
    ////////////////
    bouquet_empty_dialog: false,
    ///////////////

    edit_agricultural_field: {

    agricultural_owner_phone_number: "",
    agricultural_owner_whatsApp_number: "",
    agricultural_price: "",
    currency_type_title: "",
    city_title: "",
    agricultural_address: "",
    agricultural_area: "",
    source_of_water_title: "",
    power_title: "",
    wall_title: "",
    advertisment_title: "",
    measurement_title: "",
    agricultural_notes: "",
    },
  }),
  computed: {
        ////////////////////////////////////////////////
        total_published_customer_adds: function () {
      let total = Number(
        this.allAgriculturalFieldsPostsByCustomer.length +
          this.allApartmentsForRentByCustomer.length +
          this.allApartmentsForSaleByCustomer.length +
          this.allBuildingsByCustomer.length +
          this.allHousesByCustomer.length +
          this.allLandsByCustomer.length +
          this.allOfficesByCustomer.length +
          this.allShopsByCustomer.length +
          this.allStudentsHouseByCustomer.length +
          this.allVillasByCustomer.length
      );
      return total;
    },
    bosco_logged_in_customer: function () {
      return this.loggedInUser;
    },
    ///////////////mapGetters
    ...mapGetters([
      "get_bosco_customer",
      "loggedInUser",
      "allBoscoCustomerNumberOfPosts",
      ////Feach Number of posts by Customer///////
      "allAgriculturalFieldsPostsByCustomer",
      "allApartmentsForRentByCustomer",
      "allApartmentsForSaleByCustomer",
      "allBuildingsByCustomer",
      "allHousesByCustomer",
      "allLandsByCustomer",
      "allOfficesByCustomer",
      "allShopsByCustomer",
      "allStudentsHouseByCustomer",
      "allVillasByCustomer",
      ///////////////////////////////
      "allWalls",
      "allPowers",
      "allWaters",
      "allCities",
      "allAdvertisments",
      "allMeasurements",
      "allCurrencies",
    ]),
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted: function () {
    if (!this.isLoggedIn) {
      this.$router.push({ path: "/" });
    }
  },
  created() {
    this.fetchWalls();
    this.fetchPowers();
    this.fetchWaters();
    this.fetchCities();
    this.fetchAdvertisments();
    this.fetchMeasurements();
    this.fetchCurrencies();
    ////Feach Number of posts by Customer///////
    this.fetchAgriculturalFields();
    this.fetchApartmentsForRent();
    this.fetchApartmentsForSale();
    this.fetchBuildings();
    this.fetchHouses();
    this.fetchLands();
    this.fetchOffices();
    this.fetchShops();
    this.fetchStudentsHouse();
    this.fetchVillas();
    /////////////////////////////
      let url = `${ROOT_URL}/agriculturals/get/${this.$route.params.id}`;
      axios
      .get(url)
      .then((response) => {
        this.edit_agricultural_field = response.data.agricultural;
      })
      .catch((error) => {
        error;
      });
  },

  methods: {
    ...mapActions([
      "fetchWalls",
      "fetchPowers",
      "fetchWaters",
      "fetchCities",
      "fetchAdvertisments",
      "fetchMeasurements",
      "fetchCurrencies",
      ////Feach Number of posts by Customer///////

      "fetchAgriculturalFields",
      "fetchApartmentsForRent",
      "fetchApartmentsForSale",
      "fetchBuildings",
      "fetchHouses",
      "fetchLands",
      "fetchOffices",
      "fetchShops",
      "fetchStudentsHouse",
      "fetchVillas",
    ]),
   
    ////////////////////////////////////////////
    updateAgriculturalField() {
      if(
        this.bosco_logged_in_customer === "" ||
        this.edit_agricultural_field.agricultural_price === "" ||
        this.edit_agricultural_field.agricultural_owner_phone_number === "" ||
        this.edit_agricultural_field.agricultural_address === "" ||
        this.edit_agricultural_field.city_title === "" ||
        this.edit_agricultural_field.measurement_title === "" ||
        this.edit_agricultural_field.advertisment_title === "" ||
        this.edit_agricultural_field.currency_type_title === ""
      ){
        return (this.showAlert = true);

      } else {
       ///Calculate bosco customer bouquet remain days
       let date_1 = new Date(
          this.get_bosco_customer.bosco_bouquet_expiration_day
        );
        let date_2 = new Date();
        let difference = date_1.getTime() - date_2.getTime();
        let customer_bouquet_remain_days = difference;
          if(
            Number(
            this.get_bosco_customer.bosco_bouquet_number_of_posts -
              this.total_published_customer_adds
          ) <= 0 ||
          customer_bouquet_remain_days <= 0
          ){
      //////Show dialog
      this.bouquet_empty_dialog = true;
          } else {
            const agricultural = {
    agricultural_owner_phone_number: this.edit_agricultural_field.agricultural_owner_phone_number,
    agricultural_owner_whatsApp_number: this.edit_agricultural_field.agricultural_owner_whatsApp_number,
    agricultural_price: this.edit_agricultural_field.agricultural_price,
    currency_type_title: this.edit_agricultural_field.currency_type_title,
    city_title: this.edit_agricultural_field.city_title,
    agricultural_address: this.edit_agricultural_field.agricultural_address,
    agricultural_area: this.edit_agricultural_field.agricultural_area,
    source_of_water_title: this.edit_agricultural_field.source_of_water_title,
    power_title: this.edit_agricultural_field.power_title,
    wall_title: this.edit_agricultural_field.wall_title,
    advertisment_title: this.edit_agricultural_field.advertisment_title,
    measurement_title: this.edit_agricultural_field.measurement_title,
    agricultural_notes: this.edit_agricultural_field.agricultural_notes,
      };
      this.progressBar = true;
      axios
        .patch(`${ROOT_URL}/agriculturals/update/${this.$route.params.id}`, agricultural)
        .then(() => {
          this.progressBar = false;
          this.dialog = true;
        })
        .catch((error) => {
          console.log("Agriculturals Updating Error: " + " " + error);
          error;
        });
          }
      }
    },
    ...mapActions([
      "fetchWalls",
      "fetchPowers",
      "fetchWaters",
      "fetchCities",
      "fetchAdvertisments",
      "fetchMeasurements",
      "fetchCurrencies",
      ////Feach Number of posts by Customer///////
      "fetchAgriculturalFields",
      "fetchApartmentsForRent",
      "fetchApartmentsForSale",
      "fetchBuildings",
      "fetchHouses",
      "fetchLands",
      "fetchOffices",
      "fetchShops",
      "fetchStudentsHouse",
      "fetchVillas",
    ]),
    cancelUpdate() {
      this.$router.push({ path: "/agriculturalFields" });
    },
  },
};
</script>

<style scoped>
#create {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.countToolBar {
  margin-right: 10px;
  background: #fff !important;
  color: #9b5de5 !important;
  font-size: 18px;
  font-weight: 500;
}
.createReportBtn {
  width: 80px;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  background: #9b5de5 !important;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  border: 1px solid #9b5de5;
  border-radius: 2px;
}
.cancelBtn {
  width: 80px;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
  border: 1px solid #9b5de5;
  border-radius: 2px;
  background: #f15bb5;
}
.createReportBtn:hover {
  color: #9b5de5;
  background: #fff !important;
  transition: color 0.8s;
}
.cancleBtn {
  color: #9b5de5;
  border: 1px solid #9b5de5;
}
select {
  margin-bottom: 30px;
  margin-right: 15px;
  padding: 5px;
  border: 1px solid rgb(191, 186, 208);
  border-radius: 4px;
  width: 90%;
}
.selectSpan {
  margin-right: 15px;
}
.dialog_card {
  border: 1px solid #9b5de5;
}
.dialog_btn {
  color: #9b5de5;
  border: 1px solid #9b5de5;
}
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #create {
    margin-top: 15px;
}
  .customer_phone_number {
    margin-top: 15px;
  }
  .createReportBtnLayout{
  margin-bottom: 100px;
}
}

</style>
