import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      floors: [],
};
/////////////// Getters
const getters = {
  allFloors: (state) => state.floors
};
/////////////// Actions
const actions = {
  async fetchFloors({ commit }) {
    const response = await apiServices.fetch_floors();
    commit("setFloors", response.data.floors);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setFloors: (state, floors) => {
      state.floors = floors;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
