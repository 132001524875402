import Vue from "vue";
import Vuex from "vuex";

import users from "./modules/users"
import apartment_for_rent from "./modules/apartment_for_rent"
import apartment_for_sale from "./modules/apartment_for_sale"
import lands from "./modules/lands"
import houses from "./modules/houses"
import villas from "./modules/villas"
import students_house from "./modules/students_house"
import shops from "./modules/shops"
import offices from "./modules/offices"
import buildings from "./modules/buildings"
import agricultural_fields from "./modules/agricultural_fields"
import rooms from "./modules/rooms"
import balconies from "./modules/balconies"
import floors from "./modules/floors"
import bathrooms from "./modules/bathrooms"
import advanced_payments from "./modules/advanced_payments"
import furnitures from "./modules/furnitures"
import kitchens from "./modules/kitchens"
import halls from "./modules/halls"
import finishings from "./modules/finishings"
import guards from "./modules/guards"
import powers from "./modules/powers"
import cities from "./modules/cities"
import generals from "./modules/generals"
import elevators from "./modules/elevators"
import parkings from "./modules/parkings"
import air_conditions from "./modules/air_conditions"
import walls from "./modules/walls"
import waters from "./modules/waters"
import advertisments from "./modules/advertisments"
import sketches from "./modules/sketches"
import ownerships from "./modules/ownerships"
import measurements from "./modules/measurements"
import currencies from "./modules/currencies"
import bouquets from "./modules/bosco_bouquets"

import bosco_customer_roles from "./modules/bosco_customer_roles"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    apartment_for_rent,
    apartment_for_sale,
    lands,
    houses,
    villas,
    students_house,
    shops,
    offices,
    buildings,
    agricultural_fields,
    rooms,
    balconies,
    floors,
    bathrooms,
    advanced_payments,
    furnitures,
    kitchens,
    halls,
    finishings,
    guards,
    powers,
    cities,
    generals,
    elevators,
    parkings,
    air_conditions,
    walls,
    waters,
    advertisments,
    sketches,
    ownerships,
    measurements,
    currencies,
    bouquets,
    bosco_customer_roles
  },
});
