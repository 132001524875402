import apiServices from "../../api/apiServices";
/////////////// State
const state = {
  villas: [],
  totalVillasByCustomer: [],
};
/////////////// Getters
const getters = {
  allVillas: (state) => state.villas.reverse(),
  allVillasByCustomer: (state) => state.totalVillasByCustomer.reverse(),
};
/////////////// Actions
const actions = {
  async fetchVillas({ commit, rootGetters }) {
    const response = await apiServices.fetch_villas();
    //filter Villas posts by customer
    let total_villas_posts = response.data.villas.filter((items) => {
      const matchPost = items.bosco_customer_email.includes(
        rootGetters.get_bosco_customer.customer_email
      );
      return matchPost;
    });

    commit("setVillasByCustomer", total_villas_posts);
    commit("setVillas", response.data.villas);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
  setVillas: (state, villas) => {
    state.villas = villas;
  },
  setVillasByCustomer: (state, total_villas_posts) => {
    state.totalVillasByCustomer = total_villas_posts;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
