import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      kitchens: [],
};
/////////////// Getters
const getters = {
  allKitchens: (state) => state.kitchens
};
/////////////// Actions
const actions = {
  async fetchKitchens({ commit }) {
    const response = await apiServices.fetch_kitchens();
    commit("setKitchens", response.data.kitchens);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setKitchens: (state, kitchens) => {
      state.kitchens = kitchens;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
