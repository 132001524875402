import apiServices from "../../api/apiServices";
/////////////// State 
const state = {
      advanced_payments: [],
};
/////////////// Getters
const getters = {
  allAdvancedPayments: (state) => state.advanced_payments.reverse()
};
/////////////// Actions
const actions = {
  async fetchAdvancedPayments({ commit }) {
    const response = await apiServices.fetch_advanced_payments();
    commit("setAdvancedPayments", response.data.advanced_payments);
  },
};
/////////////// MUTATIONS /////////////////////
const mutations = {
      setAdvancedPayments: (state, advanced_payments) => {
      state.advanced_payments = advanced_payments;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
