<template>
  <v-container class="supportDiv">
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <h2 class="boscoPolicyTitle">بوسكو - سياسة الخصوصية</h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="issuedFlex">
        <p>الإصدارة رقم 1.0</p>
        <p>اخر تحديث إكتوبر 2022</p>
        <p>اخر تحديث تم بواسطة قسم ال R&D</p>
        <p>التحقيق اللجنة القانونية</p>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <p class="contantPara">المحتويات:</p>
        <p class="introPara">مقدمة:</p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <p class="para">
          الإشكاليات التقنية لها تاثيرات في مصداقية المنصة، ولذلك نحن في منصة
          بوسكو، نعمل علي تقليل هذة الإشكاليات عبر سياسة الخصوصية المتبعة والتي
          نعمل دائما علي تطويرها.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <p class="introPara">اهمية سياسة الخصوصية:</p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p class="para">- تقليل الاخطار التكنولوجية</p>
        <p class="para">
          - التخطيط للإشكاليات المتوقعة وإيجاد الحلول لتطبيقها حالة حدوثها.
        </p>
        <p class="para">- حماية المنصة، ومعلومات المستخدمين.</p>
        <p class="para">
          - حتي نتمكن من الإيفاء بالتزاماتنا تجاة المستخدمين والزوار.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <p class="contantPara">المسؤليات:</p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p class="para">
          - قسم البحث والتطوير - مسوؤل عن إيجاد الحلول للمشاكل التقنية ووضع
          إستراتيجيات الامان للمنصة.
        </p>
        <p class="para">
          - القسم القانوني - مسوؤل عن تقديم الاستشارات القانونية
        </p>
        <p class="para">- قسم التسويق - مسوؤل عن التعامل مع العملاء والزوار.</p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <p class="introPara">التحديث والمراجعة:</p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p class="para">
          المنصة تعمل علي تحديث سياسة الخصوصية كل 3 اشهر، في حالة وجود اي
          ملاحظات او اعتراضات يرجي مراسلة قسم البحث والتطوير عبر البريد
          الإلكتروني boscount@gmail.com
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <p class="contantPara">انواع البيانات:</p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p class="para">
          تقوم المنصة بتصنيف البيانات علي حسب الاهمية التي تساعدها في الإيفا
          بالتزاماتها تجاه الزوار والمستخدمين. نحن نقيد الوصول للمعلومات الشخصية
          للمستخدمين، وتقوم المنصة بتصنيف البيانات الي:
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <p class="introPara">بيانات عامة:</p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p class="para">
          بيانات متاحة لجميع الزوار في المنصة مثل (الدومين، البريد الإلكتروني
          للمنصة)، بيانات المستخدمين وتشمل ارقام الهواتف وصور العقارات.
          ومواصفاتها.
        </p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p class="introPara">بيانات خاصة ”اصحاب المكاتب“:</p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p class="para">
          وهي البيانات التي يتم طلبها في النموزج رقم 1 حتي يتم فتح مكتب في
          المنصة والبيانات التي تظهر في المراسلات عبر البريد الإلكتروني.
        </p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="">
      <v-flex xs12 md12 class="">
        <p class="introPara">الوصول الي البيانات:</p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p class="para">
          منصة بوسكو للتسويق العقاري يقيد الوصول الي معلومات المستخدمين الشخصية.
          حتي نساعد في تطوير المنصة، وتقديم نصائح للمستخدمين حتي يادوا المهام
          بشكل احترافي. في منصة بوسكو للتسويق العقاري نعمل وفقا لي لوائح ال GDPR
          في الوصول الي البياتات.
        </p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p class="contantPara">أمن المنصة والمستخدمين:</p>
        <p class="para">
          لكي نحمي البيانات، المنصة والمستخدمين نحن نستخدم ونوصي بالاتي:
        </p>
        <p class="para">استخدام انتي فايروس في اللابتوبات والكمبيوترات.</p>
        <p class="para">
          بوسكو تعمل علي تعزيز الاستخدام الامن للشبكة العالمية للمعلومات وذلك
          عبر التثقيف لمستخدمي المنصة والدورات التدريبية لاصحاب المكاتب حول
          استخدام المنصة.
        </p>
      </v-flex>
      <v-flex xs12 md12 class="">
        <p></p>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="lastLayout">
      <v-flex xs12 md12>
        <p class="contantPara">كيف تحمي جهازك الخاص:</p>
      </v-flex>
      <v-flex xs12 md12>
        <p class="para">
          - يقع هذا الجزء تحت مسوؤليتك الشخصية لتعزيز امن جهازك الشخصي (لابتوب،
          هاتف ذكي، كمبيوتر …)
        </p>
        <p class="para">- حزف البرامج التي لا تحتاج اليها من جهازك الشخصي</p>
        <p class="para">
          - التحديث الدوري لانظمة التشغيل والمتصفح (كروم، فاير فوكس، اكسبلور، …)
        </p>
        <p class="para">- استخدام برامج الانتي فايروس</p>
        <p class="para">
          - لابد من استيعاب سياسات الخصوصية لمواقع التواصل الاجتماعي والتطبيقات.
        </p>
        <p class="para">
          - نوصي زوار المنصة والمستخدمين للبحث بشكل دوري عن اخطار الشبكة
          العالمية للمعلومات وخطرها علي بياناتهم الشخصية.
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "BoPrivacyPolicy",
  data: () => ({}),
};
</script>

<style scoped>
.supportDiv {
  width: 75%;
  background: #fff;
  padding: 30px;
  margin-top: 40px;
}

.boscoPolicyTitle {
  font-size: 25px;
  font-weight: 500;
  color: #9b5de5;
  text-align: center;
}
.issuedFlex {
  font-size: 18px;
  color: #9b5de5;
  margin-top: 10px;
  text-align: center;
}
.contantPara {
  font-size: 25px;
  font-weight: 500;
  color: #9b5de5;
  border-bottom: #9b5de5 2px solid;
}
.introPara {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.para {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  letter-spacing: 1px;
  line-height: 1.5;
}
.lastLayout {
  margin-bottom: 110px;
}
/* Media Queries */
/* most mobiles has width about 400px */

@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  .supportDiv {
    width: 100%;
    margin-top: 15px;
    padding: 25px;
  }
}
</style>
