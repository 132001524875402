<template>
  <v-container id="culture">
    <v-layout row wrap>
      <v-flex xs12 md12 class="customerInfo">
        <v-card elevation="0" class="mx-auto howWeAreCard" max-width="434">
          <v-avatar size="150">
            <v-img
              :src="`data:image/png;base64, ${get_bosco_customer.image}`"
              class="cudtomerImage"
            ></v-img>
          </v-avatar>
          <p class="bosco_customer_name">
            {{ this.loggedInUser }}
          </p>
          <span class="bosco_customer_participate_day">
            تاريخ الإشتراك:
            {{ get_bosco_customer.created_At }}
          </span>
        </v-card>
      </v-flex>
      <!-- <v-spacer></v-spacer> -->
    </v-layout>

    <v-layout row wrap class="numberOfPostLayout">
      <v-spacer></v-spacer>
      <v-flex xs12 md5 class="welcomeFlex">
        <v-btn
          rounded
          elevation="0"
          class="employeeTableTitle"
        >
          <svg style="width: 30px; height: 30px" viewBox="0 0 24 24">
            <path
              fill="#fee440"
              d="M12 2C13.1 2 14 2.9 14 4S13.1 6 12 6 10 5.1 10 4 10.9 2 12 2M15.9 8.1C15.5 7.7 14.8 7 13.5 7H11C8.2 7 6 4.8 6 2H4C4 5.2 6.1 7.8 9 8.7V22H11V16H13V22H15V10.1L19 14L20.4 12.6L15.9 8.1Z"
            />
          </svg>
          مرحب بيك في مكتبك
        </v-btn>
      </v-flex>
      <v-flex xs12 md3 class="numberOfAddsFlex">
        <v-btn
          rounded
          elevation="0"
          class="employeeTableTitle"
        >
          عدد الإعلانات المنشورة:

          {{ total_published_customer_adds }}
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="#fee440"
              d="M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21"
            />
          </svg>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <!--  bouquet Info Layout -->
    <v-layout row wrap class="bouquetLayout">
      <v-flex xs5 md3 class="bouquetFlex">
        <div class="numberOfAdds numberOfAdds3">
          <p>
            <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
              <path
                fill="#fee440"
                d="M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M16.53,11.06L15.47,10L10.59,14.88L8.47,12.76L7.41,13.82L10.59,17L16.53,11.06Z"
              />
            </svg>
          </p>
          <span class="bouquetType"> نوع الباقة </span>
          <br />
          <span class="bouquetType"
            >{{ get_bosco_customer.bosco_bouquet_title }}
          </span>
        </div>
      </v-flex>
      <v-flex xs5 md3 class="bouquetFlex">
        <div class="numberOfAdds numberOfAdds4">
          <p>
            <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
              <path
                fill="#fee440"
                d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z"
              />
            </svg>
          </p>
          <span class="bouquetType">تاريخ إنتها الباقة</span>
          <br />
          <span class="bouquetType">{{
            get_bosco_customer.bosco_bouquet_expiration_day
          }}</span>
        </div>
      </v-flex>
      <v-flex xs5 md3 class="bouquetFlex">
        <div class="numberOfAdds numberOfAdds1">
          <p>
            <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
              <path
                fill="#fee440"
                d="M4,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M4,6V18H11V6H4M20,18V6H18.76C19,6.54 18.95,7.07 18.95,7.13C18.88,7.8 18.41,8.5 18.24,8.75L15.91,11.3L19.23,11.28L19.24,12.5L14.04,12.47L14,11.47C14,11.47 17.05,8.24 17.2,7.95C17.34,7.67 17.91,6 16.5,6C15.27,6.05 15.41,7.3 15.41,7.3L13.87,7.31C13.87,7.31 13.88,6.65 14.25,6H13V18H15.58L15.57,17.14L16.54,17.13C16.54,17.13 17.45,16.97 17.46,16.08C17.5,15.08 16.65,15.08 16.5,15.08C16.37,15.08 15.43,15.13 15.43,15.95H13.91C13.91,15.95 13.95,13.89 16.5,13.89C19.1,13.89 18.96,15.91 18.96,15.91C18.96,15.91 19,17.16 17.85,17.63L18.37,18H20M8.92,16H7.42V10.2L5.62,10.76V9.53L8.76,8.41H8.92V16Z"
              />
            </svg>
          </p>
          <span class="bouquetType">عدد الإعلانات في الباقة</span>
          <br />
          <span class="bouquetType">
            {{ get_bosco_customer.bosco_bouquet_number_of_posts }}</span
          >
        </div>
      </v-flex>
      <v-flex xs5 md3 class="bouquetFlex">
        <div class="numberOfAdds numberOfAdds2">
          <p>
            <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
              <path
                fill="#fee440"
                d="M13,8A4,4 0 0,1 9,12A4,4 0 0,1 5,8A4,4 0 0,1 9,4A4,4 0 0,1 13,8M17,18V20H1V18C1,15.79 4.58,14 9,14C13.42,14 17,15.79 17,18M20.5,14.5V16H19V14.5H20.5M18.5,9.5H17V9A3,3 0 0,1 20,6A3,3 0 0,1 23,9C23,9.97 22.5,10.88 21.71,11.41L21.41,11.6C20.84,12 20.5,12.61 20.5,13.3V13.5H19V13.3C19,12.11 19.6,11 20.59,10.35L20.88,10.16C21.27,9.9 21.5,9.47 21.5,9A1.5,1.5 0 0,0 20,7.5A1.5,1.5 0 0,0 18.5,9V9.5Z"
              />
            </svg>
          </p>

          <span class="bouquetType">فضل لي كم إعلان؟</span>
          <br />
          <span class="bouquetType">{{
            Number(
              get_bosco_customer.bosco_bouquet_number_of_posts -
                total_published_customer_adds
            )
          }}</span>
        </div>
      </v-flex>
    </v-layout>
    <!--  bouquet types/call us Layout -->
    <v-layout row wrap class="">
      <v-spacer></v-spacer>
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكونا</p>

            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 1 منشور</p>
              <p class="para">مدة الباقة يوم كامل 24 ساعة</p>
              <p class="para">سعر الباقة 2 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
                 <span style="color: #000; font-weight: 900;">
                 %50
                </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <p class="advertisment">عايز تجدد الباقة?</p>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#9b5de5"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#9b5de5"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>

      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو يومي</p>
            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 4 منشورات</p>
              <p class="para">مدةالباقة يوم كامل 24 ساعة</p>
              <p class="para">سعر الباقة 6 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
                 <span style="color: #000; font-weight: 900;">
                 %50
                </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <p class="advertisment">عايز تجدد الباقة?</p>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#9b5de5"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#9b5de5"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Layout -->
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو إسبوعي</p>

            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 30 منشور</p>
              <p class="para">مدةالباقة اسبوع تمام</p>
              <p class="para">سعر الباقة 42 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
                 <span style="color: #000; font-weight: 900;">
                 %50
                </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <p class="advertisment">عايز تجدد الباقة?</p>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#9b5de5"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#9b5de5"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <!-- Layout -->
    <v-layout row wrap class="secLayout">
      <v-spacer></v-spacer>
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو إسبوعين</p>
            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 65 منشور</p>
              <p class="para">مدةالباقة إسبوعين قمر دورين</p>
              <p class="para">سعر الباقة 84 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
                 <span style="color: #000; font-weight: 900;">
                 %50
                </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <p class="advertisment">عايز تجدد الباقة?</p>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#9b5de5"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#9b5de5"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو شهري</p>

            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">عدد المنشورات في الباقة 135 منشور</p>
              <p class="para">مدةالباقة شهر كامل</p>
              <p class="para">سعر الباقة 155 الف جنيه</p>
              <p class="paraDiscount"> 
                 بمناسبة الافتتاح تخفيض
              <span style="color: #000; font-weight: 900;">
                 %50
              </span>
              </p>
              <p class="para">مدة الإعلان شهر</p>
            </div>
            <div>
              <p class="advertisment">عايز تجدد الباقة?</p>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#9b5de5"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#9b5de5"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <!-- Layout -->
      <v-flex xs12 md4 class="addFlex">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto addOne"
          >
            <p class="boscoAdvertismentTitle">بوسكو للتسويق العقاري</p>
            <p class="boscoAdvertismentsubTitle">بوسكو غير محدود</p>

            <h2 class="bosco">
              <span>B</span>
              <span style="color: #000">osco</span>
            </h2>
            <v-divider></v-divider>
            <div class="content">
              <p class="para">اتواصل معانا لمن تلقي فرصة</p>
            </div>
            <div>
              <p class="advertisment">عايز تجدد الباقة?</p>
            </div>
            <div class="phone">
              <span class="svgItem">
                <svg
                  style="width: 25px; height: 25px; padding-left: 5px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#9b5de5"
                    d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                  />
                </svg>
              </span>
              <span class="firstphoneContactSpan">
                <a style="color: #000;" href="tel:+249916099219">
                  إتصل الان
                </a>
              </span>
            </div>
            <!-- For WhatsApp -->
            <div class="phone">
              <span>
                <a
                  style="color: #000;"
                  href="https://wa.me/+249916099219"
                  target="_blank"
                >
                  <span class="svgItem">
                    <svg
                      style="width: 25px; height: 25px"
                      class=""
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#9b5de5"
                        d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
                      />
                    </svg>
                  </span>
                  واتس
                </a>
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BoAdmin",
  components: {},

  data: () => ({}),
  computed: {
    ////////////////////////////////////////////////
    total_published_customer_adds: function () {
      let total = Number(
        this.allAgriculturalFieldsPostsByCustomer.length +
          this.allApartmentsForRentByCustomer.length +
          this.allApartmentsForSaleByCustomer.length +
          this.allBuildingsByCustomer.length +
          this.allHousesByCustomer.length +
          this.allLandsByCustomer.length +
          this.allOfficesByCustomer.length +
          this.allShopsByCustomer.length +
          this.allStudentsHouseByCustomer.length +
          this.allVillasByCustomer.length
      );
      return total;
    },
    //////////////////////////////////////////////////
    ...mapGetters([
      "loggedInUser",
      "get_bosco_customer",
      "allAgriculturalFieldsPostsByCustomer",
      "allApartmentsForRentByCustomer",
      "allApartmentsForSaleByCustomer",
      "allBuildingsByCustomer",
      "allHousesByCustomer",
      "allLandsByCustomer",
      "allOfficesByCustomer",
      "allShopsByCustomer",
      "allStudentsHouseByCustomer",
      "allVillasByCustomer",
    ]),
    ///////////////////////////////////////
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted: function () {
    if (!this.isLoggedIn) {
      this.$router.push({ path: "/" });
    }
  },
  created() {
    this.fetchAgriculturalFields();
    this.fetchApartmentsForRent();
    this.fetchApartmentsForSale();
    this.fetchBuildings();
    this.fetchHouses();
    this.fetchLands();
    this.fetchOffices();
    this.fetchShops();
    this.fetchStudentsHouse();
    this.fetchVillas();
  },
  methods: {
    ...mapActions([
      "fetchAgriculturalFields",
      "fetchApartmentsForRent",
      "fetchApartmentsForSale",
      "fetchBuildings",
      "fetchHouses",
      "fetchLands",
      "fetchOffices",
      "fetchShops",
      "fetchStudentsHouse",
      "fetchVillas",
    ]),
  },
};
</script>

<style scoped>
#culture {
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.employeeTableTitle {
  background: #9b5de5 !important;
  color: #fff;
  font-size: 18px !important;
  font-weight: 500;
}
/* .bouquetLayout {
  margin: 0 auto;
} */
.numberOfAdds {
  text-align: center;
  padding: 20px;
  /* margin: 2px; */
  width: 150px;
  height: 150px;
  /* transform: skew(15deg); */
  border-radius: 50%;
}
.numberOfAdds1,
.numberOfAdds2,
.numberOfAdds3,
.numberOfAdds4 {
  background: linear-gradient(to top, #00f5d4, #f15bb5) !important;
}
.addOne {
  margin-left: 10px;
  background: #e7e6f7;
  color: #000;
  border: 2px dotted #000;
  width: 425px;
  height: 650px;
}
.advertisment {
  font-size: 16px;
  font-weight: 500;
  padding: 7px;
  background: linear-gradient(to top, #9b5de5, #fee440 );
  color: #fff;
  border-radius: 2px;
}
.boscoAdvertismentTitle {
  padding-top: 10px;
  font-size: 25px;
  font-weight: 500;
}
.phone {
  font-size: 20px;
  padding-top: 15px;
}
.svgItem {
  opacity: 0.6;
}
.bosco {
  color: #9b5de5;
  font-size: 50px;
  font-weight: 900;
}
.para {
  font-size: 22px;
  font-weight: 500;
}
.paraDiscount{
  color: #fff;
  background: linear-gradient(to top, #9b5de5, #fee440 );
  border-radius: 5px;
  padding: 5px;
  font-size: 18px;
  font-weight: 500;
}
.addFlex {
  margin-top: 10px;
  text-align: center;
}
.bouquetType {
  color: #000;
}
.bosco_customer_name {
  color: #000;
  font-size: 18px;
}
.bosco_customer_participate_day {
  color: #000;
  font-size: 18px;
  opacity: 0.5;
}
.customerInfo {
  text-align: center;
}
.numberOfPostLayout {
  margin-top: 30px;
  text-align: center;
}
.bouquetFlex {
  padding-top: 20px;
  padding-bottom: 20px;
  /* text-align: center; */
  margin: 0 auto;
}
.numberOfAddsFlex,
.welcomeFlex {
  margin-bottom: 10px;
}
.secLayout{
  margin-bottom: 130px;

}
/* Media Queries */
/* most mobiles has width about 400px */
@media screen and (max-width: 500px) {
  /* any device has 500px and less  "mobiels"*/
  #culture {
    padding: 10px;
    margin-top: 15px;
  }
  .employeeTableTitle {
  font-size: 17px !important;
}
  .addOne {
    width: 410px;
    height: 700px;
  }
  .bosco_customer_participate_day {
    color: #000;
    font-size: 16px;
  }
  .numberOfAdds {
    padding: 10px;
    /* margin: 2px; */
    width: 140px;
    height: 140px;
  }
  .para {
  font-size: 22px;
}
.paraDiscount{
  font-size: 22px;
}
.boscoAdvertismentsubTitle{
  font-size: 23px;
  font-weight: 500;
}
.advertisment {
  font-size: 22px;
  padding: 5px;

}
.firstphoneContactSpan {
  font-size: 22px;
}
.phone {
  font-size: 22px;
}
}
</style>
