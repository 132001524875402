import apiServices from "../../api/apiServices";

/////////////// State /////////////////////
const state = {
  apartmentsForSale: [],
  totalAgriculturalsFieldsByCustomer: [],
  totalApartmentsForSaleByCustomer: [],
};
/////////////// Getters /////////////////////
const getters = {
  allApartmentsForSale: (state) => state.apartmentsForSale.reverse(),
  allApartmentsForSaleByCustomer: (state) =>
    state.totalApartmentsForSaleByCustomer
};

/////////////// Actions /////////////////////
const actions = {
  async fetchApartmentsForSale({ commit, rootGetters }) {
    const response = await apiServices.fetch_apartments_for_sale();
    //filter agriculturals fields posts by customer
    let total_apartment_for_sale_posts =
      response.data.apartments_for_sale.filter((items) => {
        const matchPost = items.bosco_customer_email.includes(
          rootGetters.get_bosco_customer.customer_email
        );
        return matchPost;
      });
    commit("totalApartmentsForSaleByCustomer", total_apartment_for_sale_posts);
    commit("setApartmentsForSale", response.data.apartments_for_sale);
  },
};

/////////////// MUTATIONS /////////////////////
const mutations = {
  setApartmentsForSale: (state, apartmentsForSale) => {
    state.apartmentsForSale = apartmentsForSale;
  },
  totalApartmentsForSaleByCustomer: (
    state,
    totalApartmentsForSaleByCustomer
  ) => {
    state.totalApartmentsForSaleByCustomer = totalApartmentsForSaleByCustomer;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
