import axios from "axios";
const ROOT_URL = "/api";
import apiServices from "../../api/apiServices";

///////////////State/////////////////////
const state = {
  bosco_customer: [],
  bosco_customer_number_of_posts: 0,
  users: [],
  full_customer_name: [],
  customer_email: "",
  userId: null,
  isLoggedIn: false,
  loginError: "",
   token: null,  
};
/////////////// Getters /////////////////////
const getters = {
  
  get_bosco_customer: (state) => state.bosco_customer,
  allBoscoCustomerNumberOfPosts: (state) => state.bosco_customer_number_of_posts,
  getToken: (state) => state.token,
  allUsers: (state) => state.users,
  isLoggedIn: (state) => state.isLoggedIn,
  userId: (state) => state.userId,
  loginError: (state) => state.loginError,
  loggedInUser: (state) => state.full_customer_name,
};

/////////////// Actions /////////////////////
const actions = {
  //////Fetch Users
  async fetchUsers({ commit }) {
    const response = await apiServices.fetch_users();
    commit("setUsers", response.data.users);
  },

  //////////////////// Logout Customer //////////
  logout: ({ commit }) => {
   commit("setToken", null);
    //localStorage.removeItem("token");
    state.isLoggedIn = false;

    //this.$router.push({ path: "/login" });
  },
  //////////////////// Login User 
  async logInUser({ commit }, payload) {
    await axios
      .post(`${ROOT_URL}/users/login/`, payload)
      .then((res) => {
        let data = res.data;
        ///////////////////////////////////////////
        let number_of_posts = data.user.bosco_bouquet_number_of_posts
        let logged_in_user_name = data.user.customer_name;
         //////////////////////////////////////////
            if (data) {
          if (data.token && data.user) {
            /////Remain Posts for user
            commit("set_remain_posts", number_of_posts )
            commit("set_loged_in_customer_info", data.user)
            commit("setLoggedIn_user_name", logged_in_user_name);
            commit("logInUser", payload);
            commit("setToken", data.token);
           // localStorage.setItem("token", JSON.stringify(data.token));
            axios.defaults.headers.common["Authorization"] = data.token;

          } else {
            commit("loginError");
          }
        }
      })
      .catch(() => {
        commit("loginError");
      });
  },
};

/////////////// MUTATIONS //////////
const mutations = {
  set_loged_in_customer_info: (state, bosco_customer) => {
    state.bosco_customer = bosco_customer;
  },
  set_remain_posts: (state, number_of_posts ) => {
    state.bosco_customer_number_of_posts = number_of_posts;

  },
  setUsers: (state, users) => {
    state.users = users;
  },

  logInUser(state, payload) {
    state.isLoggedIn = true;
    state.customer_email = payload.customer_email;
    state.userId = payload.user_Id;
  },

  setToken: (state, token) => {
    state.token = token;
  },
  loginError(state) {
    state.isLoggedIn = false;
    state.loginError =
      "اوووبس، هنالك خطاء في الأيميل او الباسورد، محاولة فاشلة!";
  },
  setLoggedIn_user_name: (state, full_customer_name) => {
    state.full_customer_name = full_customer_name;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
